import { allColors } from 'components/colors/AllColors';
import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
`;
export const LabelMain = styled.label`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
`;
export const UploadConatiner = styled.div`
  /* width: 100%; */
  width: 156px;
  height: 48px;
  background: ${allColors.selectBgColor};
  position: relative;
  border-radius: 8px;
  color: ${allColors.shriftColorOpasity3};
`;

export const Input = styled.input`
  position: absolute;
  display: none;
  /* opacity: 0; */
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: ${allColors.selectBgColor};
  border-radius: 8px;
  border-style: none;
  &::placeholder {
    color: ${allColors.shriftColorOpasity3};
    padding-left: 10px;
  }
`;
export const Label = styled.label`
  width: 100%;
  height: 48px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  gap: 8px;
  cursor: pointer;
  svg {
    font-size: 18px;
    color: ${allColors.shriftColorOpasity3};
  }
`;
