import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
  .date-picker {
    background: ${allColors.selectBgColor};
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    height: 44px;
    border: none;
  }
`
export const Label = styled.label`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
`
export const Input = styled.input`
  background: ${allColors.selectBgColor};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
  border: none;
`
