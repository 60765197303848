import React from "react";
import { createBrowserRouter } from "react-router-dom";
import RootLayout from "RootLayout/RootLayout";
import Apply from "pages/agro-kredit/ariza-page/container";
import ActSuccess from "pages/agro-kredit/ariza-page/components/ariza-success";
import BusinessPlan from "pages/agro-kredit/biznes-plan/container";
import Landing from "pages/landing/container";
import AuthV1 from "components/auth/auth-v1/container";
import ApplyRejection from "pages/shaxsiy-kabinet/components/one-apply-info";
import ChooseDirection from "components/auth/auth-v1/components/chooseDirection";
import MainCabinet from "pages/shaxsiy-kabinet/container";
import LeasingApply from "pages/agroleasing/apply/container";
import AuthV2 from "components/auth/auth-v2";
import TypeForApply from "pages/agroleasing/TypeForApply";
import LeasingMainApply from "pages/agroleasing/collapseForApply/container";
import LeasingApplyV2 from "pages/agroleasing/apply/container/apply-v2";
import ApplyStatus from "pages/agroleasing/apply-status/container";
import PersonalInfo from "pages/agroleasing/apply/container/archive";
import LeasingCabinet from "pages/agroleasing/personal-cabinet";
import ProjectOfficeChoosing from "pages/agroleasing/choosing-project-office";

export const router = createBrowserRouter([
  {
    path: "",
    element: <Landing />,
  },
  {
    path: "ms-auth-oneId",
    element: <AuthV1 />,
  },
  {
    path: "agro-leasing-auth-oneId",
    element: <AuthV2 />,
  },

  {
    path: "credit-type",
    element: <ChooseDirection />,
  },
  {
    path: "/apply-success",
    element: <ActSuccess />,
  },
  {
    element: <RootLayout />,
    children: [
      {
        path: "agro-leasing/",
        children: [
          {
            path: "personal-cabinet",
            element: <LeasingCabinet />,
          },
          {
            path: "user-info",
            element: <PersonalInfo />,
          },
          {
            path: "apply-type-choose",
            element: <TypeForApply />,
          },
          {
            path: "form-an-application",
            element: <LeasingMainApply />,
          },
          {
            path: "choosing-project-office",
            element: <ProjectOfficeChoosing />,
          },
          {
            path: "apply-v2",
            element: <LeasingApply />,
          },
          {
            path: "apply-v1",
            element: <LeasingApplyV2 />,
          },
          {
            path: "apply-status",
            element: <ApplyStatus />,
          },
        ],
      },

      {
        path: "ms/",
        children: [
          {
            path: "personal-cabinet",
            element: <MainCabinet />,
          },
          {
            path: "personal-cabinet/:id",
            element: <ApplyRejection />,
          },
          {
            path: "ariza",
            element: <Apply />,
          },
          {
            path: "business-plan",
            element: <BusinessPlan />,
          },
        ],
      },
    ],
  },
]);

// ==== children element ishlatish ===
// path: '/skoring-shakllantirish',
// children: [
//   {
//     index: true,
//     element: <SkoringMain />,
//   },
//   {
//     path: 'add-factor',
//     element: <AddFactor />,
//   },
// ],
// },
