import React from "react";
import { ContentInput } from "./style";
// import {NumericFormat} from "react-number-format";

function Input({
  Controller,
  control,
  name,
  disabled,
  required = false,
  handleChange,
  type,
  defaultValue,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? null}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        // <NumericFormat
        //   // className="dashboard-number-format"
        //   displayType={type||'number'}
        //   thousandSeparator={" "}
        //   value={value || ""}
        //   onChange={(e) => {
        //     if (handleChange) {
        //       handleChange(e);
        //     } else {
        //       onChange(e);
        //     }
        //   }}
        //   required={required}
        //   inputRef={ref}
        //   onWheel={(e) => e.target.blur()}
        //   // onWheel={true}
        //   disabled={disabled}
        // />
        <ContentInput
          name={name}
          onBlur={onBlur} // notify when input is touched
          type={type || "number"}
          value={value || ""}
          onChange={(e) => {
            if (handleChange) {
              handleChange(e);
            } else {
              onChange(e);
            }
          }}
          required={required}
          inputRef={ref}
          onWheel={(e) => e.target.blur()}
          // onWheel={true}
          disabled={disabled}
        />
      )}
    />
  );
}

export default Input;
