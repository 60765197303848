import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const PlanContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  /* form {
    white-space: nowrap;
  } */
`;
export const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  tr {
    height: 50px;
    border-bottom: 1px solid #e7e7e7;
  }
`;
export const THead = styled.thead`
  tr {
    padding: 0px;
    width: 100%;
    border-radius: 12px;
  }
  & th {
    /* white-space: nowrap; */
    position: relative;
    text-align: start;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${allColors.shriftColorOpasity3};
    padding: 8px 10px;
    /* width: 150px; */
  }
  & th:first-child {
    min-width: auto !important;
    text-align: center;
  }
  & th:nth-child(2) {
    min-width: 230px !important;
  }

  tr th:not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.4em;
    background: #e7e7e7;
    border: 1px solid #e7e7e7;
    transform: rotate(90deg);
    transform: translateY(-50%);
    content: "";
  }
  tr th:last-child:not([colspan]):before {
    content: none;
  }
`;
export const TableBody = styled.tbody`
  tr {
    padding: 0;
    width: 100%;
    border-radius: 12px;
    &.tr-green {
      background-color: rgba(8, 112, 95, 0.05);
      td {
        color: ${allColors.mainColor} !important;
        font-weight: 600;
      }
      input {
        background: #f2f8f7;
      }
    }
  }
  & td {
    position: relative;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${allColors.shriftColor};
    padding: 8px 10px;
  }
  & td:first-child {
    min-width: auto !important;
    text-align: center;
  }
  tr td:not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.4em;
    background: ${allColors.seriyColor};
    border: 1px solid ${allColors.seriyColor};
    transform: rotate(90deg);
    transform: translateY(-50%);
    content: "";
  }
  tr td:last-child:not([colspan]):before {
    content: none;
  }
`;

export const ContentInput = styled.input`
  border: none;
  min-width: 100%;
  background-color: ${allColors.whiteColor};
  font-feature-settings: "tnum" on, "lnum" on;
  max-width: 140px;
  &:focus {
    outline: none;
  }
`;

export const TableFooter = styled.tfoot`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
