import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 64px 0;
  box-sizing: border-box;
  background: ${({ isLanding }) =>
    isLanding ? "#FBFBFB" : allColors.whiteColor};
`;
export const WrapperCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  /* @media(max-width: 1400px){
gap: 80px;
}
@media(max-width: 992px){
gap: 60px;
} */
`;
export const CopyrightCard = styled.div`
  background: ${allColors.mainColor};
  width: 100%;
  padding: 14px 0;
  box-sizing: border-box;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;
export const H4 = styled.h4`
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${allColors.whiteColor};
  /* white-space: nowrap; */
`;

// ==========FooterInfo ============

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  &.maxWidth1 {
    max-width: 384px;
  }
  &.maxWidth2 {
    max-width: 300px;
  }
  &.maxWidth3 {
    max-width: 204px;
  }
  @media (max-width: 820px) {
    &.maxWidth1 {
      max-width: 100%;
    }
  }
`;
export const ColFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  gap: 40px;
  @media (max-width: 820px) {
    width: 100%;
    .logo-vazirlik {
      width: 220px;
    }
    .logo-bog {
      width: 200px;
    }
  }
  @media (max-width: 460px) {
    gap: 20px;
    .logo-vazirlik {
      width: 160px;
    }
    .logo-bog {
      width: 140px;
    }
  }
`;
export const Paragraf = styled.p`
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #aaabad;
`;
export const TitleH3 = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${allColors.shriftColor};
`;
export const TitleCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  justify-content: space-between;
  gap: 30px;
  .flex-div-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
  }
`;
export const TitleSpan = styled.p`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${allColors.shriftColorOpasity3};
  white-space: nowrap;
`;
