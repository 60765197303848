import Api from '../index'

class Technics extends Api {
  sendData(url, data) {
    return this.execute('post', url, data)
  }
  sendDataPatch(url, data) {
    return this.execute('patch', url, data)
  }
  deleteData(url) {
    return this.execute('delete', url)
  }
}

export default Technics