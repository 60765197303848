import React from 'react'
import { TitleH4 } from '../collapseComponent/style'
import {
  InputLabel,
  RadioBoxCard,
  RadioBoxContainer,
  RadioBoxInput,
  Wrapper,
} from './style'

function RadioBoxComponent({ name, Title, setRadioValue, value }) {
  return (
    <Wrapper>
      <TitleH4>{Title}</TitleH4>
      <RadioBoxContainer>
        <RadioBoxCard>
          <RadioBoxInput
            name={name}
            id={`${name + 1}`}
            type={'radio'}
            onChange={() => setRadioValue(true)}
            checked={value}
          />
          <InputLabel htmlFor={`${name + 1}`}>Ha</InputLabel>
        </RadioBoxCard>
        <RadioBoxCard>
          <RadioBoxInput
            name={name}
            id={`${name + 2}`}
            type={'radio'}
            onChange={() => setRadioValue(false)}
            value={!value}
            defaultChecked={true}
          />
          <InputLabel htmlFor={`${name + 2}`}>Yo'q</InputLabel>
        </RadioBoxCard>
      </RadioBoxContainer>
    </Wrapper>
  )
}

export default RadioBoxComponent
