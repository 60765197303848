import styled from "styled-components";

export const TitleCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
export const ContainerAct = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  .input-error {
    border: 1px solid #e54e53;
  }
  .error-span {
    color: #e54e53;
    margin-top: 6px;
    font-size: 13px;
  }
  @media (max-width: 992px) {
    .teskari {
      flex-wrap: wrap-reverse !important;
    }
  }
`;
