import React from "react";
import { Controller } from "react-hook-form";
import { ContentInput } from "./style";

function Input({
  control,
  name,
  disabled,
  required = false,
  handleChange,
  type,
  defaultValue,
  placeholder,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? null}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <ContentInput
          name={name}
          onBlur={onBlur}
          type={type || "number"}
          value={value || ""}
          onChange={(e) => {
            if (handleChange) {
              handleChange(e);
            } else {
              onChange(e);
            }
          }}
          pla
          required={required}
          inputRef={ref}
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder || ""}
          disabled={disabled}
        />
      )}
    />
  );
}

export default Input;
