import { Col, Row } from "antd";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import InputController from "components/input/inputController";
import RadioComponent from "components/input/radioComponent";
import { SelectController } from "components/select/selectController";
import React, { Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { Cards, Hr, TitleH4 } from "../style";

function ARegion({
  control,
  Controller,
  errors,
  options,
  setRegionSale,
  regionSale,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "region_marketing",
  });
  function addRegionInfo() {
    append({
      r_stir: null,
      r_company_name: null,
      r_product_name: null,
      r_production_volume: null,
    });
  }

  return (
    <Cards className="m-0">
      <TitleH4 className="m-0">A. Viloyat ichida:</TitleH4>
      <RadioComponent
        label={"Sotuv bormi?"}
        name={"sales_within_region"}
        setRadioValue={setRegionSale}
        value={regionSale}
      />
      {regionSale === true ? (
        <Row gutter={[16]} className="w-100">
          {fields?.map((item, index) => {
            return (
              <Fragment key={item?.id}>
                {index > 0 ? (
                  <Col span={24} xl={24}>
                    <Hr />
                  </Col>
                ) : null}
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    defaultValue={item?.r_stir}
                    // value={stirState}
                    // onChange={onChange}
                    name={`region_marketing[${index}].r_stir`}
                    label={`${index + 1}. STIR`}
                    required
                    placeholder={"123456789"}
                    inputType={"number"}
                    className={
                      errors &&
                      errors?.region_marketing?.[index]?.r_stir &&
                      "input-error"
                    }
                  />
                  {errors && errors?.region_marketing?.[index]?.r_stir && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`region_marketing[${index}].r_company_name`}
                    label={"Korxona nomi"}
                    defaultValue={item?.r_company_name}
                    required
                    disabled
                    className={
                      errors &&
                      errors?.region_marketing?.[index]?.r_company_name &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.region_marketing?.[index]?.r_company_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  {/* <InputController
                  control={control}
                  Controller={Controller}
                  name={`region_marketing[${index}].r_product_name`}
                  label={'Maxsulot nomi'}
                  defaultValue={item?.r_product_name}
                  required
                  className={
                    errors &&
                    errors?.region_marketing?.[index]?.r_product_name &&
                    'input-error'
                  }
                /> */}
                  <SelectController
                    control={control}
                    Controller={Controller}
                    name={`region_marketing[${index}].r_product_name`}
                    label={"Maxsulot nomi"}
                    options={options}
                    // defaultValue={item?.r_product_name}
                    placeholder="Tanlang"
                    required
                    className={
                      errors &&
                      errors?.region_marketing?.[index]?.r_product_name &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.region_marketing?.[index]?.r_product_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`region_marketing[${index}].r_production_volume`}
                    label={"Ishlab chiqarish xajmi,(tn)"}
                    defaultValue={item?.r_production_volume}
                    required
                    inputType={"number"}
                    className={
                      errors &&
                      errors?.region_marketing?.[index]?.r_production_volume &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.region_marketing?.[index]?.r_production_volume && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col
                  span={24}
                  xl={8}
                  lg={12}
                  sm={12}
                  style={{ alignSelf: "end", marginTop: "16px" }}
                >
                  {index > 0 ? (
                    <Button
                      Icon={
                        <AiOutlineDelete
                          style={{ fontSize: "20px" }}
                          color={allColors.shriftRed}
                        />
                      }
                      TextColor={allColors.shriftRed}
                      backgroundColor={"rgba(235, 87, 87, 0.1)"}
                      Text="O'chirish"
                      Width="120px"
                      OnClickButton={() => remove(index)}
                    />
                  ) : (
                    <Button
                      Icon={<BsPlus style={{ fontSize: "20px" }} />}
                      TextColor={allColors.mainColor}
                      backgroundColor={"#e6f1ef"}
                      Text="Qo’shish"
                      disabled={fields.length === 5 ? true : false}
                      Width="120px"
                      OnClickButton={addRegionInfo}
                    />
                  )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
      ) : (
        ""
      )}
    </Cards>
  );
}

export default ARegion;
