import React, { useState } from "react";
import { Col, Row } from "antd";
import {
  Cards,
  H1,
  H3,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import { ContainerAct } from "pages/agro-kredit/ariza-page/container/style";
import Buttons from "pages/agroleasing/components/button-components/Buttons";
import { useApply } from "../hooks/useApply";
import { Wrapper } from "pages/agroleasing/collapseForApply/components/project-classification/components/fotoUpload/style";
import { LabelMain } from "pages/agroleasing/collapseForApply/components/project-classification/components/fileUpload/style";
import PhotoInfo from "pages/agroleasing/collapseForApply/components/project-classification/components/fotoUpload/photoInfo";
import FileUploadForApply from "../components/fileUpload";
// import { useQuery } from "@tanstack/react-query";
// import { debtTypeApi } from "shared/api/pagesApi";
const LeasingApply = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file6, setFile6] = useState(null);
  const [file7, setFile7] = useState(null);
  const [file8, setFile8] = useState(null);
  const [file9, setFile9] = useState(null);
  const [file10, setFile10] = useState(null);
  const [file11, setFile11] = useState(null);
  const [file12a, setFile12a] = useState(null);
  const [file12b, setFile12b] = useState(null);
  const [file12c, setFile12c] = useState(null);
  const [file13a, setFile13a] = useState(null);
  const [file13b, setFile13b] = useState(null);
  const [file13c, setFile13c] = useState(null);
  const [file14, setFile14] = useState(null);

  const allStates = {
    file1,
    file2,
    file3,
    file4,
    file5,
    file6,
    file7,
    file8,
    file9,
    file10,
    file11,
    file12a,
    file12b,
    file12c,
    file13a,
    file13b,
    file13c,
    file14,
  };
  const { onSubmit, isLoading } = useApply({ ...allStates });
  // const debType = localStorage.getItem('debtType');
  // const applicationL = localStorage.getItem('application_l');

  async function deleteFile({ setstate }) {
    setstate(null);
  }
  // useQuery(['deb-type-logistics'], () =>
  //   debtTypeApi.debtTypeStep({
  //     debt_type: debType,
  //     application_l: Number(applicationL),
  //   })
  // );

  return (
    <MainContainer>
      <MarginContainer>
        <H1>Agrologistika markazi lizing asosida barpo etish haqida ariza</H1>
        <ContainerAct>
          <Row gutter={[16, 16]}>
            <Col sm={24} lg={24} xl={24}>
              <form onSubmit={onSubmit}>
                <Cards>
                  <H3>Arizani to’ldiring</H3>
                  <Row gutter={[16, 16]}>
                    <Col span={24} lg={24}>
                      {!file1 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile1}
                          label="1. Ariza"
                          nameId="file1"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>1. Ariza</LabelMain>
                          <PhotoInfo
                            file={file1}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile1,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file2 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile2}
                          label="2. Shartnoma"
                          nameId="file2"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>2. Shartnoma</LabelMain>
                          <PhotoInfo
                            file={file2}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile2,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file3 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile3}
                          label="3. Nizom"
                          nameId="file3"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>3. Nizom</LabelMain>
                          <PhotoInfo
                            file={file3}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile3,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file4 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile4}
                          label="4. Guvohnoma"
                          nameId="projectInitiator"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>4. Guvohnoma</LabelMain>
                          <PhotoInfo
                            file={file4}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile4,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file5 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile5}
                          label="5. Loyihaning texnik-iqtisodiy asosi; (Savolnoma taqdim etiladi)"
                          nameId="cadastrPermission"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            5. Loyihaning texnik-iqtisodiy asosi; (Savolnoma
                            taqdim etiladi)
                          </LabelMain>
                          <PhotoInfo
                            file={file5}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile5,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file6 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile6}
                          label="6. Xo‘jalik yurituvchi sub'ekt ro‘yxatdan o‘tkazilgan joydagi soliq organi tomonidan tasdiqlanadigan oxirgi hisobot davri uchun moliyaviy xujjatlar; (F1, F2)."
                          nameId="file6"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            6. Xo‘jalik yurituvchi sub'ekt ro‘yxatdan
                            o‘tkazilgan joydagi soliq organi tomonidan
                            tasdiqlanadigan oxirgi hisobot davri uchun moliyaviy
                            xujjatlar; (F1, F2).
                          </LabelMain>
                          <PhotoInfo
                            file={file6}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile6,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file7 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile7}
                          label="7. Kadastr xujjati"
                          nameId="file7"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>7. Kadastr xujjati</LabelMain>
                          <PhotoInfo
                            file={file7}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile7,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file8 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile8}
                          label="8. Erga egalik xujjati"
                          nameId="file8"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>8. Erga egalik xujjati</LabelMain>
                          <PhotoInfo
                            file={file8}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile8,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file9 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile9}
                          label="9. Xududiy qurilish va uy-joy kommunal boshqarmasidan qurilish uchun ruxsatnoma"
                          nameId="file9"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            9. Xududiy qurilish va uy-joy kommunal
                            boshqarmasidan qurilish uchun ruxsatnoma
                          </LabelMain>
                          <PhotoInfo
                            file={file9}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile9,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file10 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile10}
                          label="10. Xizmat ko‘rsatuvchi bankdan kreditorlik qarzdorligi mavjud emasligi xaqida ma'lumotnoma"
                          nameId="file10"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            10. Xizmat ko‘rsatuvchi bankdan kreditorlik
                            qarzdorligi mavjud emasligi xaqida ma'lumotnoma
                          </LabelMain>
                          <PhotoInfo
                            file={file10}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile10,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file11 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile11}
                          label="11. Davlat soliq inspeksiyasidan kreditor qarzdorligi to‘g‘risida ma'lumotnoma;"
                          nameId="file11"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            11. Davlat soliq inspeksiyasidan kreditor
                            qarzdorligi to‘g‘risida ma'lumotnoma
                          </LabelMain>
                          <PhotoInfo
                            file={file11}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile11,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <div style={{ width: "100%" }}>
                      <LabelMain>
                        12. Xar bir xarid qilinadigan uskunalar uchun 3 tadan
                        tijorat taklifi
                      </LabelMain>
                      <Row gutter={[16, 16]}>
                        <Col span={24} lg={8}>
                          {!file12a ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile12a}
                              label="A)"
                              nameId="file12a"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>A)</LabelMain>
                              <PhotoInfo
                                file={file12a}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile12a,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                        <Col span={24} lg={8}>
                          {!file12b ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile12b}
                              label="B)"
                              nameId="file12b"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>B)</LabelMain>
                              <PhotoInfo
                                file={file12b}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile12b,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                        <Col span={24} lg={8}>
                          {!file12c ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile12c}
                              label="C)"
                              nameId="file12c"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>C)</LabelMain>
                              <PhotoInfo
                                file={file12c}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile12c,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div style={{ display: "block", width: "100%" }}>
                      <LabelMain>
                        13. Xar bir xarid qilinadigan avtotexnika uchun 3 tadan
                        tijorat taklifi
                      </LabelMain>
                      <Row gutter={[16, 16]}>
                        <Col span={24} lg={8}>
                          {!file13a ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile13a}
                              label="A)"
                              nameId="file13a"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>A)</LabelMain>
                              <PhotoInfo
                                file={file13a}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile13a,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                        <Col span={24} lg={8}>
                          {!file13b ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile13b}
                              label="B)"
                              nameId="file13b"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>B)</LabelMain>
                              <PhotoInfo
                                file={file13b}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile13b,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                        <Col span={24} lg={8}>
                          {!file13c ? (
                            <FileUploadForApply
                              setTechnicsFoto={setFile13c}
                              label="C)"
                              nameId="file13c"
                            />
                          ) : (
                            <Wrapper className="apply-upload-wrap">
                              <LabelMain>C)</LabelMain>
                              <PhotoInfo
                                file={file13c}
                                deleteFile={() =>
                                  deleteFile({
                                    setstate: setFile12c,
                                  })
                                }
                              />
                            </Wrapper>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <Col span={24} lg={24} md={24}>
                      {!file14 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile14}
                          label="14. Tuman arxitektura va shaharsozlik tomonidan magistral qizil chizig‘iga tushmaganligi bo‘yicha ma'lumot (1 oylik) muddat bilan"
                          nameId="file14"
                        />
                      ) : (
                        <Wrapper className="apply-upload-wrap">
                          <LabelMain>
                            14. Tuman arxitektura va shaharsozlik tomonidan
                            magistral qizil chizig‘iga tushmaganligi bo‘yicha
                            ma'lumot (1 oylik) muddat bilan
                          </LabelMain>
                          <PhotoInfo
                            file={file14}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile14,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                  </Row>
                  <Buttons disabled={isLoading} />
                </Cards>
              </form>
            </Col>
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
};

export default LeasingApply;
