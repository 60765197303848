import { Col, Row } from "antd";
import {
  Cards,
  H1,
  H3,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import { ContainerAct } from "pages/agro-kredit/ariza-page/container/style";
import React, { useState } from "react";
import FileUploadForApply from "../components/fileUpload";
import { Wrapper } from "pages/agroleasing/collapseForApply/components/project-classification/components/fotoUpload/style";
import { LabelMain } from "pages/agroleasing/collapseForApply/components/project-classification/components/fileUpload/style";
import PhotoInfo from "pages/agroleasing/collapseForApply/components/project-classification/components/fotoUpload/photoInfo";
import Buttons from "pages/agroleasing/components/button-components/Buttons";
import { useQuery } from "@tanstack/react-query";
import { applicationApi } from "shared/api/pagesApi";
import { useApplyV2 } from "../hooks/useApply.v2";

const LeasingApplyV2 = () => {
  const [apply, setApply] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file6, setFile6] = useState(null);
  const [file7, setFile7] = useState(null);
  const [file8, setFile8] = useState(null);
  const [file9, setFile9] = useState(null);
  const [file10, setFile10] = useState(null);

  async function deleteFile({ setstate }) {
    setstate(null);
  }
  const { data } = useQuery(["get-application-l"], () =>
    applicationApi.getApplication()
  );
  const debtType = localStorage.getItem("debtType");
  const props = {
    apply,
    file2,
    file3,
    file4,
    file5,
    file6,
    file7,
    file8,
    file9,
    file10,
    application_l: data?.application_l_garden,
  };
  const { onSubmit, isLoading } = useApplyV2({ ...props });
  // const debType = localStorage.getItem('debtType');
  // const applicationL = localStorage.getItem('application_l');
  // useQuery(['deb-type-garden'], () =>
  //   debtTypeApi.debtTypeStep({
  //     debt_type: debType,
  //     application_l: Number(applicationL),
  //   })
  // );

  return (
    <MainContainer>
      <MarginContainer>
        <H1>Intensiv bog' va tokzorlar haqida ariza</H1>
        <ContainerAct>
          <Row gutter={[16, 16]}>
            <Col sm={24} lg={24} xl={24}>
              <form onSubmit={onSubmit}>
                <Cards>
                  <H3>Arizani to’ldiring</H3>
                  <Row gutter={[16, 16]}>
                    <Col span={24} lg={24}>
                      {!apply ? (
                        <FileUploadForApply
                          setTechnicsFoto={setApply}
                          label="1. Ariza"
                          nameId="apply"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>1. Ariza</LabelMain>
                          <PhotoInfo
                            file={apply}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setApply,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file2 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile2}
                          label="2. «Agroservis operator» DUK bilan tuzilgan dastlabki shartnoma"
                          nameId="file2"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            2. «Agroservis operator» DUK bilan tuzilgan
                            dastlabki shartnoma
                          </LabelMain>
                          <PhotoInfo
                            file={file2}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile2,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file3 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile3}
                          label="3. Ariza beruvchi korxona nizomi"
                          nameId="file3"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            3. Ariza beruvchi korxona nizomi
                          </LabelMain>
                          <PhotoInfo
                            file={file3}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile3,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file4 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile4}
                          label="4. Ariza beruvchi korxona guvoxnomasi"
                          nameId="file4"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            4. Ariza beruvchi korxona guvoxnomasi
                          </LabelMain>
                          <PhotoInfo
                            file={file4}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile4,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={24}>
                      {!file5 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile5}
                          label="5. Hokimlikning bog‘ va uzumzorlar tashkil etish uchun yer maydonini ajratish bo‘yicha qarori (nusxasi)"
                          nameId="file5"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            5. Hokimlikning bog‘ va uzumzorlar tashkil etish
                            uchun yer maydonini ajratish bo‘yicha qarori
                            (nusxasi)
                          </LabelMain>
                          <PhotoInfo
                            file={file5}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile5,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={10}>
                      {!file6 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile6}
                          label="6. Ushbu qaror xaqiqatdan xam kuchda ekanligi bo‘yicha Xokimyat tomonidan taqdim qilingan ma'lumotnoma (1 oylik) muddat bilan"
                          nameId="file6"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            6. Ushbu qaror xaqiqatdan xam kuchda ekanligi
                            bo‘yicha Xokimyat tomonidan taqdim qilingan
                            ma'lumotnoma (1 oylik) muddat bilan
                          </LabelMain>
                          <PhotoInfo
                            file={file6}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile6,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file7 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile7}
                          label="7. Er uchastkasining KMZ formatdagi xaritasi"
                          nameId="file7"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            7. Er uchastkasining KMZ formatdagi xaritasi
                          </LabelMain>
                          <PhotoInfo
                            file={file7}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile7,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file8 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile8}
                          label="8. Sanitariya-epidemiologiya stansiya xulosasi"
                          nameId="file8"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            8. Sanitariya-epidemiologiya stansiya xulosasi
                          </LabelMain>
                          <PhotoInfo
                            file={file8}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile8,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file9 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile9}
                          label="9. Kadastr bo‘limidan ajratilgan yer uchastkasining garov ostida emasligi to‘g‘risida yer uchastkasining kontur raqamlari ko‘rsatilgan ma'lumotnoma(1 oylik)"
                          nameId="file9"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            9. Kadastr bo‘limidan ajratilgan yer uchastkasining
                            garov ostida emasligi to‘g‘risida yer uchastkasining
                            kontur raqamlari ko‘rsatilgan ma'lumotnoma(1 oylik)
                          </LabelMain>
                          <PhotoInfo
                            file={file9}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile9,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                    <Col span={24} lg={16}>
                      {!file10 ? (
                        <FileUploadForApply
                          setTechnicsFoto={setFile10}
                          label="10. Arxitektura va shaharsozlik tomonidan magistral qizil chizig‘iga tushmaganligi bo‘yicha ma'lumotnoma (1 oylik) muddat bilan"
                          nameId="filelast"
                        />
                      ) : (
                        <Wrapper className="">
                          <LabelMain>
                            10. Arxitektura va shaharsozlik tomonidan magistral
                            qizil chizig‘iga tushmaganligi bo‘yicha ma'lumotnoma
                            (1 oylik) muddat bilan
                          </LabelMain>
                          <PhotoInfo
                            file={file10}
                            deleteFile={() =>
                              deleteFile({
                                setstate: setFile10,
                              })
                            }
                          />
                        </Wrapper>
                      )}
                    </Col>
                  </Row>
                  <Buttons disabled={isLoading} />
                </Cards>
              </form>
            </Col>
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
};

export default LeasingApplyV2;
