import React from "react";
import {
  InputLabel,
  RadioBoxCard,
  RadioBoxInput,
  InputContainer,
  Label,
  RadioBoxContainer,
} from "./style";

function RadioComponent({ label, name, setRadioValue, value, data, wrap }) {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <RadioBoxContainer>
        {data?.map((x, i) => (
          <RadioBoxCard key={i}>
            <RadioBoxInput
              name={name}
              id={`${x?.title + name}`}
              type={"radio"}
              onChange={() => setRadioValue(x?.id)}
              checked={x.id === value ? true : false}
            />
            <InputLabel htmlFor={`${x?.title + name}`}>{x?.title}</InputLabel>
          </RadioBoxCard>
        ))}
      </RadioBoxContainer>
    </InputContainer>
  );
}

export default RadioComponent;
