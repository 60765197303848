import { DatePicker } from "antd";
import React from "react";
import moment from "moment";
import "moment/locale/zh-cn";

import { InputContainer, Label } from "./styled";
function DatePickerComponent({
  label,
  name,
  disabled,
  Controller,
  control,
  required = false,
  defaultValue,
  format = "YYYY.MM.DD",
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      defaultValue={moment(defaultValue, "YYYY-MM-DD") || null}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <InputContainer>
          <Label htmlFor={name}>{label}</Label>
          <DatePicker
            className="date-picker"
            onChange={onChange} // send value to hook form
            id={name}
            onBlur={onBlur} // notify when input is touched
            inputRef={ref}
            placeholder={"YYYY.MM.DD"}
            value={value}
            // defaultPickerValue={dayjs(dayjs('2019-01-25').format('DD/MM/YYYY')) }
            defaultValue={defaultValue}
            disabled={disabled}
            format={format}
          />
          {/* <ConfigProvider locale={locale}>
            <DatePicker
              className="date-picker"
              onChange={onChange} // send value to hook form
              id={name}
              onBlur={onBlur} // notify when input is touched
              inputRef={ref}
              placeholder={'YYYY.MM.DD'}
              value={value}
              defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
              disabled={disabled}
              format={'YYYY.MM.DD'}
            />
          </ConfigProvider> */}
        </InputContainer>
      )}
    />
  );
}

export default DatePickerComponent;
