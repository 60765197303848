import React, { useState } from 'react';
import useCollapse from 'react-collapsed';
import { Card, CardTopWrapper, CardTitle } from './tableInAccordionStyles';
import { BsCheckCircle } from 'react-icons/bs';
import useAccordionStore from '../../../../../zustand/accordionData';
import { useEffect } from 'react';

export default function Accordion({
  Cardtitle,
  stepTitle,
  RowComponents,
  step,
}) {
  const accordionData = useAccordionStore((state) => state.accordionData);
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps } = useCollapse({ isExpanded });
  // console.log(accordionData)
  useEffect(() => {
    if (accordionData.step === step) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [accordionData, step]);
  return (
    <>
      <Card>
        <CardTopWrapper>
          <CardTitle>
            <span>{stepTitle}</span> {Cardtitle}
          </CardTitle>

          <BsCheckCircle
            className={
              accordionData.step > step
                ? `arrow-open`
                : accordionData.step === step
                ? 'arrow-close'
                : `arrow-close`
            }
          />
        </CardTopWrapper>
        <section {...getCollapseProps()}>{RowComponents}</section>
      </Card>
    </>
  );
}
