import React, { useState } from 'react';
import { TitleH4, Wrapper } from './style';
import { Col, Row } from 'antd';
import FileUpload from 'components/fileUpload';
import PhotoInfo from './fotoUpload/photoInfo';
import RadioBoxComponent from 'pages/agroleasing/components/radioBoxContainer';
import FileUploadForApply from './fileUpload';
import { LabelMain } from './fileUpload/style';
import { technicsApi } from 'shared/api/pagesApi';
import Maps from './Maps';

function ProjectDrawing() {
  const [technicsFoto, setTechnicsFoto] = useState([]);
  const [fileId, setFileId] = useState([]);
  const [foundersCharter, setFoundersCharter] = useState(null);
  const [foundersCharterId, setFoundersCharterId] = useState(null);
  const [presentationBormi, setPresentationBormi] = useState(false);
  async function deleteFoto({ setstate, state, id, fileid, setfileid }) {
    setstate(state.filter((item, index) => index !== id));
    let a = fileid.filter((v, i) => i === id);
    setfileid(fileid.filter((v, i) => i !== id));
    await technicsApi.deleteData(`/file/delete/${a}/`);
  }
  async function deleteFile({ setstate, id, setfileid }) {
    await technicsApi.deleteData(`/file/delete/${id}/`);

    setfileid(null);
    setstate(null);
  }
  return (
    <Wrapper className="mt-4">
      <TitleH4 style={{ textAlign: 'center' }}>
        Loyihaning chizmalari va prezentatsiyalari
      </TitleH4>
      <Row gutter={[16, 12]}>
        <Col span={24} lg={12} md={12}>
          <Row gutter={[12, 12]}>
            <Col span={24} lg={24}>
              <Wrapper>
                <FileUpload
                  setTechnicsFoto={setTechnicsFoto}
                  label="Loyihaning chizmalari"
                  setFileId={setFileId}
                  fileId={fileId}
                  nameId="uskunaId"
                />
              </Wrapper>
            </Col>
            {technicsFoto.map((item, index) => (
              <Col span={24} xl={24} lg={24} md={24} sm={24} key={index}>
                <PhotoInfo
                  file={item}
                  deleteFile={() =>
                    deleteFoto({
                      setstate: setTechnicsFoto,
                      state: technicsFoto,
                      fileid: fileId,
                      setfileid: setFileId,
                      id: index,
                    })
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={24} lg={12} md={12}>
          <Row gutter={[12, 12]}>
            <Col span={24} lg={24}>
              <RadioBoxComponent
                name={'recycled-equipment'}
                Title={'Qayta ishlash uskunalari mavjudligi:'}
                setRadioValue={setPresentationBormi}
                value={presentationBormi}
              />
            </Col>
            {presentationBormi && (
              <Col span={24} lg={24}>
                {!foundersCharterId ? (
                  <FileUploadForApply
                    setTechnicsFoto={setFoundersCharter}
                    label="Loyihaning prezentatsiyasi"
                    setFileId={setFoundersCharterId}
                    fileId={foundersCharterId}
                    nameId="founders_charter_id"
                  />
                ) : (
                  <Wrapper>
                    <LabelMain>
                      Loyihaning chizmalari va prezentatsiyalari
                    </LabelMain>
                    <PhotoInfo
                      file={foundersCharter}
                      deleteFile={() =>
                        deleteFile({
                          setstate: setFoundersCharter,
                          id: foundersCharterId,
                          setfileid: setFoundersCharterId,
                        })
                      }
                    />
                  </Wrapper>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 12]}>
        <Col span={24} lg={24}>
          <TitleH4 style={{ textAlign: 'center', marginTop: '16px' }}>
            Geolokatsiya
          </TitleH4>
        </Col>
        <Col span={24} lg={24}>
          <Maps />
        </Col>
      </Row>
      <Row gutter={[16, 12]}>
        <>textarea uchun</>
      </Row>
    </Wrapper>
  );
}

export default ProjectDrawing;
