import { MainContainer } from 'components/styles/styleComponetns';
import React from 'react';
import { Container, CopyrightCard, H4 } from '../container/style';
import CofDIcon from 'assets/images/footer/CofDIcon.svg'

function CopyrightFooter() {
    return (
        <CopyrightCard>
            <MainContainer>
                <Container>
                    <H4>
                        © 2022; Bog’dorchilik va issiqxona xo’jaligini rivojlantirish agentligi
                    </H4>
                    <img src={CofDIcon} alt="icons" />
                </Container>
            </MainContainer>
        </CopyrightCard>
    );
}

export default CopyrightFooter;