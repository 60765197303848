import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  .input-error {
    border: 1px solid #e54e53;
  }
  .error-span {
    color: #e54e53;
    margin-top: 6px;
    font-size: 13px;
  }
`;
export const TitleH4 = styled.h3`
  margin: 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* margin-bottom: -12px; */
  margin-top: 24px;
`;
export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid #dadada;
  border-radius: 12px;
  margin-top: 24px;
  width: 100%;
`;
export const Hr = styled.div`
  width: 100%;
  border: 1px solid #dadada;
  margin: 0;
  /* height: 1px; */
  margin-top: 16px;
`;
export const ButtonWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;
export const MarketingWraps = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
