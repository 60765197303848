import React from "react";
import {
  FileIconCard,
  FileNameCard,
  FileNameSpan,
  FotoInfoCard,
} from "./style";
import { IoClose } from "react-icons/io5";
import { AiFillFileText } from "react-icons/ai";

function PhotoInfo({ file, deleteFile }) {
  return (
    <FotoInfoCard>
      <FileIconCard>
        <AiFillFileText className="img-style-file" />
        <FileNameCard>
          <FileNameSpan>
            {`${
              file?.name?.length > 12
                ? file?.name?.slice(0, 6) + "..." + file?.name?.slice(-6)
                : file?.name
            }`}
            {/* {file?.name?.slice(file?.name?.length - 4, 4)} */}
          </FileNameSpan>
          <span>{Math.round(file?.size / 1000) + " KB"}</span>
        </FileNameCard>
      </FileIconCard>
      <IoClose className="close-icon" onClick={deleteFile} />
    </FotoInfoCard>
  );
}

export default PhotoInfo;
