import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import React from "react";
import { ButtonContainer } from "../container/style";

function Buttons({ disabled }) {
  return (
    <ButtonContainer>
      <Button
        Width={"140px"}
        Text="Bekor qilish"
        TextColor={allColors.shriftRed}
        BorderColor={allColors.shriftRed}
        backgroundColor={allColors.whiteColor}
        typeButton="button"
        disabled={disabled}
      />
      <Button
        Width={"140px"}
        Text="Davom etish"
        TextColor={allColors.whiteColor}
        backgroundColor={allColors.mainColor}
        // OnClickButton={toggle}
        disabled={disabled}
        typeButton="submit"
      />
    </ButtonContainer>
  );
}

export default Buttons;
