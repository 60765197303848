import React from "react";
import Table1 from "./Table1";
import Table2 from "./Table2";
import Table3 from "./Table3";
import ButtonComponent from "../buttonComponent";
import { TitleH4, SecondaryTitle } from "./style";
import { Wrapper, ProductionSalesContainer } from "./style";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";
import { SelectController } from "components/select/selectController";

import { useProductAndSeles } from "./hooks_";

export default function ProductionAndSales() {
  const {
    goBack,
    getCalc,
    getCalcTable1,
    control,
    isLoading,
    sale_programsValue,
    productList,
    productTotal,
    fieldsTable1,
    fieldsTable2,
    onSubmit,
    handleSubmit,
  } = useProductAndSeles();

  return (
    <>
      <Wrapper>
        <TitleH4>
          Fermer xo’jaligi tomonidan ishlab chiqariladigan maxsulot turlari
          quyidagilardan iborat:
        </TitleH4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProductionSalesContainer>
            <Row gutter={[16, 16]}>
              <Col span={24} md={12} style={{ margin: "24px 0" }}>
                <SelectController
                  control={control}
                  Controller={Controller}
                  name={"product"}
                  label={
                    "Maxsulotlarni tanlang (bir nechta maxsulot tanlash imkoni mavjud)"
                  }
                  placeholder="Tanlang"
                  options={productList}
                  isMulti={true}
                  required
                />
              </Col>
            </Row>
            <TitleH4>
              1. Fermer xo’jaligi bir mavsumda ishlab chiqriladigan maxsulot sof
              miqdori
            </TitleH4>

            <Table1
              control={control}
              Controller={Controller}
              // tableList={watch("product") !== null ? watch("product") : []}
              tableList={fieldsTable1}
              getCalcTable1={getCalcTable1}
            />
            <TitleH4>
              2. Fermer xo’jaligi tomonnidan bir mavsumda ishlab chiqarilgan
              maxsulotlarni sotish dasturi
            </TitleH4>
            <Table2
              control={control}
              Controller={Controller}
              tableList={fieldsTable2}
              getCalcAmount={getCalc}
              product={productTotal}
            />
            <TitleH4>3. Ishlab chiqarish dasturi</TitleH4>
            <SecondaryTitle>
              Fermer xo'jaligi o'zining yillik ish rejasini quyidagicha
              rejalashtirgan:
            </SecondaryTitle>
            <Table3
              control={control}
              Controller={Controller}
              productList={productList}
              tableList={sale_programsValue}
            />
          </ProductionSalesContainer>
          <ButtonComponent goBack={goBack} disabled={isLoading} />
        </form>
      </Wrapper>
    </>
  );
}
