import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const Card = styled.div`
  background-color: ${allColors.whiteColor};
  padding: 24px;
  border-radius: 16px;
`

export const CardTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .arrow-close {
    display: none;
  }
  .arrow-open {
    font-size: 24px;
    color: ${allColors.mainColor};
    width: 40px;
  }
`

export const CardTitle = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${allColors.shriftColor};
  margin: 0;
  padding: 0;
  span {
    color: ${allColors.shriftColorOpasity3};
  }
`
