import { Col, Row } from "antd";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import {
  H1,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import { ContainerAct } from "pages/agro-kredit/ariza-page/container/style";
import React from "react";
import Activity from "../components/collapseComponent/Activity";
import Accordion from "../components/collapse/tableInAccordion";
import { CollapseContainer, TitleCard } from "./style";
import ProjectCollapse from "../components/collapseComponent/Project";
import MarketingCollapse from "../components/collapseComponent/Marketing";
import Technologies from "../components/collapseComponent/Technologies";
import SuccessCollapse from "../components/successCollapse";
import FinancePlans from "../components/collapseComponent/financePlan/financePlans";
import ProductionAndSales from "../components/collapseComponent/productionAndSales";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAccordionStore from "../../../../zustand/accordionData";
import { FinancePlanV2 } from "../components/collapseComponent/financeplanv2";
import useHooks from "./useHooks";

function BusinessPlan() {
  let debtType = localStorage.getItem("dep_type");
  // const navigate = useNavigate();
  const location = useLocation();
  const { onCancelButton } = useHooks();
  const editStatus = useAccordionStore((state) => state.editStatus);

  useEffect(() => {
    const getStep = JSON.parse(localStorage.getItem("userStep"));
    if (getStep && getStep > 1) {
      editStatus({
        step: getStep,
      });
    }
  }, [location.pathname]);

  return (
    <MainContainer>
      <MarginContainer>
        <TitleCard>
          <H1>Kredit olish uchun online ariza berish </H1>
          <Button
            Text={"Bekor qilish"}
            backgroundColor={"white"}
            TextColor={allColors.shriftRed}
            BorderColor={allColors.shriftRed}
            Width={"140px"}
            OnClickButton={() => onCancelButton(-1)}
          />
        </TitleCard>
        <ContainerAct>
          <Row gutter={[16, 16]} className={"teskari"}>
            <Col span={24} sm={24} lg={16} xl={18}>
              <CollapseContainer>
                <Accordion
                  stepTitle={"1-qadam."}
                  step={2}
                  Cardtitle="Fermer xo'jaligining faoliyati to'g'risida umumiy ma'lumot"
                  RowComponents={<Activity />}
                />
                <Accordion
                  stepTitle={"2-qadam."}
                  step={3}
                  Cardtitle="Loyiha"
                  RowComponents={<ProjectCollapse />}
                />
                <Accordion
                  stepTitle={"3-qadam."}
                  step={4}
                  Cardtitle={
                    debtType === "HARVEST"
                      ? "Ishlab chiqarish va sotish dasturi  "
                      : "Texnologiyalar"
                  }
                  RowComponents={
                    debtType === "HARVEST" ? (
                      <ProductionAndSales />
                    ) : (
                      <Technologies />
                    )
                  }
                />

                <Accordion
                  stepTitle={"4-qadam."}
                  step={5}
                  Cardtitle="Marketing"
                  RowComponents={<MarketingCollapse debtType={debtType} />}
                />
                <Accordion
                  stepTitle={"5-qadam."}
                  step={6}
                  Cardtitle="Moliyaviy reja"
                  RowComponents={
                    debtType === "HARVEST" ? (
                      <FinancePlanV2 />
                    ) : (
                      <FinancePlans />
                    )
                  }
                />
              </CollapseContainer>
            </Col>
            <Col span={24} sm={24} lg={8} xl={6}>
              <SuccessCollapse />
            </Col>
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
}

export default BusinessPlan;
