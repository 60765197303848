import styled from 'styled-components';

export const Wrapper = styled('div')``;
export const Content = styled('div')`
  padding: 56px 0;
`;

export const List = styled('ul')``;
export const ListItem = styled('li')`
  list-style: none;
  padding: 29px 0;
  border: 0.5px solid #d7d7d7;
  border-left: 0;
  border-right: 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #60676d;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }
  span {
    width: 80%;
    text-decoration: underline;
  }
`;

export const Comment = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  min-height: 130px;
  background: rgba(250, 89, 72, 0.1);
  border: 1px solid rgba(250, 89, 72, 0.4);
  border-radius: 12px;
  margin: 24px 0;
  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #a5a5a5;
  }
  p {
    color: #60676d;
    letter-spacing: 0.1px;
  }
`;

export const Footer = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  padding: 0 24px;
  button {
    width: 142px;
    height: 44px;
    border: 1px solid #9cc6bf;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    &.error-btn {
      color: #fa5948;
      border: 1px solid #fa5948;
      background-color: transparent;
    }
    &.confirm {
      background: #08705f !important;
    }
    &.confirm:disabled {
      background: #9cc6bf;
      opacity: 0.6;
      color: #fff;
    }
  }
`;

export const CommentContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
