import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
  h3 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
  p {
    text-align: justify;
    font-size: 14px;
    font-weight: 500;
    font-family: "Raleway";
  }
`;
