import { useProducts } from "./useProducts";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutate } from "./useMutate";
import { useEffect, useState } from "react";
import { applyApi } from "shared/api/pagesApi";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

export function useProductAndSeles() {
  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      productions: [],
      sales: [],
      sale_program: [
        {
          ps_amount_3: null,
          ps_name_3: "Ish kuni davomiyligi",
          ps_unit_3: "soat",
        },
        {
          ps_amount_3: null,
          ps_name_3: "Bir yilda ish kunlari soni",
          ps_unit_3: "kun",
        },
        {
          ps_amount_3: null,
          ps_name_3: "Yillik ishlab chiqarilgan mahsulot hajmi",
          ps_unit_3: "so'm",
        },
        {
          ps_amount_3: null,
          ps_name_3: "Yangi tashkil etilayotgan ish o'rinlari",
          ps_unit_3: "kishi",
        },
      ],
    },
  });
  const {
    fields: fieldsTable1,
    append: appendTable1,
    remove: removeTable1,
  } = useFieldArray({
    control,
    name: "productions",
  });
  const {
    fields: fieldsTable2,
    append: appendTable2,
    remove: removeTable2,
  } = useFieldArray({
    control,
    name: "sales",
  });
  const productSelectValue = useWatch({
    control,
    name: "product",
  });
  const productionsTable1 = useWatch({
    control,
    name: "productions",
  });
  const salesTable2 = useWatch({
    control,
    name: "sales",
  });
  const sale_programsValue = useWatch({
    control,
    name: "sale_program",
  });
  const editStatus = useAccordionStore((state) => state.editStatus);
  const accordionData = useAccordionStore((state) => state.accordionData);
  const { data: productList = [] } = useProducts();
  const getStep = localStorage.getItem("userStep");
  const [hasUpdate, setHasUpdate] = useState(true);
  const [productCopy, setProductCopy] = useState([]);

  const [productTotal, setProductTotal] = useState(0);
  const { onSubmit, isLoading } = useMutate({
    getCalcTable1,
    getCalc,
    product: productTotal,
    hasUpdate,
  });
  const addProduct = () => {
    const differentLabels1 = productSelectValue.find(
      (itemA) =>
        productionsTable1.some((itemB) => {
          return itemA.value === itemB.ps_product_name_1.value;
        }) === false
    );
    const salesLAble2 = productSelectValue.find(
      (itemA) =>
        salesTable2.some(
          (itemB) => itemA.value === itemB.ps_product_name_2.value
        ) === false
    );
    appendTable1({
      ps_product_name_1: differentLabels1,
      ps_amount_1: null,
      ps_net_amount_1: null,
      ps_unit_1: "tonna",
      ps_waste_amount_1: null,
    });
    appendTable2({
      ps_amount_2: null,
      ps_cost_amount_2: null,
      ps_price_2: null,
      ps_product_name_2: salesLAble2,
      ps_total_amount_2: null,
      ps_unit_2: "tonna",
    });
    setProductCopy(productSelectValue);
  };
  const removeProduct = () => {
    const deleteProductCopy = productCopy.find(
      (itemA) =>
        productSelectValue.some((itemB) => itemA.value === itemB.value) ===
        false
    );
    salesTable2.forEach((element, index) => {
      if (element.ps_product_name_2.value === deleteProductCopy.value) {
        removeTable2(index);
      }
    });
    productionsTable1.forEach((element, index) => {
      if (element.ps_product_name_1.value === deleteProductCopy.value) {
        removeTable1(index);
      }
    });
    setProductCopy(productSelectValue);
  };

  useQuery(
    ["get_sales_update-v2", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data-v2?process_step=${getStep}`),
    {
      select: (res) => {
        let a = productList.filter(function (item) {
          return res?.productions.some(function (element) {
            return element.ps_product_name_1 === item.value;
          });
        });
        return {
          ...res,
          product: productList.filter(function (item) {
            return res?.productions.some(function (element) {
              return element.ps_product_name_1 === item.value;
            });
          }),
          productions: res?.productions.map((item) => {
            const matchedItem = a.find(
              (elem) => elem.value === item.ps_product_name_1
            );
            return {
              ...item,
              ps_product_name_1: matchedItem ? matchedItem : null,
            };
          }),
          sales: res?.sales.map((item) => {
            const matchedItem = a.find(
              (elem) => elem.value === item.ps_product_name_2
            );
            return {
              ...item,
              ps_product_name_2: matchedItem ? matchedItem : null,
            };
          }),
        };
      },
      onSuccess: (res) => {
        if (res) {
          reset({ ...res });
          setProductCopy(res?.product);
          localStorage.setItem("app_id", res?.ps_project);
        }
        setHasUpdate(true);
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled:
        parseInt(getStep) === 4 &&
        localStorage.getItem("dep_type") === "HARVEST"
          ? true
          : false,
    }
  );
  function getCalc(index) {
    setTimeout(() => setProductTotal(1), 0);
    if (
      !isNaN(
        watch(`sales[${index}].ps_amount_2`) *
          watch(`sales[${index}].ps_price_2`)
      )
    ) {
      let rowSum =
        Number(watch(`sales[${index}].ps_amount_2`)) *
        Number(watch(`sales[${index}].ps_price_2`));
      let total = watch("sales")?.reduce((current, item, i) => {
        let next = !isNaN(Number(item.ps_amount_2) * Number(item.ps_price_2))
          ? Number(item.ps_amount_2) * Number(item.ps_price_2)
          : 0;
        return current + next;
      }, 0);
      setTimeout(() => setProductTotal(total), 0);
      return rowSum;
    } else {
      return 0;
    }
  }
  function getCalcTable1(index) {
    if (
      !isNaN(
        watch(`productions[${index}].ps_amount_1`) -
          watch(`productions[${index}].ps_waste_amount_1`)
      )
    ) {
      let rowSum =
        watch(`productions[${index}].ps_amount_1`) -
        watch(`productions[${index}].ps_waste_amount_1`);

      return rowSum;
    } else {
      return 0;
    }
  }

  function goBack() {
    editStatus({
      step: 3,
    });
    localStorage.setItem("userStep", 3);
  }

  useEffect(() => {
    if (productSelectValue?.length > productionsTable1?.length) {
      addProduct();
    } else if (productSelectValue?.length < productionsTable1?.length) {
      removeProduct();
    }
  }, [productSelectValue]);
  return {
    goBack,
    getCalc,
    getCalcTable1,
    control,
    isLoading,
    sale_programsValue,
    productList,
    productTotal,
    fieldsTable1,
    fieldsTable2,
    onSubmit,
    handleSubmit,
  };
}
