import React from "react";
import { Card, Title, Wrapper, Wraplist, CardInfo } from "./style";
import { ReactComponent as IconFile } from "assets/images/landing/file.svg";
import { ReactComponent as IconList } from "assets/images/landing/list.svg";
import { ReactComponent as IconRejected } from "assets/images/landing/rejected.svg";
import { ReactComponent as IconMoney } from "assets/images/landing/money.svg";

const Statistics = ({ statistic }) => {
  return (
    <Wrapper>
      <Title>Statistika</Title>
      <Wraplist>
        <Card>
          <div className="icon">
            <IconFile />
          </div>
          <CardInfo>
            <h3>Yangi arizalar soni</h3>
            <h4>{statistic?.new_applications}</h4>
          </CardInfo>
        </Card>
        <Card>
          <div className="icon">
            <IconList />
          </div>
          <CardInfo>
            <h3>Ko’rib chiqilayatogan arizalar soni</h3>
            <h4>{statistic?.reviewing_applications}</h4>
          </CardInfo>
        </Card>
        <Card>
          <div className="icon">
            <IconRejected />
          </div>
          <CardInfo>
            <h3>Qaytarilgan arizalar soni</h3>
            <h4>{statistic?.declined_applications}</h4>
          </CardInfo>
        </Card>
        <Card>
          <div className="icon">
            <IconMoney />
          </div>
          <CardInfo>
            <h3>Ajratilgan qarz summasi</h3>
            <h4>{statistic?.total_allocated_amount}</h4>
          </CardInfo>
        </Card>
      </Wraplist>
    </Wrapper>
  );
};

export default Statistics;
