import React from "react";
import Navbar from "components/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "components/footer/container";

function RootLayout() {
  const location = useLocation();
  return (
    <>
      <Navbar />
      <Outlet />
      {location.pathname.includes("personal-cabinet") ? (
        <Footer isLanding={true} />
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );
}

export default RootLayout;
