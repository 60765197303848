import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${allColors.shriftColor};
  .user-name {
    color: ${allColors.mainColor};
  }
  @media (max-width: 1366px) {
    font-size: 26px;
  }
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
  }
`;
export const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${allColors.whiteColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 32px;
  gap: 24px;
  @media (max-width: 1366px) {
    padding: 38px 0 30px;
  }
`;
export const TabMenuContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 920px) {
    gap: 22px;
  }

  @media (max-width: 720px) {
    gap: 20px;
  }
  @media (max-width: 512px) {
    gap: 18px;
  }

  @media (max-width: 300px) {
    gap: 14px;
    /* overflow-x: scroll; */
  }
`;
export const TabMenu = styled.button`
  border: none;
  color: #0d1012;
  cursor: pointer;
  background: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  height: 38px;
  justify-content: space-between;
  padding: 0;
  font-feature-settings: "pnum" on, "lnum" on;
  &.active {
    font-weight: 700;
    color: ${allColors.mainColor};
    span {
      width: 100%;
      background-color: ${allColors.mainColor};
      border: 2px solid ${allColors.mainColor};
    }
  }

  @media (max-width: 1366px) {
    line-height: 26px;
    font-size: 18px;
  }
  @media (max-width: 512px) {
    line-height: 15px;
    font-size: 14px;
  }
  @media (max-width: 512px) {
    line-height: 34px;
    font-size: 12px;
  }
  @media (max-width: 300px) {
    line-height: 16px;
    font-size: 10px;
  }
`;
export const HR = styled.hr`
  /* color: #F2F4F5; */
  width: 100%;
  opacity: 0.3;
  margin-top: 0;
`;
