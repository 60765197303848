import React, { useState } from "react";
import {
  Input,
  InputContainer,
  Label,
  LabelMain,
  UploadConatiner,
} from "./style";
import { FiPaperclip } from "react-icons/fi";

function FileUploadForApply({
  label,
  setTechnicsFoto,
  nameId,
  disabled = false,
}) {
  const [changeFile, setChangeFile] = useState("");
  // const [fileId, setFileId] = useState([])
  async function onChange(e) {
    const files = e.target.files[0];
    setTechnicsFoto(files);
    setChangeFile("");
  }
  return (
    <InputContainer>
      <LabelMain>{label}</LabelMain>
      <UploadConatiner>
        <Label htmlFor={nameId}>
          <FiPaperclip /> File yuklash
        </Label>
        <Input
          id={nameId}
          placeholder="File yuklash"
          type={"file"}
          accept=".jpg, .pdf"
          onChange={onChange}
          value={changeFile}
          required={true}
          disabled={disabled}
        />
      </UploadConatiner>
    </InputContainer>
  );
}

export default FileUploadForApply;
