import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 64px;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 206px 40px;
  gap: 40px;
  border-radius: 16px;
  background-color: ${allColors.whiteColor};
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const H2 = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: ${allColors.shriftColor};
  margin: 0;
`
export const H4 = styled.h4`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
  margin-bottom: 26px;
  margin-top: 12px;
`
