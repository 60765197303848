import React from "react";
import { Cards, Container, ImageCard, Title, TitleCard } from "./style";
import OneId from "assets/images/auth/oneId.svg";
import OneIdImg from "assets/images/auth/one-id.png";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doGetAuth } from "services/services";
import ReactLoader from "components/react-loader/ReactLoader";
import { doGet } from "services/services";
import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";

function AuthV2() {
  const navigate = useNavigate();
  const location = useLocation();
  let object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const [urlId, setUrlId] = useState({
    error: false,
    loading: false,
    success: false,
    data: "",
  });
  useQuery(
    ["get_token"],
    () => doGet(`/auth/one-id-callback/?code=${params?.code}`),
    {
      onSuccess: (data) => {
        if (data) {
          let userInfo = JSON.stringify({
            accessToken: data?.access,
            refreshToken: data?.refresh,
          });
          localStorage.setItem("userInfo", userInfo);
          if (localStorage.getItem("isCabinetGarden") === "false") {
            navigate("/agro-leasing/user-info");
            // setAvalableBol(true);
          } else {
            navigate("/personal-cabinet");
            localStorage.setItem("isCabinetGarden", false);
          }
        }
      },
      onError: (error) => {
        if (error?.response?.status === 400) {
          navigate("/");
          notification["error"]({
            message: "Error",
            description: error?.response?.data.detail,
          });
        }
      },
      enabled: params.code ? true : false,
    }
  );

  const GetAuth = async () => {
    await doGetAuth("/auth/one-id-leasing/", setUrlId);
  };

  useEffect(() => {
    if (!params?.code) {
      GetAuth();
    }
  }, []);
  return (
    <Container>
      {params.code ? (
        <ReactLoader propWidth={100} colors={"#fff"} alignItem="center" />
      ) : (
        <>
          {urlId.loading && (
            <ReactLoader propWidth={100} colors={"#fff"} alignItem="center" />
          )}
          {urlId.error && (
            <h1 style={{ color: "#fff" }}>Xatolik yuz berdi!!!</h1>
          )}
          {urlId.success && (
            <Cards>
              <Title>Kirish</Title>
              <TitleCard>
                <button>
                  <img src={OneId} alt="one-id-icon" />
                </button>
              </TitleCard>
              <ImageCard>
                <a target={"_self"} href={urlId.data?.url}>
                  <img src={OneIdImg} alt="one-id-icon" />
                </a>
              </ImageCard>
            </Cards>
          )}
        </>
      )}
    </Container>
  );
}

export default AuthV2;
