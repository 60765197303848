import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { applicationApi } from "shared/api/pagesApi";

export const useApply = (props) => {
  const navigate = useNavigate();
  const project_id = localStorage.getItem("project_id");

  const { data: appData } = useQuery(["get-application-l"], () =>
    applicationApi.getApplication()
  );
  const request = useMutation(
    (data) =>
      project_id
        ? applicationApi.logisApplyUpdate(data, appData?.application_l)
        : applicationApi.applicationv1Create(data),
    {
      onSuccess: (res) => {
        console.log(res);
        notification["success"]({
          message: "Success",
          description: "Ariza muvaffaqiyatli yaratildi",
        });
        localStorage.removeItem("project_id");
        navigate("/");
      },
      onError: (error) => {
        notification["error"]({
          message: "Error",
          description: `${error?.detail}!`,
        });
      },
    }
  );
  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file_1", props.file1);
    formData.append("file_2", props.file2);
    formData.append("file_3", props.file3);
    formData.append("file_4", props.file4);
    formData.append("file_5", props.file5);
    formData.append("file_6", props.file6);
    formData.append("file_7", props.file7);
    formData.append("file_8", props.file8);
    formData.append("file_9", props.file9);
    formData.append("file_10", props.file10);
    formData.append("file_11", props.file11);
    formData.append("file_12", props.file12a);
    formData.append("file_13", props.file12b);
    formData.append("file_14", props.file12c);
    formData.append("file_15", props.file13a);
    formData.append("file_16", props.file13b);
    formData.append("file_17", props.file13c);
    formData.append("file_18", props.file14);
    formData.append("application_l", appData?.application_l_logistics);
    request.mutate(formData);
  };
  return {
    onSubmit,
    isLoading: request.isLoading,
  };
};
