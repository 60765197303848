import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 24px;
  margin: 40px 0 120px 0;
  .scrool {
    overflow-x: scroll;
    width: 100%;
  }

  & .antd-style {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    & .ant-pagination-item {
      border-radius: 50% !important;
      border: 1px solid #2b9763;
    }
    & .ant-pagination-item-active {
      background: #2b9763 !important;
      color: #fff !important;
      border: 1px solid #2b9763 !important;
      transition: none !important;
    }
    & .ant-pagination-item-link {
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: #2b9763;
      border-color: #2b9763;
    }
    & .ant-pagination-item:hover {
      border-color: #2b9763;
    }
  }
`;
export const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
`;
export const THead = styled.thead`
  tr {
    padding: 0px;
    width: 100%;
  }
  & th {
    white-space: nowrap;
    position: relative;
    text-align: start;
    /* max-width: 172px; */
    min-width: 150px;
    color: ${allColors.shriftColorOpasity3};
    padding: 8px 10px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  & th:first-child {
    min-width: auto !important;
    text-align: center;
  }
  /* & th:nth-child(4) {
    min-width: 230px;
  } */

  tr th:not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background: ${allColors.seriyColor};
    border: 1px solid ${allColors.seriyColor};
    transform: rotate(90deg);
    transform: translateY(-50%);
    content: "";
  }
  tr th:last-child:not([colspan]):before {
    content: none;
  }
`;
export const TableBody = styled.tbody`
  tr {
    padding: 0;
    width: 100%;
    border-radius: 4px;
    /* cursor: pointer; */
  }
  & td {
    position: relative;

    /* max-width: 172px; */
    min-width: 140px;
    color: ${allColors.shriftColor};
    padding: 8px 10px;
  }
  & td:first-child {
    min-width: auto !important;
    text-align: center;
  }
  tr td:not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background: ${allColors.seriyColor};
    border: 1px solid ${allColors.seriyColor};
    transform: rotate(90deg);
    transform: translateY(-50%);
    content: "";
  }
  tr td:last-child:not([colspan]):before {
    content: none;
  }

  & tr:nth-child(2n + 1) {
    background: ${allColors.selectBgColor};
  }
`;
