import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { applyApi } from "shared/api/pagesApi";

export const useCabinetHooks = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(1);
  const setPage = (e) => {
    navigate({
      search: `?${createSearchParams({
        page: e,
      })}`,
    });
  };
  const { data: getInfo } = useQuery(["get-leasing-personal-info"], () =>
    applyApi.getRequest("/personal-cabinet-l/info/")
  );
  const { data: getApplyList } = useQuery(
    ["get-leasing-personal-apply", location.search?.replace("?page=", "")],
    () =>
      applyApi.getRequest(
        "/personal-cabinet-l/apps-info/?page=" +
          (location.search?.replace("?page=", "") || 1)
      )
  );

  return {
    tabValue,
    setTabValue,
    getInfo,
    getApplyList,
    setPage,
  };
};
