import styled from "styled-components";
import bannerImg1 from "assets/images/landing/banner2.jpeg";
import { allColors } from "components/colors/AllColors";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  height: 480px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% 100%;
  padding: 0 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${bannerImg1});
  @media (max-width: 991px) {
    border-radius: 18px;
  }
  @media (max-width: 700px) {
    border-radius: 12px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 600;
  max-width: 848px;
  line-height: 64px;
  text-align: center;
  color: ${allColors.whiteColor};
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 35px;
  }
`;

export const Ul = styled.ul`
  gap: 8px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 32px;
  align-items: center;
  list-style-type: none;
  justify-content: center;
  li {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    align-items: center;
    color: ${allColors.whiteColor};
  }
  @media (max-width: 991px) {
    margin: 8px 0 24px;
    /* flex-direction: column; */
    li {
      font-size: 14px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ApplyLink = styled(Link)`
  height: 44px;
  display: flex;
  padding: 0 32px;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${allColors.whiteColor};
  color: ${allColors.whiteColor};
  background-color: rgba(255, 255, 255, 0.2);
  :hover {
    color: ${allColors.whiteColor};
    transform: scale(1.02);
  }
  @media (max-width: 991px) {
    height: 40px;
    padding: 0 24px;
    border-radius: 6px;
  }
`;

export const TestRejimTitle = styled.h3`
  background-color: white;
  color: red;
  width: 100%;
  text-align: center;
  font-size: 18px;
  @media (max-width: 991px) {
    font-size: 16px;
    /* line-height: 25px; */
  }
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;
