import {
  Cards,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import React from "react";
import { H1, HR, TabMenu, TabMenuContainer } from "./style";
import { useCabinetHooks } from "./useCabinetHooks";
import PersonalInfo from "./components/info/personalInfo";
import MyApply from "./components/apply/myApply";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import { useNavigate } from "react-router-dom";

function LeasingCabinet() {
  const navigate = useNavigate();
  const { tabValue, setTabValue, getInfo, getApplyList, setPage } =
    useCabinetHooks();
  return (
    <MainContainer>
      <MarginContainer>
        <Cards>
          <H1>
            Shaxsiy kabinet{" "}
            <Button
              Text={"Ariza berish"}
              TextColor={allColors.mainColor}
              backgroundColor={allColors.whiteColor}
              BorderColor={allColors.mainColor}
              OnClickButton={() => navigate("/agro-leasing/user-info")}
            />
          </H1>
          <TabMenuContainer>
            <TabMenu
              className={tabValue === 1 && "active"}
              onClick={() => setTabValue(1)}
            >
              Shaxsiy ma'lumotlar
              <span></span>
            </TabMenu>
            <TabMenu
              // disabled
              className={tabValue === 2 && "active"}
              onClick={() => setTabValue(2)}
            >
              Arizalar
              <span></span>
            </TabMenu>
          </TabMenuContainer>
          <HR />
          {tabValue === 1 ? (
            <PersonalInfo data={getInfo} />
          ) : tabValue === 2 ? (
            <MyApply data={getApplyList} setPage={setPage} />
          ) : null}
        </Cards>
      </MarginContainer>
    </MainContainer>
  );
}

export default LeasingCabinet;
