import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 24px 0;
  padding: 16px;
  border: 1px solid #dadada;
  border-radius: 12px;
`;
export const Title = styled.h4`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #313131;
`;
