import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const Cards = styled.div`
  padding: 18px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  background: ${allColors.whiteColor};
  box-sizing: border-box;
`
export const H3 = styled.h3`
  margin: 0;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${allColors.shriftColor};
`
export const FlexWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
export const WrapCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${allColors.shriftColor};
  }
`
