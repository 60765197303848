import React, { useState } from "react";
import MainTitle from "./mainTitle";
import PersonalCabinetComp from "../components/personal-cab";
import ApplysComponent from "../components/applys";

function MainCabinet() {
  const [tabValue, setTabValue] = useState(1);

  return (
    <>
      <MainTitle tabValue={tabValue} setTabValue={setTabValue} />
      {
      tabValue === 1 ? <PersonalCabinetComp/>: tabValue===2? <ApplysComponent/> : null
      }
    </>
  );
}

export default MainCabinet;
