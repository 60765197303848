import styled from "styled-components";

export const ApplyContent = styled("div")``;
export const List = styled("ul")`
  margin: 0;
  padding: 0;
`;
export const ListItem = styled("li")`
  list-style: none;
  width: 100%;
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  border-radius: 6px;
  &:nth-of-type(2n) {
    background-color: white;
  }
  span {
    font-family: "Raleway", sans-serif;
    &:nth-of-type(1) {
      color: #9b9b9b;
    }
    &:nth-of-type(2) {
      text-align: right;
    }
  }
  @media (max-width: 380px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`;
