import React from "react";
import { Input, InputContainer, Label } from "../inputStyle";

function InputController({
  label,
  placeholder,
  name,
  disabled,
  Controller,
  control,
  inputType,
  minLength = 1,
  required = false,
  className,
  defaultValue,
  max,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required, minLength: minLength, max: max }}
      defaultValue={defaultValue ?? null}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { errors },
      }) => (
        <InputContainer>
          <Label>{label}</Label>
          <Input
            id={name}
            placeholder={placeholder || "Shuyerga kiriting"}
            value={value || ""}
            disabled={disabled}
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            // checked={value}
            inputRef={ref}
            className={className}
            type={inputType || "text"}
            error={!!errors?.title}
            onWheel={(e) => e.target.blur()}
            // step={"0.001"}
          />
        </InputContainer>
      )}
    />
  );
}

export default InputController;
