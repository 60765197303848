import React from "react";
import { FileIconCard, FileNameCard, FotoInfoCard } from "./style";
// import { IoClose } from 'react-icons/io5'
import UploadFile from "./Download.svg";
import { AiFillFileText } from "react-icons/ai";
import { baseUrl } from "services/baseUrl";

function PhotoInfo({ file }) {
  return (
    <FotoInfoCard>
      <FileIconCard>
        <AiFillFileText className="img-style-file" />
        <FileNameCard>
          {/* <div>{file?.name}</div> */}
          <div>{`${
            file?.name?.length > 12
              ? file?.name?.slice(0, 6) + "..." + file?.name?.slice(-6)
              : file?.name
          }`}</div>
          <span>{Math.round(file?.size / 1000) + " KB"}</span>
        </FileNameCard>
      </FileIconCard>
      {/* <UploadFile className="close-icon" onClick={() => deleteFile(index)} /> */}
      <a href={baseUrl + "/" + file?.path} target="_blank" rel="noreferrer">
        <img src={UploadFile} alt="fileUpload" className="close-icon" />
      </a>
    </FotoInfoCard>
  );
}

export default PhotoInfo;
