import React from "react";
import { Container, Table, TableBody, THead } from "./style";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { allColors } from "components/colors/AllColors";
import moment from "moment";
import styled from "styled-components";
function ApplyTable({ data }) {
  const navigate = useNavigate();
  // console.log(data);
  // const onClickTable = (application_status, id) => {
  //   if (application_status === 0) {
  //     navigate(`${id}`);
  //   }
  // };
  return (
    <Container>
      <>
        <div className="scrool">
          <Table>
            <THead>
              <tr>
                <th>№</th>
                <th>Ariza raqami</th>
                <th>Ariza statusi</th>
                <th>Ariza sanasi</th>
                <th>Ariza summasi</th>
                <th>Qarz maqsadi</th>
              </tr>
            </THead>
            <TableBody>
              {!data?.length && (
                <tr>
                  <td colSpan={10}>Arizalar listi bo'sh</td>
                </tr>
              )}
              {data?.map((item, index) => (
                <tr
                  key={item.id}
                  // onClick={() => onClickTable(item.application_status, item.id)}
                  style={{
                    cursor:
                      item.application_status === 0 ? "pointer" : "default",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.application_num ?? "Ro'yxatga olinmagan"}</td>
                  <td>
                    {/* {item.application_status} */}
                    <Status application_status={item.application_status}>
                      {item.application_status_display}
                    </Status>
                  </td>
                  <td>{moment(item.created_at).format("DD.MM.YYYY")}</td>
                  <td>{item.debt}</td>
                  <td>{item.p_project_objective}</td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </div>
        <Pagination
          total={data?.length}
          // showSizeChanger
          // showQuickJumper
          showTotal={(total) => `Barchasi: ${total} `}
          className="antd-style"
        />
      </>
    </Container>
  );
}
export default ApplyTable;

const Status = styled.span`
  width: 100%;
  white-space: nowrap;
  border-radius: 4px;
  color: ${(props) =>
    props.application_status === 2
      ? allColors.mainColor
      : props.application_status === 0
      ? "#EB5757"
      : "#F2994A"};
  padding: 2px 8px;
  background: ${(props) =>
    props.application_status === 2
      ? "rgba(8, 112, 95, 0.2)"
      : props.application_status === 0
      ? "rgba(235, 87, 87, 0.2)"
      : "rgba(242, 153, 74, 0.2)"};
  /* background: rgba(242, 153, 74, 0.2);
border-radius: 4px; */
  /* #F2994A */
`;
