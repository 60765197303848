import React from 'react'
import {
  Input,
  InputAA,
  InputContainer,
  Label,
  PasportCard,
} from '../inputStyle'

function PassportComponent({
  control,
  Controller,
  nameA,
  nameB,
  disabled,
  label,
  required = false,
  minLength = 7,
  minLengthSeria=2,
  className,
  defaultValueA,
  defaultValueB
}) {
  return (
    <InputContainer>
      <Label>{label || 'Pasport seriyasi va raqami'}</Label>
      <PasportCard>
        <Controller
          control={control}
          name={nameA}
          rules={{ required: required, minLength: minLengthSeria }}
          defaultValue={defaultValueA ?? null}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <InputAA
              placeholder="AA"
              onChange={onChange}
              checked={value}
              value={value || ''}
              inputRef={ref}
              disabled={disabled}
              maxLength={2}
              minLength={2}
              className={className}
            />
          )}
        />
        <Controller
          control={control}
          name={nameB}
          rules={{ required: required, minLength: minLength }}
          defaultValue={defaultValueB ?? null}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Input
              id={name}
              placeholder={'1234567'}
              value={value || ''}
              disabled={disabled}
              onChange={onChange} // send value to hook form
              checked={value}
              inputRef={ref}
              // maxLength={'7'}
              // maxlength="10"
              // minLength={'7'}
              type={'number'}
              onWheel={(e) => e.target.blur()}
              className={className}
            />
          )}
        />
      </PasportCard>
    </InputContainer>
  )
}

export default PassportComponent
