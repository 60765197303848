import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;

  .ant-input {
    background-color: ${allColors.selectBgColor};
  }
`;
export const Label = styled.label`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${allColors.shriftColorOpasity3};
`;
export const Input = styled.input`
  background: ${allColors.selectBgColor};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
  border: none;
  outline-color: #3aa291;
  &:focus-visible {
    border: 1px solid #3aa291;
  }
`;
export const InputAA = styled.input`
  background: ${allColors.selectBgColor};
  border-radius: 8px;
  padding: 12px;
  width: 54px;
  height: 44px;
  border: none;
  text-align: center;
  text-transform: uppercase;
  outline-color: #3aa291;
  &:focus-visible {
    border: 1px solid #3aa291;
  }
`;
export const PasportCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;
