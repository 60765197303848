import { message, Steps as AntdSteps } from 'antd';
import { useState } from 'react';
import { Wrapper } from './style';
import { ReactComponent as ApplySubmitIcon } from 'icons/apply.submit.svg';
import { ReactComponent as ProjectOfficeIcon } from 'icons/project-office.svg';
import { ProjectOffice } from '../project-office';
import { AgroserviceOperator } from '../agroservice-operator';
import { InterCenter } from '../international-center';

export const Steps = () => {
  const [current, setCurrent] = useState(1);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: 'Ariza topshirish',
      content: 'First content',
      icon: <ApplySubmitIcon />,
      status: 'success',
    },
    {
      title: 'Hududiy loyiha ofisi',
      content: (
        <ProjectOffice
          current={current}
          prev={prev}
          next={next}
          message={message}
        />
      ),
      icon: <ProjectOfficeIcon />,
    },
    {
      title: '“Agro servis operator” DUK',
      content: (
        <AgroserviceOperator
          current={current}
          prev={prev}
          next={next}
          message={message}
        />
      ),
      icon: <ProjectOfficeIcon />,
    },
    {
      title: 'Xalqaro markaz',
      content: (
        <InterCenter
          current={current}
          prev={prev}
          next={next}
          message={message}
        />
      ),
      icon: <ProjectOfficeIcon />,
    },
    {
      title: 'Arizangiz tasdiqlandi',
      content: 'Last-content',
      icon: <ProjectOfficeIcon />,
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
    status: item.status,
  }));

  return (
    <Wrapper>
      <AntdSteps current={current} labelPlacement="vertical" items={items} />
      <div>{steps[current].content}</div>
    </Wrapper>
  );
};
