import React from "react";
import { Container, Table, TableBody, THead } from "./style";
import { Pagination } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { allColors } from "components/colors/AllColors";
import moment from "moment";
import styled from "styled-components";
function MyApply({ data, setPage }) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(data);
  const onClickTable = (application_status, id) => {
    if (application_status === 0) {
      navigate(`${id}`);
    }
  };

  return (
    <Container>
      <>
        <div className="scrool">
          <Table>
            <THead>
              <tr>
                <th>№</th>
                <th>Ariza raqami</th>
                <th>Ariza turi</th>
                <th>Ariza sanasi</th>
                <th>Telefon</th>
                <th>Email</th>
                <th>Ariza holati</th>
              </tr>
            </THead>
            <TableBody>
              {!data?.results?.length && (
                <tr>
                  <td colSpan={10}>Arizalar listi bo'sh</td>
                </tr>
              )}
              {data?.results?.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    {(parseInt(location.search?.replace("?page=", "") || "1") -
                      1) *
                      20 +
                      index +
                      1}
                  </td>
                  <td>{item.id}</td>
                  <td>{item.debt_type_display}</td>
                  <td>{moment(item.created_at).format("DD.MM.YYYY")}</td>
                  <td>{item.phone_number}</td>
                  <td>{item.email}</td>
                  <td>
                    <Status status={item.status}>{item.status_display}</Status>
                  </td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </div>
        <Pagination
          total={data?.count || 1}
          current={parseInt(location.search?.replace("?page=", "")) || 1}
          defaultCurrent={1}
          // showSizeChanger
          // showQuickJumper
          onChange={setPage}
          pageSize={20}
          showTotal={(total) => `Barchasi: ${total ?? 1} `}
          className="antd-style"
        />
      </>
    </Container>
  );
}
export default MyApply;

const Status = styled.span`
  width: 100%;
  white-space: nowrap;
  border-radius: 4px;
  color: ${(props) =>
    props.status === 4
      ? allColors.mainColor
      : props.status === 0
      ? "#EB5757"
      : props.status === 1
      ? "#228be6;"
      : "#F2994A"};
  padding: 2px 8px;
  background: ${(props) =>
    props.status === 2
      ? "rgba(8, 112, 95, 0.2)"
      : props.status === 0
      ? "rgba(235, 87, 87, 0.2)"
      : props.status === 1
      ? "rgba(34, 239, 230, 0.2)"
      : "rgba(242, 153, 74, 0.2)"};
  /* background: rgba(242, 153, 74, 0.2);
border-radius: 4px; */
  /* #F2994A */
`;
