import { Col, Row } from "antd";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import InputController from "components/input/inputController";
import RadioComponent from "components/input/radioComponent";
// import RadioController from "components/input/radioComponent/radioController";
import { SelectController } from "components/select/selectController";
import React, { Fragment} from "react";
import { useFieldArray } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { Cards, Hr, TitleH4 } from "../style";

function BRegion({ control, Controller, errors, options,setNeighbourSale,neighbourSale }) {
  // const [neighbourSale, setNeighbourSale] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "neighbour_marketing",
  });
  function addRegionInfo() {
    append({
      n_stir: null,
      n_company_name: null,
      n_product_name: null,
      n_production_volume: null,
    });
  }
  return (
    <Cards className="m-0">
      <TitleH4 className="m-0">B. Qo’shni viloyatlarda:</TitleH4>
      <RadioComponent
        label={"Sotuv bormi?"}
        name={"sales_within_neighbour"}
        setRadioValue={setNeighbourSale}
        value={neighbourSale}
      />
       {/* <RadioController label={"Sotuv bormi?"} name={"sales_within_neighbour"} 
      Controller={Controller}
      control={control}
      /> */}
      {neighbourSale && (
        <Row gutter={[16]} className="w-100">
          {/* salom */}
          {fields?.map((item, index) => {
            return (
              <Fragment key={item?.id}>
                {index > 0 ? (
                  <Col span={24} xl={24}>
                    <Hr />
                  </Col>
                ) : null}
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`neighbour_marketing[${index}].n_stir`}
                    label={`${index + 1}. STIR`}
                    defaultValue={item?.n_stir}
                    inputType={"text"}
                    required
                    className={
                      errors &&
                      errors?.neighbour_marketing?.[index]?.n_stir &&
                      "input-error"
                    }
                  />
                  {errors && errors?.neighbour_marketing?.[index]?.n_stir && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`neighbour_marketing[${index}].n_company_name`}
                    label={"Korxona nomi"}
                    disabled
                    defaultValue={item?.n_company_name}
                    required
                    className={
                      errors &&
                      errors?.neighbour_marketing?.[index].n_company_name &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.neighbour_marketing?.[index].n_company_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <SelectController
                    control={control}
                    Controller={Controller}
                    name={`neighbour_marketing[${index}].n_product_name`}
                    label={"Maxsulot nomi"}
                    // defaultValue={item?.n_product_name}
                    required
                    placeholder="Tanlang"
                    options={options}
                    className={
                      errors &&
                      errors?.neighbour_marketing?.[index]?.n_product_name &&
                      "input-error"
                    }
                  />
                  {/* <InputController
                  control={control}
                  Controller={Controller}
                  name={`neighbour_marketing[${index}].n_product_name`}
                  label={'Maxsulot nomi'}
                  defaultValue={item?.n_product_name}
                  required
                  className={
                    errors &&
                    errors?.neighbour_marketing?.[index]?.n_product_name &&
                    'input-error'
                  }
                /> */}
                  {errors &&
                    errors?.neighbour_marketing?.[index]?.n_product_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`neighbour_marketing[${index}].n_production_volume`}
                    label={"Ishlab chiqarish xajmi,(tn)"}
                    defaultValue={item?.n_production_volume}
                    required
                    inputType={'number'}
                    className={
                      errors &&
                      errors?.neighbour_marketing?.[index]
                        .n_production_volume &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.neighbour_marketing?.[index]
                      .n_production_volume && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col
                  span={24}
                  xl={8}
                  lg={12}
                  sm={12}
                  style={{ alignSelf: "end", marginTop: "16px" }}
                >
                  {index > 0 ? (
                    <Button
                      Icon={
                        <AiOutlineDelete
                          style={{ fontSize: "20px" }}
                          color={allColors.shriftRed}
                        />
                      }
                      TextColor={allColors.shriftRed}
                      backgroundColor={"rgba(235, 87, 87, 0.1)"}
                      Text="O'chirish"
                      Width="120px"
                      OnClickButton={() => remove(index)}
                    />
                  ) : (
                    <Button
                      Icon={<BsPlus style={{ fontSize: "20px" }} />}
                      TextColor={allColors.mainColor}
                      backgroundColor={"#e6f1ef"}
                      Text="Qo’shish"
                      Width="120px"
                      OnClickButton={addRegionInfo}
                    />
                  )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
      )}
    </Cards>
  );
}

export default BRegion;
