import React from "react";
import { Cards, FlexWrap, H3, WrapCard } from "./style";
import { AiFillCheckCircle } from "react-icons/ai";
import { allColors } from "components/colors/AllColors";
import useAccordionStore from "../../../../../zustand/accordionData";

function SuccessCollapse() {
  // const getStep = localStorage.getItem('userStep')
  const accordionData = useAccordionStore((state) => state.accordionData);
  // console.log(accordionData);
  return (
    <Cards>
      <H3>Kerakli ma’lumotlar</H3>
      <FlexWrap>
        <WrapCard>
          <AiFillCheckCircle
            fontSize={24}
            color={
              Number(accordionData?.step) === 2
                ? "#F3CC5C"
                : Number(accordionData?.step) > 2
                ? allColors.mainColor
                : "#DCDCDC"
            }
          />
          <span>Faoliyat</span>
        </WrapCard>
        <WrapCard>
          <AiFillCheckCircle
            fontSize={24}
            color={
              Number(accordionData?.step) === 3
                ? "#F3CC5C"
                : Number(accordionData?.step) > 3
                ? allColors.mainColor
                : "#DCDCDC"
            }
          />
          <span>Loyiha</span>
        </WrapCard>
        <WrapCard>
          <AiFillCheckCircle
            fontSize={24}
            color={
              Number(accordionData?.step) === 4
                ? "#F3CC5C"
                : Number(accordionData?.step) > 4
                ? allColors.mainColor
                : "#DCDCDC"
            }
          />
          <span>Texnalogiyalar</span>
        </WrapCard>
        <WrapCard>
          <AiFillCheckCircle
            fontSize={24}
            color={
              Number(accordionData?.step) === 5
                ? "#F3CC5C"
                : Number(accordionData?.step) > 5
                ? allColors.mainColor
                : "#DCDCDC"
            }
          />
          <span>Marketing</span>
        </WrapCard>
        <WrapCard>
          <AiFillCheckCircle
            fontSize={24}
            color={
              Number(accordionData?.step) === 6
                ? "#F3CC5C"
                : Number(accordionData?.step) > 6
                ? allColors.mainColor
                : "#DCDCDC"
            }
          />
          <span>Moliyaviy reja</span>
        </WrapCard>
      </FlexWrap>
    </Cards>
  );
}

export default SuccessCollapse;
