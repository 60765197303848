import styled from 'styled-components';
import { allColors } from 'components/colors/AllColors';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  form table thead th {
    white-space: normal;
    text-align: center;
  }
`;

export const ProductionSalesContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
`;

export const TitleH4 = styled.h3`
  margin: 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  //   margin-bottom: 12px;
  margin-top: 40px;
`;

export const SecondaryTitle = styled.h4`
  margin: 0;
  font-family: 'Raleway';
  font-style: normal;
  color: ${allColors.shriftColorOpasity3};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const EmptyProvider = styled.div`
  text-align: center;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
