import React, { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { PlanContainer, Wrapper } from "./style";
import ButtonComponent from "../buttonComponent";
import { TitleH4 } from "../style";
import Table1 from "./Table1";
import Table2 from "./Table2";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutation } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTotal } from "./useTotal";

function FinancePlans() {
  const navigate = useNavigate();
  const editStatus = useAccordionStore((state) => state.editStatus);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);

  const oneDay = useWatch({
    control,
    name: ["m_1_day", "m_2_day"],
  });
  // "m_4_day", "m_5_day", "m_6_day"
  const grossProfit = useWatch({
    control,
    name: ["m_3_day", "m_3_month", "m_3_year"],
  });
  const taxPayments = useWatch({
    control,
    name: ["m_4_day", "m_4_month", "m_4_year"],
  });
  const loanPayments = useWatch({
    control,
    name: ["m_5_day", "m_5_month", "m_5_year"],
  });
  const exportCosts = useWatch({
    control,
    name: ["m_6_day", "m_6_month", "m_6_year"],
  });

  const oneMonthX = useWatch({
    control,
    name: ["x_12_month"],
  });
  const productCost = useWatch({
    control,
    name: ["x_3_month", "x_3_quarter", "x_3_year"],
  });
  const salaryFund = useWatch({
    control,
    name: ["x_4_month", "x_4_quarter", "x_4_year"],
  });
  const yaitSalary = useWatch({
    control,
    name: ["x_5_month", "x_5_quarter", "x_5_year"],
  });
  const utilityCosts = useWatch({
    control,
    name: ["x_6_month", "x_6_quarter", "x_6_year"],
  });
  const transportationCosts = useWatch({
    control,
    name: ["x_7_month", "x_7_quarter", "x_7_year"],
  });
  const unforeseenExpenses = useWatch({
    control,
    name: ["x_8_month", "x_8_quarter", "x_8_year"],
  });
  const loanPercentage = useWatch({
    control,
    name: ["x_10_month", "x_10_quarter", "x_10_year"],
  });

  const serviceRevenue = useWatch({
    control,
    name: ["x_2_month", "x_2_quarter", "x_2_year"],
  });
  const soliqTulovi11 = useWatch({
    control,
    name: ["x_12_month", "x_12_quarter", "x_12_year"],
  });
  const x10Month = useWatch({
    control,
    name: "x_10_month",
  });

  const planProps = {
    productCost: productCost,
    salaryFund: salaryFund,
    yaitSalary: yaitSalary,
    utilityCosts: utilityCosts,
    transportationCosts: transportationCosts,
    unforeseenExpenses: unforeseenExpenses,
    loanPercentage: loanPercentage,
  };
  const planProps1 = {
    taxPayments: taxPayments,
    loanPayments: loanPayments,
    exportCosts: exportCosts,
  };

  const { getTotal: getTotal1 } = useTotal({ planProps });
  const { getTotal: getTotal_1 } = useTotal({ planProps: planProps1 });
  const getTotalValue = getTotal1();
  const getTotalValue_1 = getTotal_1();
  // console.log(getTotalValue_1);
  // avtomatik xisoblash ancha o'zgargan qayta ko'rib chiqish kerak!!! m_4_day,m_5_day
  useEffect(() => {
    setValue("x_11_month", getTotalValue[0]);
    setValue("x_11_quarter", getTotalValue[1]);
    setValue("x_11_year", getTotalValue[2]);
  }, [getTotalValue]);
  useEffect(() => {
    setValue("m_7_day", grossProfit[0] - getTotalValue_1[0]);
    setValue("m_7_month", grossProfit[1] - getTotalValue_1[1]);
    setValue("m_7_year", grossProfit[2] - getTotalValue_1[2]);
  }, [getTotalValue_1, grossProfit]);
  useEffect(() => {
    oneMonthX.forEach((v, i) => {
      if (!isNaN(v)) {
        setValue(`x_${12 + i}_quarter`, Number(v) * 3);
        setValue(`x_${12 + i}_year`, Number(v) * 12);
      }
    });
  }, [oneMonthX]);
  useEffect(() => {
    setValue(`m_6_month`, Number(watch("m_6_day")) * 30);
    setValue(`m_6_year`, Number(watch("m_6_day")) * 30 * 12);
  }, [watch("m_6_day")]);
  useEffect(() => {
    setValue(
      "x_16_year",
      serviceRevenue[2] - getTotalValue[2] - soliqTulovi11[2]
    );
    setValue(
      "x_16_quarter",
      serviceRevenue[1] - getTotalValue[1] - soliqTulovi11[1]
    );
    setValue(
      "x_16_month",
      serviceRevenue[0] - getTotalValue[0] - soliqTulovi11[0]
    );
  }, [serviceRevenue, getTotalValue, soliqTulovi11]);
  useEffect(() => {
    setValue(`x_10_quarter`, watch("x_10_month") * 3);
    setValue(`x_10_year`, watch("x_10_month") * 12);
  }, [x10Month]);
  useEffect(() => {
    oneDay?.forEach((v, i) => {
      if (!isNaN(v)) {
        let m_s = Number(v) * 30;
        setValue(`m_${i + 1}_month`, Number(v) * 30);
        setValue(`m_${i + 1}_year`, m_s * 12);
      } else {
        setValue(`m_${i + 1}_month`, 0);
        setValue(`m_${i + 1}_year`, 0);
      }
    });
    oneDay.reduce((p, n, i) => {
      // console.log(i);
      if (i === 1) {
        setValue(`m_3_day`, Number(p) - Number(n));
        setValue(`m_3_month`, (Number(p) - Number(n)) * 30);
        setValue(`m_3_year`, (Number(p) - Number(n)) * 365);
      }
      return 0;
    });
  }, [oneDay]);

  const { mutate } = useMutation((data) => applyApi.sendApply(data), {
    onSuccess: () => {
      notification["success"]({
        message: "Success",
        description: "5-qadam yakunlandi!!!",
      });
      setIsDisabled(false);
      navigate("/apply-success");
    },
    onError: () => {
      notification["error"]({
        message: "Error",
        description: "Xatolik yuz berdi!",
      });
      setIsDisabled(false);
    },
  });

  function NextStep(data) {
    const formData = new FormData();
    formData.append("process_step", 6);
    formData.append(
      "revenue_product_sales_first_1",
      JSON.stringify({
        m_1_day: data.m_1_day,
        m_1_month: data.m_1_month,
        m_1_year: data.m_1_year,
      })
    );
    formData.append(
      "product_cost_first_2",
      JSON.stringify({
        m_2_day: data.m_2_day,
        m_2_month: data.m_2_month,
        m_2_year: data.m_2_year,
      })
    );
    formData.append(
      "gross_profit_first_3",
      JSON.stringify({
        m_3_day: data.m_3_day,
        m_3_month: data.m_3_month,
        m_3_year: data.m_3_year,
      })
    );
    formData.append(
      "taxes_first_4",
      JSON.stringify({
        m_4_day: "",
        m_4_month: data.m_4_month,
        m_4_year: data.m_4_year,
      })
    );
    formData.append(
      "credit_payments_first_5",
      JSON.stringify({
        m_5_day: "",
        m_5_month: data.m_5_month,
        m_5_year: data.m_5_year,
      })
    );
    formData.append(
      "costs_related_export_first_6",
      JSON.stringify({
        m_6_day: data.m_6_day,
        m_6_month: data.m_6_month,
        m_6_year: data.m_6_year,
      })
    );
    formData.append(
      "net_profit_first_7",
      JSON.stringify({
        m_7_day: data.m_7_day,
        m_7_month: data.m_7_month,
        m_7_year: data.m_7_year,
      })
    );
    formData.append(
      "num_of_employees_second_1",
      JSON.stringify({
        x_1_month: data.x_1_month,
        x_1_quarter: data.x_1_quarter,
        x_1_year: data.x_1_year,
      })
    );
    formData.append(
      "sales_service_revenue_second_2",
      JSON.stringify({
        x_2_month: data.x_2_month,
        x_2_quarter: data.x_2_quarter,
        x_2_year: data.x_2_year,
      })
    );
    formData.append(
      "product_purchase_cost_second_3",
      JSON.stringify({
        x_3_month: data.x_3_month,
        x_3_quarter: data.x_3_quarter,
        x_3_year: data.x_3_year,
      })
    );
    formData.append(
      "labor_fund_second_4",
      JSON.stringify({
        x_4_month: data.x_4_month,
        x_4_quarter: data.x_4_quarter,
        x_4_year: data.x_4_year,
      })
    );
    formData.append(
      "YAIT_12_percent_second_5",
      JSON.stringify({
        x_5_month: data.x_5_month,
        x_5_quarter: data.x_5_quarter,
        x_5_year: data.x_5_year,
      })
    );
    formData.append(
      "utility_costs_second_6",
      JSON.stringify({
        x_6_month: data.x_6_month,
        x_6_quarter: data.x_6_quarter,
        x_6_year: data.x_6_year,
      })
    );
    formData.append(
      "transportation_costs_second_7",
      JSON.stringify({
        x_7_month: data.x_7_month,
        x_7_quarter: data.x_7_quarter,
        x_7_year: data.x_7_year,
      })
    );
    formData.append(
      "unexpected_costs_second_8",
      JSON.stringify({
        x_8_month: data.x_8_month,
        x_8_quarter: data.x_8_quarter,
        x_8_year: data.x_8_year,
      })
    );
    // formData.append(
    //   'problems_costs_second_9',
    //   JSON.stringify({
    //     x_9_month: data.x_9_month,
    //     x_9_quarter: data.x_9_quarter,
    //     x_9_year: data.x_9_year,
    //   }),
    // )
    formData.append(
      "credit_14_second_10",
      JSON.stringify({
        x_10_month: data.x_10_month,
        x_10_quarter: data.x_10_quarter,
        x_10_year: data.x_10_year,
      })
    );
    formData.append(
      "total_costs_second_11",
      JSON.stringify({
        x_11_month: data.x_11_month,
        x_11_quarter: data.x_11_quarter,
        x_11_year: data.x_11_year,
      })
    );
    formData.append(
      "turnover_taxes_4_percent_second_12",
      JSON.stringify({
        x_12_month: data.x_12_month,
        x_12_quarter: data.x_12_quarter,
        x_12_year: data.x_12_year,
      })
    );
    formData.append(
      "livestock_tax_second_13",
      JSON.stringify({
        x_13_month: 0,
        x_13_quarter: 0,
        x_13_year: 0,
      })
    );
    formData.append(
      "land_tax_second_14",
      JSON.stringify({
        x_14_month: 0,
        x_14_quarter: 0,
        x_14_year: 0,
      })
    );
    formData.append(
      "main_loan_debt_second_15",
      JSON.stringify({
        x_15_month: 0,
        x_15_quarter: 0,
        x_15_year: 0,
      })
    );
    formData.append(
      "net_profit_second_16",
      JSON.stringify({
        x_16_month: data.x_16_month,
        x_16_quarter: data.x_16_quarter,
        x_16_year: data.x_16_year,
      })
    );
    formData.append("fp_marketing", localStorage.getItem("app_id"));
    setIsDisabled(true);
    console.log(data, "data");
    mutate(formData);
  }

  function goBack() {
    editStatus({
      step: 5,
    });
    localStorage.setItem("userStep", 5);
  }

  return (
    <Wrapper>
      <PlanContainer>
        <form onSubmit={handleSubmit(NextStep)} id={"finans"}>
          <TitleH4>1. Moliyaviy shartnomalar xisobi:</TitleH4>
          <Table1 Controller={Controller} control={control} />
          <TitleH4>2. Xarajatlar xisob:</TitleH4>
          <Table2 Controller={Controller} control={control} />
        </form>
      </PlanContainer>
      <ButtonComponent form={"finans"} goBack={goBack} disabled={isDisabled} />
    </Wrapper>
  );
}

export default FinancePlans;
