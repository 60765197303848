import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

// ======Obshiy style start=======
export const MainContainer = styled.div`
  padding: 0 156px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 1400px) {
    max-width: 1100px;
    padding: 0;
  }
  @media (max-width: 1366px) {
    padding: 0 148px;
  }
  @media (max-width: 991px) {
    padding: 0 50px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`
export const H1 = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${allColors.shriftColor};
  @media (max-width: 1366px) {
    font-size: 26px;
  }
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 35px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`
export const H3 = styled.h3`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: ${allColors.shriftColor};
  @media (max-width: 1400px) {
    font-size: 18px;
  }
`
export const MarginContainer = styled.div`
  margin: 40px 0 120px 0;
  box-sizing: border-box;
`
export const Cards = styled.div`
  padding: 24px;
  border-radius: 16px;
  background: ${allColors.whiteColor};
  box-sizing: border-box;
`
// ======Obshiy style end=======

// ==========Title Style Start=============
export const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${allColors.whiteColor};
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
// ==========Title Style End=============
