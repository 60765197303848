import React from 'react'
import { Input, InputContainer, Label } from '../inputStyle'

function InputComponent({
  label,
  placeholder,
  value,
  onChange,
  name,
  className,
  disabled,
  inputType,
  required = false,
  maxLength
}) {
  return (
    <InputContainer>
      <Label htmlFor={name}>{label}</Label>
      <Input
        className={className || ''}
        id={name}
        name="name"
        placeholder={placeholder}
        onChange={onChange || null}
        value={value || ''}
        disabled={disabled}
        type={inputType || 'text'}
        // step="0.001"
        onWheel={(e) => e.target.blur()}
        required={required}
        maxLength={maxLength || null}
      />
    </InputContainer>
  )
}

export default InputComponent
