import React, { Fragment } from "react";
import Input from "../financePlan/Input";
import { Table, TableBody, THead } from "../financePlan/style";
import { EmptyProvider } from "./style";

function Table2({ Controller, control, tableList, getCalcAmount, product }) {
  return (
    <Fragment>
      <Table>
        <THead>
          <tr>
            <th>№</th>
            <th>Maxsulot turi</th>
            <th>O’lchov birligi</th>
            <th>Miqdori</th>
            <th>Narxi (so’m)</th>
            <th>Maxsulot summasi </th>
          </tr>
        </THead>
        <TableBody>
          {tableList?.map((item, index) => {
            return (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                <td>{item?.ps_product_name_2?.label}</td>
                <td>Tn</td>
                <td>
                  <Input
                    required
                    Controller={Controller}
                    control={control}
                    name={`sales[${index}].ps_amount_2`}
                    defaultValue={item?.ps_amount_2}
                  />
                </td>
                <td>
                  <Input
                    required
                    Controller={Controller}
                    control={control}
                    name={`sales[${index}].ps_price_2`}
                    defaultValue={item?.ps_price_2}
                  />
                </td>
                <td>{getCalcAmount(index)}</td>
              </tr>
            );
          })}

          <tr>
            <td></td>
            <td>Jami</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{product}</td>
          </tr>
        </TableBody>
      </Table>
      {tableList?.length === 0 && (
        <EmptyProvider>
          <p>Ma'lumot mavjud emas</p>
        </EmptyProvider>
      )}
    </Fragment>
  );
}

export default Table2;
