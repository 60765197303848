import styled from "styled-components";

export const ApplyTypeContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: start;
  flex-direction: column;
  width: 100%;
  /* height: 30vh; */
`;
