import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
export const LabelMain = styled.label`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
  margin-top: 16px;
`;

export const RadioBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
`;
export const RadioBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  /* input[type='radio']:checked::after {
    background-color: red;
  } */
`;
export const RadioBoxInput = styled.input`
  accent-color: ${allColors.mainColor};
  font-size: 20px;
  transform: scale(1.4);
`;
export const InputLabel = styled.label`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${allColors.shriftColor};
`;
