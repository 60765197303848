import {
  H1,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import React from "react";
import { CollapseContainer, ContainerAct, TitleCard } from "./style";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import { Col, Row } from "antd";
import { AccordionComponent } from "../components/accordion";
import MatnComponent from "../components/matn";
import ProjectClassification from "../components/project-classification/container";

function LeasingMainApply() {
  return (
    <MainContainer>
      <MarginContainer>
        <TitleCard>
          <H1>Agrolizing uchun online ariza berish </H1>
          <Button
            Text={"Bekor qilish"}
            backgroundColor={"white"}
            TextColor={allColors.shriftRed}
            BorderColor={allColors.shriftRed}
            Width={"140px"}
            // OnClickButton={() => navigate(-1)}
          />
        </TitleCard>
        <ContainerAct>
          <Row gutter={[16, 16]} className={"teskari"}>
            <Col span={24} sm={24} lg={16} xl={18}>
              <CollapseContainer>
                <AccordionComponent title={"Ariza"} factorSOpen={true}>
                  <MatnComponent />
                </AccordionComponent>
                <AccordionComponent
                  title={"Loyiha tasnifi"}
                  factorSOpen={false}
                >
                  <ProjectClassification />
                </AccordionComponent>
              </CollapseContainer>
            </Col>
            
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
}

export default LeasingMainApply;
