import {} from '../constants';
import Api from '../index';

class Application extends Api {
  getApplication() {
    return this.execute('get', 'app-l/get-application-id/');
  }
  applicationv2Create(data) {
    return this.execute('post', 'app-l/garden-files-create/', data);
  }
  applicationv1Create(data) {
    return this.execute('post', 'app-l/logistic-files-create/', data);
  }
  gardenApplyUpdate(data, app_l) {
    return this.execute('patch', `app-l/garden-files-update/${app_l}/`, data);
  }
  logisApplyUpdate(data, app_l) {
    return this.execute('patch', `app-l/logistic-files-update/${app_l}/`, data);
  }
}

export default Application;
