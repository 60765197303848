import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Card = styled.div`
  background-color: ${allColors.whiteColor};
  padding: ${(props) => (props.Border ? "16px" : "24px")};
  border-radius: 16px;
  border: ${(props) => (props.Border ? "1px solid #eaeaea" : "none")};
  & .table-rows > td {
    background-color: ${allColors.selectBgColor};
  }

  .table-container {
    display: flex;
    transition: display 0.4s ease-in;
  }

  .table-collapse {
    display: none;
  }
`;

export const CardTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .arrow-open svg {
    width: 28px;
    height: 28px;
    color: #aaabad;
    transition: 0.5s ease-in-out;
    rotate: 180deg;
  }

  .arrow-close svg {
    width: 28px;
    height: 28px;
    color: #aaabad;
    transition: 0.5s ease-in-out;
    rotate: 0deg;
  }
`;

export const CardTitle = styled.h3`
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: ${(props) => (props.Border ? 600 : 700)};
  line-height: 24px;
  color: ${allColors.shriftColor};
  margin: 0;
  padding: 0;
`;

export const Table = styled.table`
  min-width: 100%;
  display: table;
  border-collapse: collapse;
  transition: display 0.5s ease-out;
  margin-top: 24px;
  box-sizing: border-box;
`;

export const TableHeader = styled.thead`
  background-color: ${allColors.whiteColor};
  box-sizing: border-box;
  white-space: nowrap;
  & th {
    background-color: ${allColors.whiteColor};
    padding: 8px;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #60676d;
    border-right: 1px solid ${allColors.seriyColor};
  }
  tr th:first-child {
    width: 50px;
  }
`;

export const TableBody = styled.tbody`
  white-space: nowrap;
  box-sizing: border-box;
  //   & tr:nth-child(odd) {
  //     background-color: #f5f5f5;
  //   }
  //   & tr:nth-child(even) {
  //     background-color: #fff;
  //   }
  & td {
    min-width: 120px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    color: ${allColors.shriftColor};
    text-align: center;
    white-space: normal;
  }

  .number-id-width {
    min-width: auto !important;
  }
`;
