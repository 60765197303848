import { Col, Row } from 'antd';
import {
  Cards,
  H3,
  MainContainer,
  MarginContainer,
} from 'components/styles/styleComponetns';
import { ContainerAct } from 'pages/agroleasing/collapseForApply/container/style';
import React from 'react';
import { Header } from './style';
import { Steps } from '../components/steps';

const ApplyStatus = () => {
  return (
    <MainContainer>
      <MarginContainer>
        <ContainerAct>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xl={24}>
              <form>
                <Cards>
                  <Header>
                    <div className="apply-status-header">
                      <H3>Arizaning holati</H3>
                      <p>
                        <span>Ariza yuborilgan vaqti:</span> 20.06.2023
                      </p>
                    </div>
                    <Steps />
                  </Header>
                </Cards>
              </form>
            </Col>
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
};

export default ApplyStatus;
