import React, { useState } from "react";
import { Container, LogoCards, Wrapper } from "./style";
import { applyApi } from "shared/api/pagesApi";
import { useQuery } from "@tanstack/react-query";
import useAccordionStore from "../../../../zustand/accordionData";
import { useNavigate } from "react-router-dom";

function ChooseDirection() {
  const editStatus = useAccordionStore((state) => state.editStatus);
  const navigate = useNavigate();
  const [avalableBol, setAvalableBol] = useState(false);

  useQuery(
    ["get_stepInfo"],
    async () => await applyApi.getRequest("/app/available/"),
    {
      onSuccess: (res) => {
        if (res) {
          if (res.process_step > 1) {
            editStatus({
              step: res.process_step,
              debt_type: res.debt_type,
            });
            localStorage.setItem("app_id", res?.id);
            navigate("/ms/business-plan");
          } else {
            editStatus({
              step: 2,
            });
            navigate("/ms/ariza");
          }
          let userStep = JSON.stringify(res?.process_step);
          localStorage.setItem("userStep", userStep);
          localStorage.setItem("dep_type", res?.debt_type);
        }
      },
      onError: (err) => {
        if (err?.status_code === 401) {
          navigate("/");
        }
      },
      enabled: avalableBol ? true : false,
    }
  );
  return (
    <Wrapper>
      <Container>
        <LogoCards onClick={() => setAvalableBol(true)}>
          Agrokredit-uz
        </LogoCards>
        <LogoCards onClick={() => navigate("/agro-leasing/choose-apply-type")}>
          Agroleasing-uz
        </LogoCards>
      </Container>
    </Wrapper>
  );
}

export default ChooseDirection;
