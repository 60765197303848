import React from "react";
import { InputContainer, Label } from "../inputStyle";
import {
  InputLabel,
  RadioBoxCard,
  RadioBoxContainer,
  RadioBoxInput,
} from "./style";

function RadioComponent({ label, name, setRadioValue, value }) {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <RadioBoxContainer>
        <RadioBoxCard>
          <RadioBoxInput
            name={name}
            id={`${name + 1}`}
            type={"radio"}
            onChange={() => setRadioValue(true)}
            // defaultChecked={value === true}
            checked={value}
          />
          <InputLabel htmlFor={`${name + 1}`}>Ha</InputLabel>
        </RadioBoxCard>
        <RadioBoxCard>
          <RadioBoxInput
            name={name}
            id={`${name + 2}`}
            type={"radio"}
            onChange={() => setRadioValue(false)}
            // onChange={() => console.log("false")}
            value={!value}
            // defaultChecked={true}
          />
          <InputLabel htmlFor={`${name + 2}`}>Yo'q</InputLabel>
        </RadioBoxCard>
      </RadioBoxContainer>
    </InputContainer>
  );
}

export default RadioComponent;
