import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { applicationApi } from "shared/api/pagesApi";

export const useApplyV2 = (props) => {
  const navigate = useNavigate();
  const project_id = localStorage.getItem("project_id");

  const request = useMutation(
    (data) =>
      project_id
        ? applicationApi.gardenApplyUpdate(data, props.application_l)
        : applicationApi.applicationv2Create(data),
    {
      onSuccess: () => {
        notification["success"]({
          message: "Success",
          description: "Ariza muvaffaqiyatli yaratildi",
        });
        localStorage.removeItem("project_id");
        navigate("/");
      },
      onError: (error) => {
        notification["error"]({
          message: "Error",
          description: `${error?.detail}!`,
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file_1", props.apply);
    formData.append("file_2", props.file2);
    formData.append("file_3", props.file3);
    formData.append("file_4", props.file4);
    formData.append("file_5", props.file5);
    formData.append("file_6", props.file6);
    formData.append("file_7", props.file7);
    formData.append("file_8", props.file8);
    formData.append("file_9", props.file9);
    formData.append("file_10", props.file10);
    formData.append("application_l", props.application_l);
    request.mutate(formData);
  };
  return { onSubmit, isLoading: request.isLoading };
};
