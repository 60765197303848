import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import React from "react";
import { Card, Container, H2, H4, Wrapper } from "./style";
import ImgSuccess from "./successImg.png";
import { useNavigate } from "react-router-dom";

function ActSuccess() {
  const navigate = useNavigate();
  function OnClickButton() {
    navigate("/ms/personal-cabinet");
  }
  return (
    <Wrapper>
      <Card>
        <img src={ImgSuccess} alt="sucess" />
        <Container>
          <H2>Ariza muvaffaqiyatli jo‘natildi</H2>
          <H4>Sizga xabar kelishini kuting!</H4>
          <Button
            Text={"Web saytga qaytish"}
            backgroundColor={allColors.whiteColor}
            BorderColor={allColors.shriftColorOpasity3}
            TextColor={allColors.shriftColorOpasity3}
            Width={"170px"}
            OnClickButton={OnClickButton}
          />
        </Container>
      </Card>
    </Wrapper>
  );
}

export default ActSuccess;
