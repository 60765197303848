import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const ContentInput = styled.input`
  border: none;
  min-width: 100%;
  background-color: ${allColors.whiteColor};
  font-feature-settings: "tnum" on, "lnum" on;
  max-width: 140px;
  &:focus {
    outline: none;
  }
`;
