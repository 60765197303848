import React from "react";
import { Container } from "./style";
import { H3 } from "components/styles/styleComponetns";
import ButtonComponent from "../button/buttonComponent";

function MatnComponent() {
  const onSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <Container onSubmit={onSubmit}>
      <H3>
        Qishloq xo'jaligi vazirligi huzuridagi “Agroservis operator” davlat
        unitar korxonasi direktori A.U.Shukurovga
      </H3>
      <div>
        <p style={{ margin: 0 }}>
          &nbsp;&nbsp;O'zbekiston Respublikasi Prezidentining 2021 yil 15
          dekabrdagi “Meva-sabzavotchilik sohasini davlat tomonidan
          qullab-quvvatlash, tarmoqda klaster va kooperatsiya tizimini yana-da
          rivojlantirish chora-tadbirlari to'g'risida” gi PQ-52-sonli qarori
          bilan, Halqaro tiklanish va taraqqiyot banki hamda, Halqaro taraqqiyot
          uyushmasi ishtirokida «O'zbekiston Respublikasi qishloq xo'jaligini
          modernizatsiya qilish» loyihasi shartlari asosida Qishloq xo'jaligi
          vazirligi xuzuridagi “Agroservis operator” DUKga ajratilgan mablaglar
          xisobidan, tadbirkorlik subyektlariga tegishli bo'lgan yerlarda kichik
          agrologistik markazlar barpo etish belgilangan.
        </p>
        <p>
          &nbsp;&nbsp; Yuqoridagilarni inobatga olib, “FAYZ PARRANDA” XK MCHJ
          Sizdan, mazkur qarorning 5-bandiga muvofiq Toshkent viloyati,
          Yangiyo'l tumani, Shoralisoy QFY hududidagi 1,0 yer yer maydonida
          maxsulotlarni saqlash, qayta ishlash, quritish, qadoqlash bo'yicha
          kichik agrologistika markazini barpo etishda amaliy yordam
          berishingizni so'raymiz.
        </p>
      </div>
      <ButtonComponent />
    </Container>
  );
}

export default MatnComponent;
