import React from "react";
import Input from "./Input";
import { Table, TableBody, THead } from "./style";

function Table2({ Controller, control }) {
  return (
    <Table>
      <THead>
        <tr>
          <th>№</th>
          <th>Ko’rsatkichlar nomi</th>
          <th>1 oyda</th>
          <th>1 chorakda</th>
          <th>1 yilda</th>
        </tr>
      </THead>
      <TableBody>
        <tr>
          <td>1</td>
          <td>Ishchilar soni</td>
          <td>
            <Input
              name={"x_1_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_1_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_1_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Savdo xizmati ko’rsatish tushumi</td>
          <td>
            <Input
              name={"x_2_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_2_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_2_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Maxsulot sotib olish tannarxi</td>
          <td>
            <Input
              name={"x_3_month"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_3_quarter"}
              Controller={Controller}
              control={control}
              
              required
            />
          </td>
          <td>
            <Input
              name={"x_3_year"}
              Controller={Controller}
              control={control}
              
              required
            />
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Ish xaqi fondi</td>
          <td>
            <Input
              name={"x_4_month"}
              required
              Controller={Controller}
              control={control}
              
            />
          </td>
          <td>
            <Input
              name={"x_4_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_4_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>YAIT Ish xaqidan %</td>
          <td>
            <Input
              name={"x_5_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_5_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_5_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Komunal xarajatlar</td>
          <td>
            <Input
              name={"x_6_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_6_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_6_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>Transport xarajatlari</td>
          <td>
            <Input
              name={"x_7_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_7_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_7_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>Ko’zda tutilmagan xarajatlar</td>
          <td>
            <Input
              name={"x_8_month"}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_8_quarter"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_8_year"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
        </tr>

        <tr>
          <td>9</td>
          <td>Kredit foizi va asosiy qarz</td>
          <td>
            <Input
              name={"x_10_month"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_10_quarter"}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
          <td>
            <Input
              name={"x_10_year"}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>10</td>
          <td>Jami xarajatlar</td>
          <td>
            <Input
              name={"x_11_month"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
          <td>
            <Input
              name={"x_11_quarter"}
              Controller={Controller}
              disabled
              
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_11_year"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>11</td>
          <td>Soliq to’lovlari</td>
          <td>
            <Input
              name={"x_12_month"}
              Controller={Controller}
              required
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_12_quarter"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
          <td>
            <Input
              name={"x_12_year"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
        </tr>
        {/* <tr>
          <td>12</td>
          <td>Mol-mulk solig’i</td>
          <td>
            <Input
              name={"x_13_month"}
              Controller={Controller}
              required
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_13_quarter"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
          <td>
            <Input
              name={"x_13_year"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>13</td>
          <td>Yer solig’i</td>
          <td>
            <Input
              name={"x_14_month"}
              Controller={Controller}
              required
              control={control}
            />
          </td>
          <td>
            <Input
              name={"x_14_quarter"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
          <td>
            <Input
              name={"x_14_year"}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
        </tr>
        <tr>
          <td>14</td>
          <td>Asosiy kredit qarzi</td>
          <td>
            <Input
              name={"x_15_month"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td>
            <Input
              name={"x_15_quarter"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
          <td>
            <Input
              name={"x_15_year"}
              Controller={Controller}
              control={control}
              required
              disabled
            />
          </td>
        </tr> */}
        <tr className="tr-green">
          <td>12</td>
          <td>Sof foyda</td>
          <td>
            <Input
              name={"x_16_month"}
              Controller={Controller}
              control={control}
              required
              disabled
              
            />
          </td>
          <td>
            <Input
              name={"x_16_quarter"}
              Controller={Controller}
              required
              control={control}
              disabled
              
            />
          </td>
          <td>
            <Input
              name={"x_16_year"}
              Controller={Controller}
              control={control}
              required
              disabled
              
            />
          </td>
        </tr>
      </TableBody>
    </Table>
  );
}

export default Table2;
