import React from 'react';
import { ReactComponent as CheckCircleIcon } from 'icons/check.circle.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import {
  Comment,
  CommentContent,
  Content,
  Footer,
  List,
  ListItem,
  Wrapper,
} from '../../style';
import { useQuery } from '@tanstack/react-query';
import { gardenRetrieveApi } from 'shared/api/pagesApi';
import { Button } from 'antd';

const Logistics = ({ current, prev, next, message }) => {
  const app_l = localStorage.getItem('application_l');
  const { data, isLoading } = useQuery(['get-logistic-retrieve'], () =>
    gardenRetrieveApi.logisRetrieve(app_l)
  );
  return (
    <>
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <Wrapper>
          <Content>
            <List>
              <ListItem>
                <CommentContent>
                  <span>1. Ariza</span>
                  {data?.file_1?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_1?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_1?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>2. Shartnoma</span>
                  {data?.file_2?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_2?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_2?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>3. Nizom</span>
                  {data?.file_3?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_3?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_3?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>4. Guvohnoma</span>
                  {data?.file_4?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_4?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_4?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    5. Loyihaning texnik-iqtisodiy asosi; (Savolnoma taqdim
                    etiladi)
                  </span>
                  {data?.file_5?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_5?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_5?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    6. Xo‘jalik yurituvchi sub'ekt ro‘yxatdan o‘tkazilgan
                    joydagi soliq organi tomonidan tasdiqlanadigan oxirgi
                    hisobot davri uchun moliyaviy xujjatlar; (F1, F2).
                  </span>
                  {data?.file_6?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_6?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_6?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>7. Kadastr xujjati</span>
                  {data?.file_7?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_7?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_7?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>8. Yerga egalik xujjati</span>
                  {data?.file_8?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_8?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_8?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    9. Xududiy qurilish va uy-joy kommunal boshqarmasidan
                    qurilish uchun ruxsatnoma
                  </span>
                  {data?.file_9?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_9?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_9?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    10. Xizmat ko‘rsatuvchi bankdan kreditorlik qarzdorligi
                    mavjud emasligi xaqida ma'lumotnoma
                  </span>
                  {data?.file_10?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_10?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_9?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    11. Davlat soliq inspeksiyasidan kreditor qarzdorligi
                    to‘g‘risida ma'lumotnoma
                  </span>
                  {data?.file_11?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_11?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_11?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    12.A) Xar bir xarid qilinadigan uskunalar uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_12?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_12?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_12?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    12.B) Xar bir xarid qilinadigan uskunalar uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_13?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_13?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_13?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    12.C) Xar bir xarid qilinadigan uskunalar uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_14?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_14?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_14?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    13.A) Xar bir xarid qilinadigan avtotexnika uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_15?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_15?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_15?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    13.B) Xar bir xarid qilinadigan avtotexnika uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_16?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_16?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_16?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    13.C) Xar bir xarid qilinadigan avtotexnika uchun 3 tadan
                    tijorat taklifi
                  </span>
                  {data?.file_17?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_17?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_17?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    14. Tuman arxitektura va shaharsozlik tomonidan magistral
                    qizil chizig‘iga tushmaganligi bo‘yicha ma'lumot (1 oylik)
                    muddat bilan
                  </span>
                  {data?.file_18?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_18?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_18?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
            </List>
          </Content>
          <Footer>
            {current > 0 && (
              <Button onClick={() => prev()} className="error-btn">
                Xatoni to’g’irlash
              </Button>
            )}
            {current < 4 && (
              <Button
                type="primary"
                className="confirm"
                onClick={() => next()}
                disabled={data?.project_comment_exists}
              >
                Davom etish
              </Button>
            )}
            {current === 5 && (
              <Button
                type="primary"
                onClick={() => message.success('Processing complete!')}
                className="confirm"
              >
                Yakunlandi
              </Button>
            )}
          </Footer>
        </Wrapper>
      )}
    </>
  );
};

export default Logistics;
