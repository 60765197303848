import React from "react";
import { Ul, Title, Wrapper, ApplyLink, ButtonContainer } from "./style";

const Banner = () => {
  return (
    <Wrapper>
      <Title>
        Meva-sabzavotchilik, uzumchilik sohasini moliyalashtirish tizimiga xush
        kelibsiz
      </Title>
      <Ul>
        <li>Moliyalashtirish</li>
        <li>&#9679;</li>
        <li>Skoring</li>
        <li>&#9679;</li>
        <li>Sug’urta</li>
        <li>&#9679;</li>
        <li>Export</li>
      </Ul>
      <ButtonContainer>
        <ApplyLink
          to="/ms-auth-oneId"
          onClick={() => localStorage.setItem("isCabinetGarden", false)}
        >
          Ariza berishga kirish
        </ApplyLink>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Banner;
