import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const ContainerAct = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  .input-error {
    border: 1px solid #e54e53;
  }
  .error-span {
    color: #e54e53;
    margin-top: 6px;
    font-size: 13px;
  }
  @media (max-width: 992px) {
    .teskari {
      flex-wrap: wrap-reverse !important;
    }
  }
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 12px;
`;
export const FileContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`
export const ButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`
export const CardsCalc = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: ${allColors.whiteColor};
  box-sizing: border-box;
`
export const Label = styled.label`
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
  cursor: pointer;
 
`