import { Col, Row } from "antd";
import InputController from "components/input/inputController";
import { SelectController } from "components/select/selectController";
import {
  Cards,
  H1,
  H3,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import { ContainerAct } from "pages/agro-kredit/ariza-page/container/style";
import Buttons from "pages/agroleasing/components/button-components/Buttons";
import React from "react";
import { Controller } from "react-hook-form";
import { Hooks } from "../components/hooks";
const PersonalInfo = () => {
  const { handleSubmit, control, errors, region, district, onSubmit } = Hooks();

  return (
    <MainContainer>
      <MarginContainer>
        <H1>Agrologistika markazi lizing asosida barpo etish haqida ariza</H1>
        <ContainerAct>
          <Row gutter={[16, 16]}>
            <Col sm={24} lg={24} xl={24}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Cards>
                  <H3>Foydalanuvchi ma'lumotlari</H3>
                  <Row gutter={16}>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"INN"}
                        placeholder="stir"
                        Controller={Controller}
                        control={control}
                        name={"stir"}
                        inputType={"number"}
                        disabled={true}
                        className={
                          errors &&
                          errors?.hasOwnProperty("stir") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("stir") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"Korxona nomi"}
                        placeholder="nomi"
                        Controller={Controller}
                        control={control}
                        name={"company_name"}
                        disabled={true}
                        className={
                          errors &&
                          errors?.hasOwnProperty("company_name") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("company_name") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"Yuridik manzili"}
                        placeholder="manzil"
                        Controller={Controller}
                        control={control}
                        name={"legal_address"}
                        disabled={true}
                        className={
                          errors &&
                          errors?.hasOwnProperty("legal_address") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("legal_address") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <SelectController
                        control={control}
                        Controller={Controller}
                        name={"region"}
                        label={"Viloyat"}
                        placeholder="Tanlang"
                        options={region}
                        disabled={true}
                        className={
                          errors &&
                          errors?.hasOwnProperty("region") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("region") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <SelectController
                        control={control}
                        Controller={Controller}
                        name={"district"}
                        label={"Tuman"}
                        placeholder="Tanlang"
                        options={district}
                        disabled={true}
                        // disabled={district.length === 0 ? true : false}
                        className={
                          errors &&
                          errors?.hasOwnProperty("district") &&
                          "input-error"
                        }
                      />

                      {errors && errors?.hasOwnProperty("district") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>

                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"phone_number"}
                        label={"Telefon raqam*"}
                        placeholder="telefon"
                        inputType={"number"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("phone_number") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("phone_number") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"email"}
                        label={"E-mail*"}
                        placeholder="email"
                        inputType={"email"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("email") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("email") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Buttons />
                </Cards>
              </form>
            </Col>
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
};

export default PersonalInfo;
