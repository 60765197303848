import React from 'react';
import { ReactComponent as CheckCircleIcon } from 'icons/check.circle.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useQuery } from '@tanstack/react-query';
import { gardenRetrieveApi } from 'shared/api/pagesApi';
import { Button } from 'antd';
import {
  Comment,
  CommentContent,
  Content,
  Footer,
  List,
  ListItem,
  Wrapper,
} from '../../style';

export const Garden = ({ current, prev, next, message }) => {
  const app_l = localStorage.getItem('application_l');
  const { data, isLoading } = useQuery(
    ['get-garden-retrieve-agroservice'],
    () => gardenRetrieveApi.gardenRetrieve(app_l)
  );

  return (
    <>
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <Wrapper>
          <Content>
            <List>
              <ListItem>
                <CommentContent>
                  <span>1. Ariza</span>
                  {data?.file_1?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_1?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_1?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    2. «Agroservis operator» DUK bilan tuzilgan dastlabki
                    shartnoma
                  </span>
                  {data?.file_2?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_2?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_2?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>3. Ariza beruvchi korxona nizomi</span>
                  {data?.file_3?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_3?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_3?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span> 4. Ariza beruvchi korxona guvoxnomasi</span>
                  {data?.file_4?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_4?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_4?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    5. Hokimlikning bog‘ va uzumzorlar tashkil etish uchun yer
                    maydonini ajratish bo‘yicha qarori (nusxasi)
                  </span>
                  {data?.file_5?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_5?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_5?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    6. Ushbu qaror xaqiqatdan xam kuchda ekanligi bo‘yicha
                    Xokimyat tomonidan taqdim qilingan ma'lumotnoma (1 oylik)
                    muddat bilan
                  </span>
                  {data?.file_6?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_6?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_6?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>7. Er uchastkasining KMZ formatdagi xaritasi</span>
                  {data?.file_7?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_7?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_7?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>8. Sanitariya-epidemiologiya stansiya xulosasi</span>
                  {data?.file_8?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_8?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_8?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    9. Kadastr bo‘limidan ajratilgan yer uchastkasining garov
                    ostida emasligi to‘g‘risida yer uchastkasining kontur
                    raqamlari ko‘rsatilgan ma'lumotnoma(1 oylik)
                  </span>
                  {data?.file_9?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_9?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_9?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
              <ListItem>
                <CommentContent>
                  <span>
                    10. Arxitektura va shaharsozlik tomonidan magistral qizil
                    chizig‘iga tushmaganligi bo‘yicha ma'lumotnoma (1 oylik)
                    muddat bilan
                  </span>
                  {data?.file_10?.proj_office_comment === null ? (
                    <CheckCircleIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </CommentContent>
                {data?.file_10?.proj_office_comment !== null && (
                  <Comment>
                    <h5>Hujjatdagi kamchiliklar haqida izoh</h5>
                    <p>{data?.file_10?.proj_office_comment}</p>
                  </Comment>
                )}
              </ListItem>
            </List>
          </Content>
          <Footer>
            {current > 0 && (
              <Button onClick={() => prev()} className="error-btn">
                Xatoni to’g’irlash
              </Button>
            )}
            {current < 4 && (
              <Button
                type="primary"
                className="confirm"
                onClick={() => next()}
                disabled={data?.operator_comment_exists}
              >
                Davom etish
              </Button>
            )}
            {current === 5 && (
              <Button
                type="primary"
                onClick={() => message.success('Processing complete!')}
                className="confirm"
              >
                Yakunlandi
              </Button>
            )}
          </Footer>
        </Wrapper>
      )}
    </>
  );
};
