import React, { Fragment, useEffect, useState } from "react";
import { Col, notification, Row } from "antd";
import { Cards, Form, TitleH4, Hr } from "../style";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import ButtonComponent from "../buttonComponent";
import InputController from "components/input/inputController";
import RadioBoxComponent from "../../radioBoxContainer";
import RadioComponent from "components/input/radioComponent";
import FileUpload from "components/fileUpload";
import { Line, TotalCost, Wrapper } from "./style";
import PhotoInfo from "./photoInfo";
import { BsPlus } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { allColors } from "components/colors/AllColors";
import Button from "components/Button";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applyApi, technicsApi } from "shared/api/pagesApi";
import InputComponent from "components/input/inputComponent";
import { SelectController } from "components/select/selectController";
import FileUploadForTechnics from "./fileUpload";

// ombor rasmi va muzlatgich rasmi  joylash qo'shish!!!

function Technologies() {
  const getStep = localStorage.getItem("userStep");
  const editStatus = useAccordionStore((state) => state.editStatus);
  const accordionData = useAccordionStore((state) => state.accordionData);
  const [hasUpdate, setHasUpdate] = useState(true);
  const [fileId, setFileId] = useState([]);
  const [fridgeFileId, setFridgeFileId] = useState([]);
  const [productWarehouseId, setProductWarehouseId] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsVal, setOptionsVal] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      products: [
        {
          pc_product_name: null,
          pc_unit: "Tonna",
          pc_price: null,
          pc_daily: null,
          pc_monthly: null,
          pc_annually: null,
        },
      ],
    },
  });
  const [productWarehouse, setProductWarehouse] = useState(false);
  const [pledge, setPledge] = useState(false);
  const [refrigerator, setRefrigerator] = useState(false);
  const [isItRented, setIsItRented] = useState(false);
  const [recycledEquipment, setRecycledEquipment] = useState(false);
  const [technicsFoto, setTechnicsFoto] = useState([]);
  const [fridgeFoto, setFridgeFoto] = useState([]);
  const [leaseAgreement, setLeaseAgreement] = useState([]);
  const [leaseAgreementFile, setLeaseAgreementFile] = useState([]);
  const [leaseAgreementForRefrigerator, setLeaseAgreementForRefrigerator] =
    useState([]);
  const [
    leaseAgreementFileForRefrigerator,
    setLeaseAgreementFileForRefrigerator,
  ] = useState([]);
  const [productWarehouseFoto, setProductWarehouseFoto] = useState([]);

  async function deleteFoto({ setstate, state, id, fileid, setfileid }) {
    setstate(state.filter((item, index) => index !== id));
    let a = fileid.filter((v, i) => i === id);
    setfileid(fileid.filter((v, i) => i !== id));
    await technicsApi.deleteData(`/file/delete/${a}/`);
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const productss = useWatch({ name: "products", control });
  const internal_price = useWatch({ name: "internal_price", control });
  const external_price = useWatch({ name: "external_price", control });
  const internal_volume = useWatch({ name: "internal_volume", control });
  const external_volume = useWatch({ name: "external_volume", control });
  const { mutate, isLoading } = useMutation(
    (data) => applyApi.sendApply(data),
    {
      onSuccess: (res) => {
        notification["success"]({
          message: "Success",
          description: "3-qadam yakunlandi!!!",
        });
        editStatus({
          step: 5,
        });
        localStorage.setItem("userStep", 5);
        localStorage.setItem("app_id", res?.m_technology);
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Xatolik yuz berdi!",
        });
      },
    }
  );
  function NextStep(data) {
    let formData = new FormData();
    let t_project = localStorage.getItem("app_id");
    formData.append("process_step", 4);
    if (hasUpdate) {
      formData.append("id", data.id);
      formData.append("t_project", data.t_project);
    } else formData.append("t_project", t_project);
    formData.append(
      "products",
      JSON.stringify(
        productss?.map((item) => {
          return {
            ...item,
            pc_product_name: item?.pc_product_name?.value,
          };
        })
      )
    );
    formData.append("products_count", productss.length);
    formData.append(
      "daily_total",
      productss?.map((a) => Number(a.pc_daily)).reduce((i, s) => i + s, 0)
    );
    formData.append(
      "monthly_total",
      productss?.map((a) => Number(a.pc_monthly)).reduce((i, s) => i + s, 0)
    );
    formData.append(
      "annually_total",
      productss?.map((a) => Number(a.pc_annually)).reduce((i, s) => i + s, 0)
    );

    if (productWarehouse) {
      formData.append("t_storage_cad_num", data.t_storage_cad_num);
      formData.append("t_storage_area", data.t_storage_area);
      formData.append("t_storage_pledge", pledge);
      if (pledge) {
        formData.append("t_storage_rent", leaseAgreement[0]);
      }
    }
    if (refrigerator) {
      formData.append("t_fridge_area", data.t_fridge_area);
      formData.append("t_fridge_rented", isItRented);
      if (isItRented) {
        formData.append("t_fridge_rent", leaseAgreementForRefrigerator[0]);
      }
    }
    // technicsFoto.forEach((v) => formData.append('files', v))
    formData.append("equipments", JSON.stringify(fileId));
    formData.append("t_fridge_photos", JSON.stringify(fridgeFileId));
    formData.append("t_storage_photos", JSON.stringify(productWarehouseId));
    formData.append("internal_product_name", data.internal_product_name.value);
    formData.append("internal_volume", data.internal_volume);
    formData.append("internal_price", data.internal_price);
    formData.append("internal_amount", Number(data.internal_amount));
    formData.append("external_product_name", data.external_product_name.value);
    formData.append("external_volume", data.external_volume);
    formData.append("external_price", data.external_price);
    formData.append("external_amount", Number(data.external_amount));

    mutate(formData);
  }
  useQuery(
    ["get_product"],
    async () => await applyApi.getRequest("/tools/plants/"),
    {
      onSuccess: (res) => {
        setOptions(res);
      },
      enabled: parseInt(getStep) === 4 ? true : false,
    }
  );
  useQuery(
    ["get_technologies_update", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data?process_step=${getStep}`),
    {
      onSuccess: (res) => {
        if (res) {
          reset({
            ...res,
          });
          setHasUpdate(true);
          if (res?.t_fridge_area || res?.t_fridge_rented) {
            setRefrigerator(true);
            setIsItRented(res?.t_fridge_rented);
            setFridgeFoto(res?.t_fridge_photos);
            if (res?.t_fridge_rented) {
              setLeaseAgreementFileForRefrigerator([res?.t_fridge_rent]);
              setLeaseAgreementForRefrigerator([res?.t_fridge_rent?.id]);
            }
            setFridgeFileId((prev) => [
              ...prev,
              ...res?.t_fridge_photos.map((item) => item.id),
            ]);
          }
          if (
            res?.t_storage_cad_num ||
            res?.t_storage_area ||
            res?.t_storage_pledge
          ) {
            setProductWarehouse(true);
            setPledge(res?.t_storage_pledge);
            if (res?.t_storage_pledge && res?.t_storage_rent) {
              setLeaseAgreementFile([res?.t_storage_rent]);
              setLeaseAgreement([res?.t_storage_rent?.id]);
            }
            setProductWarehouseFoto(res?.t_storage_photos);
            setProductWarehouseId((prev) => [
              ...prev,
              ...res?.t_storage_photos.map((item) => item.id),
            ]);
          }
          if (res?.equipments?.length > 0) {
            setRecycledEquipment(true);
            setTechnicsFoto(res?.equipments);
            setFileId((prev) => [
              ...prev,
              ...res?.equipments.map((item) => item.id),
            ]);
          }

          if (res?.t_fridge_photos?.length > 0) {
          }
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled: parseInt(getStep) === 4 ? true : false,
    }
  );

  function addProduct() {
    append({
      pc_product_name: null,
      pc_unit: "Tonna",
      pc_price: null,
      pc_daily: null,
      pc_monthly: null,
      pc_annually: null,
    });
  }
  function goBack() {
    editStatus({
      step: 3,
    });
    localStorage.setItem("userStep", 3);
  }
  useEffect(() => {
    let a = [];
    productss?.map((item, index) => a.push(item.pc_product_name));
    setOptionsVal(a);
  }, [productss]);
  useEffect(() => {
    setValue("external_amount", external_price * external_volume);
    setValue("internal_amount", internal_price * internal_volume);
  }, [internal_price, external_price, internal_volume, external_volume]);

  return (
    <Form onSubmit={handleSubmit(NextStep)}>
      <RadioBoxComponent
        name={"max-saq-ombori"}
        Title={"Maxsulot saqlash ombori mavjudligi:"}
        setRadioValue={setProductWarehouse}
        value={productWarehouse}
      />
      {productWarehouse && (
        <Row gutter={16}>
          <Col span={24} xl={8} lg={12} sm={12}>
            <InputController
              name={"t_storage_cad_num"}
              label="Bino kadastr raqami"
              inputType={"text"}
              Controller={Controller}
              control={control}
              required
              className={errors && errors?.t_storage_cad_num && "input-error"}
            />
            {errors && errors?.t_storage_cad_num && (
              <span className="error-span">Please enter information!</span>
            )}
          </Col>
          <Col span={24} xl={8} lg={12} sm={12}>
            <InputController
              name={"t_storage_area"}
              label="Maydoni (m.kv)"
              inputType={"number"}
              Controller={Controller}
              control={control}
              required={true}
              className={errors && errors?.t_storage_area && "input-error"}
            />
            {errors && errors?.t_storage_area && (
              <span className="error-span">Please enter information!</span>
            )}
          </Col>
          <Col span={24} xl={8} lg={12} sm={12}>
            <RadioComponent
              name={"t_storage_pledge"}
              label={"Ijaraga olinganmi?"}
              setRadioValue={setPledge}
              value={pledge}
            />
          </Col>
          {pledge && (
            <Col span={24} xl={24} sm={24}>
              <Wrapper>
                <FileUploadForTechnics
                  setTechnicsFoto={setLeaseAgreementFile}
                  label="Ijara shartnomasini yuklash"
                  setFileId={setLeaseAgreement}
                  fileId={leaseAgreement}
                  nameId="shartnomaId"
                  disabled={leaseAgreementFile.length === 1}
                />
                <Row gutter={[12, 12]}>
                  {leaseAgreementFile.map((item, index) => (
                    <Col span={24} xl={12} lg={12} md={12} sm={24} key={index}>
                      <PhotoInfo
                        file={item}
                        deleteFile={() =>
                          deleteFoto({
                            setstate: setLeaseAgreementFile,
                            state: leaseAgreementFile,
                            fileid: leaseAgreement,
                            setfileid: setLeaseAgreement,
                            id: index,
                          })
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Wrapper>
            </Col>
          )}
          <Col span={24} xl={24} sm={24}>
            <Wrapper>
              <FileUpload
                setTechnicsFoto={setProductWarehouseFoto}
                label="Saqlash ombori rasmlarini yuklash"
                setFileId={setProductWarehouseId}
                fileId={productWarehouseId}
                nameId="omborID"
              />
              <Row gutter={[12, 12]}>
                {productWarehouseFoto.map((item, index) => (
                  <Col span={24} xl={8} lg={12} md={12} sm={24} key={index}>
                    <PhotoInfo
                      file={item}
                      deleteFile={() =>
                        deleteFoto({
                          setstate: setProductWarehouseFoto,
                          state: productWarehouseFoto,
                          fileid: productWarehouseId,
                          setfileid: setProductWarehouseId,
                          id: index,
                        })
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Wrapper>
          </Col>
        </Row>
      )}
      <RadioBoxComponent
        name={"shax-muz-mavjud"}
        Title={"Shaxsiy muzlatgichlar mavjudligi:"}
        setRadioValue={setRefrigerator}
        value={refrigerator}
      />
      {refrigerator && (
        <Row gutter={16}>
          <Col span={24} xl={8} lg={12} sm={12}>
            <InputController
              name={"t_fridge_area"}
              label="Maydoni (m.kv)"
              inputType={"number"}
              required
              Controller={Controller}
              control={control}
              className={errors && errors?.t_fridge_area && "input-error"}
            />
            {errors && errors?.t_fridge_area && (
              <span className="error-span">Please enter information!</span>
            )}
          </Col>
          <Col span={24} xl={8} lg={12} sm={12}>
            <RadioComponent
              name={"t_fridge_rented"}
              label={"Ijaraga olinganmi?"}
              setRadioValue={setIsItRented}
              value={isItRented}
            />
          </Col>
          {isItRented && (
            <Col span={24} xl={24} sm={24}>
              <Wrapper>
                <FileUploadForTechnics
                  setTechnicsFoto={setLeaseAgreementFileForRefrigerator}
                  label="Ijara shartnomasini yuklash"
                  setFileId={setLeaseAgreementForRefrigerator}
                  fileId={leaseAgreementForRefrigerator}
                  nameId="shartnomaIdMuz"
                  disabled={leaseAgreementFileForRefrigerator.length === 1}
                />
                <Row gutter={[12, 12]}>
                  {leaseAgreementFileForRefrigerator.map((item, index) => (
                    <Col span={24} lg={12} md={12} sm={24} key={index}>
                      <PhotoInfo
                        file={item}
                        deleteFile={() =>
                          deleteFoto({
                            setstate: setLeaseAgreementFileForRefrigerator,
                            state: leaseAgreementFileForRefrigerator,
                            fileid: leaseAgreementForRefrigerator,
                            setfileid: setLeaseAgreementForRefrigerator,
                            id: index,
                          })
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Wrapper>
            </Col>
          )}
          <Col span={24} xl={24} sm={24}>
            <Wrapper>
              <FileUpload
                setTechnicsFoto={setFridgeFoto}
                label="Shaxsiy muzlatgichlar rasmlarini yuklash"
                setFileId={setFridgeFileId}
                fileId={fridgeFileId}
                nameId="muzlatgichID"
              />
              <Row gutter={[12, 12]}>
                {fridgeFoto.map((item, index) => (
                  <Col span={24} xl={8} lg={12} md={12} sm={24} key={index}>
                    <PhotoInfo
                      file={item}
                      deleteFile={() =>
                        deleteFoto({
                          setstate: setFridgeFoto,
                          state: fridgeFoto,
                          fileid: fridgeFileId,
                          setfileid: setFridgeFileId,
                          id: index,
                        })
                      }
                    />
                  </Col>
                ))}
              </Row>
            </Wrapper>
          </Col>
        </Row>
      )}
      <RadioBoxComponent
        name={"recycled-equipment"}
        Title={"Qayta ishlash uskunalari mavjudligi:"}
        setRadioValue={setRecycledEquipment}
        value={recycledEquipment}
      />
      {recycledEquipment && (
        <Wrapper>
          <FileUpload
            setTechnicsFoto={setTechnicsFoto}
            label="Uskuna rasmlarini yuklash"
            setFileId={setFileId}
            fileId={fileId}
            nameId="uskunaId"
          />
          <Row gutter={[12, 12]}>
            {technicsFoto.map((item, index) => (
              <Col span={24} xl={8} lg={12} md={12} sm={24} key={index}>
                <PhotoInfo
                  file={item}
                  deleteFile={() =>
                    deleteFoto({
                      setstate: setTechnicsFoto,
                      state: technicsFoto,
                      fileid: fileId,
                      setfileid: setFileId,
                      id: index,
                    })
                  }
                />
              </Col>
            ))}
          </Row>
        </Wrapper>
      )}
      <Cards>
        <TitleH4 className="mt-0">
          Qayta ishlab chiqarish uchun sotib olinadigan mahsulotlar:
        </TitleH4>
        <Row style={{ width: "100%" }} gutter={[16]}>
          {fields.map((item, index) => {
            return (
              <Fragment key={item.id}>
                {index > 0 ? (
                  <Col span={24} xl={24}>
                    <Hr />
                  </Col>
                ) : null}
                <Col span={24} xl={8} lg={12} sm={12}>
                  <SelectController
                    control={control}
                    Controller={Controller}
                    name={`products[${index}].pc_product_name`}
                    label={`${index + 1}. Maxsulot nomi`}
                    options={options}
                    defaultValue={item?.pc_product_name}
                    placeholder="Tanlang"
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_product_name &&
                      "input-error"
                    }
                  />

                  {errors && errors?.products?.[index]?.pc_product_name && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    label={"Birlik"}
                    name={`products[${index}].pc_unit`}
                    defaultValue={item.pc_unit}
                    Controller={Controller}
                    control={control}
                    disabled={true}
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_product_name &&
                      "input-error"
                    }
                  />
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    label={"Narxi, (tn/so`m)"}
                    name={`products[${index}].pc_price`}
                    Controller={Controller}
                    defaultValue={item.pc_price}
                    control={control}
                    inputType={"number"}
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_price &&
                      "input-error"
                    }
                  />
                  {errors?.products?.[index]?.pc_price && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} lg={24}>
                  <TitleH4 className="mt-3">
                    Qayta ishlab chiqariladigan mahsulot hajmi:
                  </TitleH4>
                </Col>

                <Col span={24} xl={6} lg={8} md={8} sm={12}>
                  <InputController
                    placeholder={"Vazni"}
                    label={`1 kunda, (tn)`}
                    name={`products[${index}].pc_daily`}
                    Controller={Controller}
                    defaultValue={item.pc_daily}
                    control={control}
                    inputType={"number"}
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_daily &&
                      "input-error"
                    }
                  />
                  {errors?.products?.[index]?.pc_daily && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={6} lg={8} md={8} sm={12}>
                  <InputController
                    placeholder={"Vazni"}
                    label={`1 oyda, (tn)`}
                    name={`products[${index}].pc_monthly`}
                    Controller={Controller}
                    defaultValue={item.pc_monthly}
                    control={control}
                    inputType={"number"}
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_monthly &&
                      "input-error"
                    }
                  />
                  {errors?.products?.[index]?.pc_monthly && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={6} lg={8} md={8} sm={12}>
                  <InputController
                    placeholder={"Vazni"}
                    label={`1 yilda, (tn)`}
                    name={`products[${index}].pc_annually`}
                    defaultValue={item.pc_annually}
                    Controller={Controller}
                    control={control}
                    inputType={"number"}
                    required
                    className={
                      errors &&
                      errors?.products?.[index]?.pc_annually &&
                      "input-error"
                    }
                  />
                  {errors?.products?.[index]?.pc_annually && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col
                  span={24}
                  xl={6}
                  lg={8}
                  md={8}
                  sm={12}
                  style={{ alignSelf: "end", marginTop: "16px" }}
                >
                  {index > 0 ? (
                    <Button
                      Icon={
                        <AiOutlineDelete
                          style={{ fontSize: "20px" }}
                          color={allColors.shriftRed}
                        />
                      }
                      TextColor={allColors.shriftRed}
                      backgroundColor={"rgba(235, 87, 87, 0.1)"}
                      Text="O'chirish"
                      Width="120px"
                      OnClickButton={() => remove(index)}
                    />
                  ) : (
                    <Button
                      Icon={<BsPlus style={{ fontSize: "20px" }} />}
                      TextColor={allColors.mainColor}
                      backgroundColor={"#e6f1ef"}
                      Text="Qo’shish"
                      Width="120px"
                      OnClickButton={addProduct}
                    />
                  )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
        <Line />
        <TotalCost>Jami qiymati:</TotalCost>
        <Row gutter={16}>
          <Col span={24} xl={6} lg={8} md={8} sm={12}>
            <InputComponent
              placeholder={"Soni"}
              label={`Maxsulotlar soni`}
              name="products_count"
              disabled
              value={productss?.length}
            />
          </Col>
          <Col span={24} xl={6} lg={8} md={8} sm={12}>
            <InputComponent
              placeholder={"Vazni"}
              label={`1 kunda, (tn)`}
              name="daily_total"
              disabled
              value={productss
                ?.map((a) => Number(a.pc_daily))
                .reduce((i, s) => i + s, 0)}
            />
          </Col>
          <Col span={24} xl={6} lg={8} md={8} sm={12}>
            <InputComponent
              placeholder={"Vazni"}
              label={`1 oyda, (tn)`}
              name="monthly_total"
              disabled
              value={productss
                ?.map((a) => Number(a.pc_monthly))
                .reduce((i, s) => i + s, 0)}
            />
          </Col>
          <Col span={24} xl={6} lg={8} md={8} sm={12}>
            <InputComponent
              placeholder={"Vazni"}
              label={`1 yilda, ${watch("birlik") ? watch("birlik").value : ""}`}
              name="annually_total"
              disabled={true}
              value={productss
                ?.map((a) => Number(a.pc_annually))
                .reduce((i, s) => i + s, 0)}
            />
          </Col>
        </Row>
      </Cards>

      <TitleH4>Ishlab chiqarilgan maxsulotni ichki bozorda sotish:</TitleH4>
      <Row gutter={16}>
        <Col span={24} xl={8} lg={12} sm={12}>
          <SelectController
            control={control}
            Controller={Controller}
            name={"internal_product_name"}
            label="Maxsulot nomi"
            options={optionsVal}
            // defaultValue={'internal_product_name'}
            placeholder="Tanlang"
            required
            className={
              errors &&
              errors?.hasOwnProperty("internal_product_name") &&
              "input-error"
            }
          />

          {errors && errors?.hasOwnProperty("internal_product_name") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"internal_volume"}
            label="Hajmi,(tn)"
            inputType={"number"}
            Controller={Controller}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("internal_volume") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("internal_volume") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"internal_price"}
            label="Narxi, (tn/so’m)"
            inputType={"number"}
            Controller={Controller}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("internal_price") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("internal_price") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"internal_amount"}
            label="Summasi,(so'm)"
            disabled={true}
            inputType={"text"}
            Controller={Controller}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("internal_price") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("internal_price") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
      </Row>
      <TitleH4>Ishlab chiqarilgan maxsulotni tashqi bozorda sotish:</TitleH4>
      <Row gutter={16}>
        <Col span={24} xl={8} lg={12} sm={12}>
          <SelectController
            control={control}
            Controller={Controller}
            name={"external_product_name"}
            label="Maxsulot nomi"
            options={optionsVal}
            // defaultValue={'internal_product_name'}
            placeholder="Tanlang"
            required
            className={
              errors &&
              errors?.hasOwnProperty("external_product_name") &&
              "input-error"
            }
          />

          {errors && errors?.hasOwnProperty("external_product_name") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"external_volume"}
            label="Hajmi,(tn)"
            inputType={"number"}
            Controller={Controller}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("external_volume") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("external_volume") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"external_price"}
            label="Narxi, (tn/$)"
            inputType={"number"}
            Controller={Controller}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("external_price") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("external_price") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            name={"external_amount"}
            label="Summasi,($)"
            Controller={Controller}
            disabled
            inputType={"text"}
            control={control}
            required
            className={
              errors &&
              errors?.hasOwnProperty("external_amount") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("external_amount") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
      </Row>
      <ButtonComponent goBack={goBack} disabled={isLoading} />
    </Form>
  );
}

export default Technologies;
