import React from "react";
import {
  AccountCard,
  Container,
  LogoMain,
  Wrapper,
  DropItem,
  DropExitItem,
  Marquee,
  Main,
} from "./style";

import { FaRegUser } from "react-icons/fa";
import { MainContainer } from "components/styles/styleComponetns";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ReactComponent as Logo } from "assets/images/nav/logoPortal.svg";
import { BiRightArrowAlt } from "react-icons/bi";
import { ReactComponent as VazLogo } from "assets/images/nav/logo.svg";
import { Dropdown } from "antd";

function Navbar({ isLanding }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  var fullName = "";
  var decoded = "";
  if (userInfo && !isLanding) {
    decoded = jwt_decode(userInfo.accessToken);
    fullName = decoded?.last_name + " " + decoded?.first_name.charAt(0) + ".";
  }
  // const changeCabinetEdit = () => {
  //   localStorage.setItem("isCabinetGarden", true);
  // };
  const Exit = () => {
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();
    // JSON.parse(localStorage.setItem("userInfo", null));
  };
  const pathSegments = location.pathname.split("/"); // Split the pathname by '/'
  const firstPathSegment = pathSegments[1]; // Get the first segment
  const itemsCredit = [
    {
      key: "1",
      label: <DropItem to={"ms/personal-cabinet"}>Shaxsiy kabinet</DropItem>,
    },
    {
      key: "2",
      label: (
        <DropExitItem onClick={Exit}>
          Chiqish <BiRightArrowAlt />
        </DropExitItem>
      ),
    },
  ];
  const itemsLizing = [
    {
      key: "1",
      label: (
        <DropItem to={"agro-leasing/personal-cabinet"}>
          Shaxsiy kabinet
        </DropItem>
      ),
    },
    {
      key: "2",
      label: (
        <DropExitItem onClick={Exit}>
          Chiqish <BiRightArrowAlt />
        </DropExitItem>
      ),
    },
  ];

  return (
    <Main>
      <Marquee behavior="alternate" direction="left">
        Sayt test rejimida ishlamoqda / Сайт находится в тестовом режиме / Sayt
        test rejimida ishlamoqda / Сайт находится в тестовом режиме / Sayt test
        rejimida ishlamoqda / Сайт находится в тестовом режиме / Sayt test
        rejimida ishlamoqda / Сайт находится в тестовом режиме /
      </Marquee>
      <Wrapper>
        <MainContainer>
          <Container>
            <LogoMain>
              <Logo className={"vaz-logo"}  />
              <VazLogo className={"bog-logo"} />
              {/* <BogdorchilikLogoPlus className={"plus-logo"} /> */}
            </LogoMain>
            {isLanding ? null : (
              <AccountCard>
                <Dropdown
                  menu={{
                    items:
                      firstPathSegment === "ms" ? itemsCredit : itemsLizing,
                  }}
                  trigger={["click"]}
                >
                  <div className="main-account">
                    <span style={{ textTransform: "capitalize" }}>
                      {fullName?.toLowerCase()}
                    </span>
                    <FaRegUser />
                  </div>
                </Dropdown>
              </AccountCard>
            )}
          </Container>
        </MainContainer>
      </Wrapper>
    </Main>
  );
}

export default Navbar;
