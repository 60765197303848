import { useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { notification } from "antd";

export const useProducts = () => {
  const getStep = localStorage.getItem("userStep");
  return useQuery(
    ["get_product_list"],
    () => applyApi.getRequest("/tools/plants/"),
    {
      
      onSuccess: (response) => {
        return response;
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Products da xatolik!",
        });
      },
      enabled:
        parseInt(getStep) === 4 &&
        localStorage.getItem("dep_type") === "HARVEST"
          ? true
          : false,
    }
  );
};
