import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const RadioBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 0px;
  gap: 10px;
  /* height: 44px; */
`;
export const RadioBoxCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
`;
export const RadioBoxInput = styled.input`
  accent-color: ${allColors.mainColor};
  font-size: 20px;
  transform: scale(1.4);
`;
export const InputLabel = styled.label`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${allColors.shriftColor};
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-top: 16px;
  .ant-input {
    background-color: ${allColors.selectBgColor};
  }
`;
export const Label = styled.label`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: ${allColors.shriftColor};
`;
