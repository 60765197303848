import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

export default function ReactLoader({ propWidth, colors, alignItem }) {
  return (
    <ReactLoaderSyled className={`react-loader`} alignItem={alignItem}>
      <ReactLoading
        type="bars"
        color={colors || '#226A47'}
        width={propWidth ? propWidth : 40}
      />
    </ReactLoaderSyled>
  )
}

const ReactLoaderSyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItem || 'start'};
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: ${(props) => (props.alignItem ? 0 : '150px')};
  justify-content: center;
`
