import React from "react";
import CopyrightFooter from "../components/copyrightFooter";
import FooterInfo from "../components/footerInfo";

function Footer({ isLanding }) {
  return (
    <>
      <FooterInfo isLanding={isLanding} />
      <CopyrightFooter />
    </>
  );
}

export default Footer;
