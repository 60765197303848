import { useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export function useHooks() {
  //   const [filesInfo, setFilesInfo] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { control, reset } = useForm();
  const { data } = useQuery(
    ["get_apply_status_info"],
    async () =>
      await applyApi.getRequest(`/personal-cabinet/rejected-app/${id}`),
    {
      select: (res) => {
        return {
          ...res, //moment(item.created_at).format("DD.MM.YYYY")
          reject_date: moment(res.reject_date).format("DD.MM.YYYY"),
        };
      },
      onSuccess: (res) => {
        reset({
          ...res,
          applyStatus: "Rad etilgan",
        });
      },
      onError: (err) => {
        if (err?.status_code === 401) {
          navigate("/");
        }
      },
    }
  );
  return { control, data };
}
