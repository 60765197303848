import { useMutation } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { notification } from "antd";
import useAccordionStore from "../../../../../../zustand/accordionData";

export const useMutate = ({ getCalcTable1, getCalc, product, hasUpdate }) => {
  let appID = localStorage.getItem("app_id");
  const editStatus = useAccordionStore((state) => state.editStatus);

  const { mutate, isLoading } = useMutation(
    (data) => applyApi.sendApplyV2(data),
    {
      onSuccess: (res) => {
        notification["success"]({
          message: "Success",
          description: "3-qadam yakunlandi!!!",
        });
        editStatus({
          step: 5,
        });
        localStorage.setItem("userStep", 5);
        localStorage.setItem("app_id", res?.m_prod_sales);
      },
      onError: (err) => {
        console.log(err);
        notification["error"]({
          message: "Error",
          description: "Something went wrong!",
        });
      },
    }
  );
  const onSubmit = (data) => {
    console.log(data);

    let payload = {
      productions: data?.productions.map((item, index) => {
        return {
          ...item,
          ps_product_name_1: item.ps_product_name_1.value,
          ps_net_amount_1: getCalcTable1(index),
        };
      }),
      sales: data?.sales?.map((el, index) => {
        return {
          ...el,
          ps_product_name_2: el.ps_product_name_2.value,
          ps_cost_amount_2: getCalc(index),
          ps_total_amount_2: product,
        };
      }),
      sale_programs: data?.sale_program,
      process_step: 4,

      ps_project: Number(appID),
    };
    if (hasUpdate) {
      payload.id = data.id;
    }
    mutate(payload);
  };

  return { onSubmit, isLoading };
};
