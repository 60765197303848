import React, { useState } from "react";
import useCollapse from "react-collapsed";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { Card, CardTitle, CardTopWrapper } from "./style";

export const AccordionComponent = ({
  children,
  title,
  plusIcon,
  factorSOpen,
}) => {
  const [isExpanded, setExpanded] = useState(factorSOpen ? factorSOpen : false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <Card Border={plusIcon}>
      <CardTopWrapper>
        <CardTitle Border={plusIcon}>{title}</CardTitle>
        <button
          {...getToggleProps({
            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
          })}
          className={
            plusIcon ? "arrow-open" : isExpanded ? `arrow-open` : `arrow-close`
          }
        >
          {plusIcon ? (
            isExpanded ? (
              <BiMinus />
            ) : (
              <BsPlus />
            )
          ) : (
            <IoChevronDownCircleOutline />
          )}
        </button>
      </CardTopWrapper>
      <section {...getCollapseProps()}>{children}</section>
    </Card>
  );
};
