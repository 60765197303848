import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const H3 = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${allColors.shriftColor};
  margin: 0;
`;

export const ButtonContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
gap: 10px;

`