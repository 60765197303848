import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
  color: ${allColors.shriftColorOpasity3};
  min-width: ${(props) => props.widthCon ?? '100%'} !important;
`
export const Label = styled.label`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${allColors.shriftColorOpasity3};
`
