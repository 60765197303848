import React, { Fragment } from "react";
import Input from "../financePlan/Input";
import { Table, TableBody, THead } from "../financePlan/style";
import { EmptyProvider } from "./style";

function Table1({ Controller, control, tableList, getCalcTable1 }) {
  return (
    <Fragment>
      <Table>
        <THead>
          <tr>
            <th>№</th>
            <th>Maxsulot turi</th>
            <th>O’lchov birligi</th>
            <th style={{ width: "100px" }}>
              Bir mavsumda ishlab chiqarilgan maxsulot miqdori
            </th>
            <th>Ishlab chiqarish chiqindilar miqdori</th>
            <th>Bir mavsumda ishlab chiqarilgan maxsulot sof miqdori</th>
          </tr>
        </THead>
        <TableBody>
          {tableList?.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item?.ps_product_name_1?.label}</td>
              <td>Tn</td>
              <td>
                <Input
                  required
                  Controller={Controller}
                  control={control}
                  name={`productions[${index}].ps_amount_1`}
                  defaultValue={item?.ps_amount_1}
                />
              </td>
              <td>
                <Input
                  required
                  Controller={Controller}
                  control={control}
                  name={`productions[${index}].ps_waste_amount_1`}
                  defaultValue={item?.ps_waste_amount_1}
                />
              </td>
              <td>{getCalcTable1(index)}</td>
            </tr>
          ))}
        </TableBody>
      </Table>
      {tableList?.length === 0 && (
        <EmptyProvider>
          <p>Ma'lumot mavjud emas</p>
        </EmptyProvider>
      )}
    </Fragment>
  );
}

export default Table1;
