import { useQuery } from "@tanstack/react-query";
import Footer from "components/footer/container";
import Navbar from "components/Navbar";
import { MainContainer } from "components/styles/styleComponetns";
import Banner from "../components/banner";
import Info from "../components/info";
import Statistics from "../components/statistics";
import { Content, Wrapper } from "./style";
import { doGet } from "services/services";

function Landing() {
  const {data: statistic} =  useQuery(["get_earth_info"], () => doGet("/app/landing-page-stats/"));
  return (
    <Wrapper>
      <Navbar isLanding />
      <Content>
        <MainContainer>
          <Banner />
          <Statistics statistic={statistic}/>
          <Info />
        </MainContainer>
      </Content>
      <Footer isLanding />
    </Wrapper>
  );
}

export default Landing;
