import { Col, Row, notification } from "antd";
import InputController from "components/input/inputController";
import {
  Cards,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import { ButtonContainer, H3 } from "../../container/style";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applyApi, technicsApi } from "shared/api/pagesApi";
import ReactLoader from "components/react-loader/ReactLoader";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import jwt_decode from "jwt-decode";

function PersonalCabinetComp() {
  const { control, reset, handleSubmit } = useForm();
  const [isEdit, setIsEdit] = useState(true);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  var decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.accessToken);
  }
  const { isFetching } = useQuery(
    ["get_personal_info"],
    async () => await applyApi.getRequest("/personal-cabinet/info/"),
    {
      onSuccess: (res) => {
        reset(res);
      },
      onError: (err) => {
        if (err?.status_code === 401) {
          navigate("/");
        }
      },
    }
  );
  const { mutate, isLoading } = useMutation(
    (data) =>
      technicsApi.sendDataPatch(
        `/personal-cabinet/update-personals/${decoded.user_id}/`,
        data
      ),
    {
      onSuccess: () => {
        notification["success"]({
          message: "Success",
          description: "Muvaffaqiyatli!!!",
        });
        toggle();
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Xatolik yuz berdi!",
        });
        toggle();
      },
    }
  );
  // /personal-cabinet/update-personals/1/
  const onSubmit = (data) => {
    const payload = {
      email: data?.email,
      phone_number: data?.phone_number,
    };
    mutate(payload);
  };

  const toggle = () => {
    setIsEdit(!isEdit);
  };

  return (
    <MainContainer>
      <MarginContainer>
        <Cards>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            {isFetching ? (
              <ReactLoader propWidth={80} alignItem={"60px"} />
            ) : (
              <Row gutter={[16, 8]}>
                <Col span={24} xl={24} sm={24}>
                  <H3>Shaxsiy kabinet</H3>
                </Col>
                <Col span={24} xl={24} sm={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24} lg={8} md={12} sm={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        label={"STIR"}
                        placeholder={"stir"}
                        disabled={true}
                        name={"stir"}
                      />
                    </Col>
                    <Col span={24} lg={8} md={12} sm={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        label={"Tashkilot nomi"}
                        placeholder={"company name"}
                        disabled={true}
                        name={"company_name"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} xl={24} sm={24}>
                  <Row gutter={[16, 0]}>
                    <Col span={24} lg={8} md={12} sm={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        label={"Raxbari"}
                        placeholder={"director"}
                        disabled={true}
                        name={"director"}
                      />
                    </Col>
                    <Col span={24} lg={8} md={12} sm={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        label={"Telefon raqam"}
                        placeholder={"phone"}
                        disabled={isEdit}
                        name={"phone_number"}
                      />
                    </Col>
                    <Col span={24} lg={8} md={12} sm={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        label={"E-mail"}
                        placeholder={"-"}
                        disabled={isEdit}
                        name={"email"}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} lg={24} sm={24}>
                  <br />
                  {isEdit ? (
                    <Button
                      Text={"Taxrirlash"}
                      Icon={<FiEdit />}
                      TextColor={allColors.whiteColor}
                      backgroundColor={"#FFD95A"}
                      BorderColor={"#FFD95A"}
                      typeButton={"button"}
                      OnClickButton={toggle}
                    />
                  ) : (
                    <ButtonContainer>
                      <Button
                        Text={"Bekor qilish"}
                        TextColor={allColors.whiteColor}
                        backgroundColor={allColors.shriftRed}
                        BorderColor={allColors.shriftRed}
                        typeButton={"button"}
                        OnClickButton={toggle}
                        disabled={isLoading}
                      />
                      <Button
                        Text={"Saqlash"}
                        TextColor={allColors.whiteColor}
                        backgroundColor={allColors.mainColor}
                        BorderColor={allColors.mainColor}
                        typeButton={"submit"}
                        Width={"100px"}
                        disabled={isLoading}
                      />
                    </ButtonContainer>
                  )}
                </Col>
              </Row>
            )}

            <br />
            <br />
          </form>
        </Cards>
      </MarginContainer>
    </MainContainer>
  );
}

export default PersonalCabinetComp;
