import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 80px 0;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: #313131;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    font-size: 22px;
    margin-bottom: 16px;
  }
`;

export const Wraplist = styled.div`
  gap: 42px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 991px) {
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    gap: 22px;
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  gap: 18px;
  display: flex;
  padding: 16px 18px;
  background: #fbfbfb;
  border-radius: 12px;
  align-items: center;
  border: 1.5px solid #ededed;
  .icon {
    display: flex;
    align-items: center;
  }
`;

export const CardInfo = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h3 {
    margin: 0;
    padding: 0;
    color: #60676d;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  & > h4 {
    margin: 0;
    padding: 0;
    color: #454545;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
`;
