import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import { ButtonWrap } from "./style";
import React from "react";

function ButtonComponent({ goBack, form, disabled, isButton = true }) {
  return (
    <ButtonWrap>
      {isButton && (
        <Button
          Width={"140px"}
          Text="Ortga qaytish"
          TextColor={allColors.shriftRed}
          backgroundColor={allColors.whiteColor}
          typeButton="button"
          BorderColor={allColors.shriftRed}
          OnClickButton={goBack}
          disabled={disabled}
        />
      )}
      <Button
        Width={"140px"}
        Text="Davom etish"
        TextColor={allColors.whiteColor}
        backgroundColor={allColors.mainColor}
        form={form}
        typeButton="submit"
        BorderColor={allColors.mainColor}
        disabled={disabled ?? null}
      />
    </ButtonWrap>
  );
}

export default ButtonComponent;
