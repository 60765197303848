/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import useAccordionStore from "../../../../../../zustand/accordionData";
import ButtonComponent from "../buttonComponent";
import { PlanContainer, Wrapper } from "../financePlan/style";
import { TitleH4 } from "../style";
import { useMutate } from "./hooks/useMutate";
import { useTotal } from "./hooks/useTotal";
import { TableComponent } from "./tables";

export const FinancePlanV2 = () => {
  const { control, handleSubmit, watch, setValue } = useForm();
  const editStatus = useAccordionStore((state) => state.editStatus);

  const seed_5_1_value = useWatch({
    control,
    name: ["seed_5_1_value"],
  });
  const work_fee_5_2_value = useWatch({
    control,
    name: ["work_fee_5_2_value"],
  });
  const plant_protection_products_5_4_value = useWatch({
    control,
    name: ["plant_protection_products_5_4_value"],
  });
  const chemical_protection_means_5_5_value = useWatch({
    control,
    name: ["chemical_protection_means_5_5_value"],
  });
  const chemical_nutrients_5_6_value = useWatch({
    control,
    name: ["chemical_nutrients_5_6_value"],
  });
  const fuel_lubricants_5_7_value = useWatch({
    control,
    name: ["fuel_lubricants_5_7_value"],
  });
  const technical_materials_5_8_value = useWatch({
    control,
    name: ["technical_materials_5_8_value"],
  });
  const tax_payments_5_9_value = useWatch({
    control,
    name: ["tax_payments_5_9_value"],
  });
  const other_expenses_5_10_value = useWatch({
    control,
    name: ["other_expenses_5_10_value"],
  });

  const ammophos_5_3_1_value = useWatch({
    control,
    name: ["ammophos_5_3_1_value"],
  });
  const ammonium_nitrate_5_3_2_value = useWatch({
    control,
    name: ["ammonium_nitrate_5_3_2_value"],
  });
  const urea_5_3_3_value = useWatch({
    control,
    name: ["urea_5_3_3_value"],
  });
  const potassium_5_3_4_value = useWatch({
    control,
    name: ["potassium_5_3_4_value"],
  });
  const others_5_3_5_value = useWatch({
    control,
    name: ["others_5_3_5_value"],
  });
  const planPropsNew = {
    ammophos_5_3_1_value: ammophos_5_3_1_value,
    ammonium_nitrate_5_3_2_value: ammonium_nitrate_5_3_2_value,
    urea_5_3_3_value: urea_5_3_3_value,
    potassium_5_3_4_value: potassium_5_3_4_value,
    others_5_3_5_value: others_5_3_5_value,
  };

  const { getTotal: getTotalNew } = useTotal({ planProps: planPropsNew });

  const planProps = {
    seed_5_1_value: seed_5_1_value,
    work_fee_5_2_value: work_fee_5_2_value,
    mineral_fertilizers_5_3_value: getTotalNew(),
    plant_protection_products_5_4_value: plant_protection_products_5_4_value,
    chemical_protection_means_5_5_value: chemical_protection_means_5_5_value,
    chemical_nutrients_5_6_value: chemical_nutrients_5_6_value,
    fuel_lubricants_5_7_value: fuel_lubricants_5_7_value,
    technical_materials_5_8_value: technical_materials_5_8_value,
    tax_payments_5_9_value: tax_payments_5_9_value,
    other_expenses_5_10_value: other_expenses_5_10_value,
  };
  const { getTotal } = useTotal({ planProps });

  const { onSubmit, isLoading } = useMutate({ getTotal, getTotalNew });

  function goBack() {
    editStatus({
      step: 5,
    });
    localStorage.setItem("userStep", 5);
  }
  useEffect(() => {
    setValue(
      "net_profit_6_value",
      Number(watch("sales_revenue_4_value")) - Number(getTotal()[0])
    );
  }, [getTotal()[0], watch("sales_revenue_4_value")]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <PlanContainer>
          <TitleH4>1. Moliyaviy xarajat turlari:</TitleH4>
          <TableComponent
            control={control}
            Controller={Controller}
            planProps={planProps}
            total={getTotal()}
            totalNew={getTotalNew()}
          />
        </PlanContainer>
      </Wrapper>
      <ButtonComponent disabled={isLoading} goBack={goBack} />
    </form>
  );
};
