import { allColors } from "components/colors/AllColors";
import React from "react";
import Select from "react-select";
import { Container, Label } from "./style";

const customStyles = {
  menuList: (base) => ({
    ...base,
    transition: "all 4s",
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: "Raleway",
    fontWeight: 600,
    color: allColors.shriftColor + "!important",
    fontSize: "16px",
    zIndex: 10,
  }),
  option: (styles, { data, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isSelected
        ? allColors.selectBgColor
        : allColors.whiteColor,
      ":active": {
        backgroundColor: "#E5E5E5",
      },
      color: isSelected ? allColors.mainColor : data.color,
      ":hover": {
        backgroundColor: allColors.selectBgColor,
      },
      transition: "all .8s",
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: "all 0.4s",
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
  control: (styles) => ({
    ...styles,
    outline: "none",
    border: "none",
    boxShadow: "none",
    backgroundColor: allColors.selectBgColor,
    fontFamily: "Raleway",
    fontWeight: 500,
    color: allColors.shriftColor + "!important",
    fontSize: "16px",
    borderRadius: "8px",
    minHeight: "44px",
    padding: "0 8px",
    minWidth: "100% !important",
  }),
  container: (styles) => ({
    ...styles,
    minWidth: "100%",
    borderRadius: "8px",
  }),
};

export function SelectController({
  options,
  placeholder,
  name,
  label,
  Controller,
  control,
  defaultValue,
  disabled,
  className,
  required = false,
  isMulti = false,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ?? null}
      rules={{ required: required }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: { errors },
      }) => (
        <Container>
          <Label htmlFor={name}>{label}</Label>
          <Select
            id={name}
            onChange={onChange ?? null}
            options={options ?? []}
            placeholder={placeholder || "Kiriting"}
            name={name ?? ""}
            value={value}
            inputRef={ref}
            styles={customStyles}
            isDisabled={disabled}
            className={className}
            defaultMenuIsOpen={defaultValue ?? null}
            isMulti={isMulti}
            components={{
              IndicatorSeparator: () => null,
            }}
            error={!!errors?.title}
            // isDisabled={selectName.data ? false : true}
          />
        </Container>
      )}
    />
  );
}
