import React, { useState } from "react";
import { ReactComponent as IconArrow } from "assets/images/landing/arrow.svg";
import {
  Box,
  Title,
  BoxText,
  BoxTitle,
  Wrapper,
  WrapBoxs,
  BoxHeader,
  WrapContact,
  UlContact,
  BoxContactTitle,
} from "./style";
import { Modal } from "antd";
import { baseUrl } from "services/baseUrl";

const Info = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      const element = document.getElementById("company-video");
      const src = element.getAttribute("src");
      element.setAttribute("src", src);
    }
  };

  return (
    <Wrapper>
      <Title>Kerakli ma’lumotlar</Title>
      <WrapBoxs>
        <Box onClick={toggle}>
          <BoxHeader>
            <BoxTitle>Video qo’llanma</BoxTitle>
            <IconArrow />
          </BoxHeader>
          <BoxText>
            Qishloq xo‘jaligi korxonalariga mahsulot yetishtirish va qishloq
            xo‘jaligi eksportini rivojlantirish uchun qarz ajratishga onlayn
            ariza berish to‘g‘risida ma’lumot olishingiz mumkin
          </BoxText>
          <Modal
            width={1000}
            footer={null}
            open={isModalOpen}
            onCancel={toggle}
            style={{
              userSelect: "none",
            }}
          >
            <iframe
              id="company-video"
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/G0fnAyBUUFg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{
                marginTop: 40,
              }}
            />
          </Modal>
        </Box>
        <Box>
          <a
            target="_blank"
            rel="noreferrer"
            href={ baseUrl+ "/static/files/uzagro.pdf"}
          >
            <BoxHeader>
              <BoxTitle>Xosil yetishtirish uchun qarz berish tartibi</BoxTitle>
              <IconArrow />
            </BoxHeader>
            <BoxText>
              Qishloq xo‘jaligi korxonalariga qishloq xo‘jaligi ekinlarini
              yetishtirish uchun zarur bo‘lgan moddiy-texnik resurslar, shu
              jumladan urug‘lik va ko‘chatlarni yetkazib berish hamda qishloq
              xo‘jaligi xizmatlarini ko‘rsatish tartibi bilan tanishib
              chiqishingiz mumkin.
            </BoxText>
          </a>
        </Box>
        <Box>
          <a
            target="_blank"
            rel="noreferrer"
            href={baseUrl+ "/static/files/gardening.pdf"}
          >
            <BoxHeader>
              <BoxTitle>Aylanma mablag‘ uchun qarz berish tartibi</BoxTitle>
              <IconArrow />
            </BoxHeader>
            <BoxText>
              Qayta ishlovchi, saqlovchi va eksport qiluvchi korxonalar
              tomonidan yetishtirilgan qishloq xo‘jaligi ekinlarini xarid qilish
              uchun talab etiladigan aylanma mablag‘larini to‘ldirish uchun qarz
              mablag‘lari ajratish tartibi bilan tanishib chiqishingiz mumkin.
            </BoxText>
          </a>
        </Box>
        <Box>
          <BoxTitle>Texnik qo’llab quvvatlash</BoxTitle>
          <WrapContact>
            <div>
              <BoxContactTitle>Telefon raqam:</BoxContactTitle>
              <UlContact>
                <li>
                  •{" "}
                  <a href="tel:+998555008008" rel="noreferrer">
                    +998 55 500 80 08
                  </a>
                </li>
                <li>• 1342</li>
              </UlContact>
            </div>
            <div>
              <BoxContactTitle>Telegram:</BoxContactTitle>
              <UlContact>
                <li>
                  •{" "}
                  <a
                    href="https://t.me/AgroSupportBot"
                    rel="noreferrer"
                    target="_blank"
                  >
                    @AgroSupportBot
                  </a>
                </li>
              </UlContact>
            </div>
            <div>
              <BoxContactTitle>Email:</BoxContactTitle>
              <UlContact>
                <li>
                  • <a href="mailto:agrokredit@agro.uz">agrokredit@agro.uz</a>
                </li>
              </UlContact>
            </div>
          </WrapContact>
        </Box>
      </WrapBoxs>
    </Wrapper>
  );
};

export default Info;
