import { Col, notification, Row } from "antd";
import InputController from "components/input/inputController";
import {
  Cards,
  H1,
  H3,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import React, { useEffect, useState } from "react";
import { ContainerAct, FileContainer, Label, Wrapper } from "./style";
import { Controller, useForm, useWatch } from "react-hook-form";
import Buttons from "../components/Buttons";
import { SelectController } from "components/select/selectController";
import { useNavigate } from "react-router-dom";
// import CalcComponent from "../components/calculator";
// import ReactLoader from 'components/react-loader/ReactLoader'
import { applyApi, technicsApi } from "shared/api/pagesApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import FileUploadForApply from "../components/fileUpload";
import PhotoInfo from "../components/fileView/photoInfo";

const optionsSel = [
  {
    label: "Aylanma mablag' to'ldirish uchun",
    value: "BUDGET",
  },
  {
    label: "Hosil yetishtirish uchun",
    value: "HARVEST",
  },
];
function Apply() {
  const navigate = useNavigate();
  const {
    // register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [foundersCharter, setFoundersCharter] = useState(null);
  const [foundersCharterId, setFoundersCharterId] = useState(null);
  const [openFile, setOpenFile] = useState(false);
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [hasUpdate, setHasUpdate] = useState(true);
  const [company_charter_file, setCompany_charter_file] = useState(null);
  const [company_charter_id, setCompany_charter_id] = useState(null);
  const [letter_id, setLetter_id] = useState(null);
  const [letter_file, setLetter_file] = useState(null);
  const [stirInfo, setStirInfo] = useState({
    success: false,
    error: false,
    data: null,
  });
  const regionId = useWatch({
    control,
    name: "region",
  });
  const { mutate, isLoading } = useMutation(
    (data) => applyApi.sendApply(data),
    {
      onSuccess: (res) => {
        localStorage.setItem("app_id", JSON.stringify(res?.a_application));
        localStorage.setItem(
          "application_id",
          JSON.stringify(res?.a_application)
        );
        localStorage.setItem("userStep", JSON.stringify(2));
        notification["success"]({
          message: "Success",
          description: "Ariza Muvaffaqiyatli yaratildi",
        });
        navigate("/ms/business-plan");
      },
      onError: (err) => {
        notification["error"]({
          message: "Error",
          description: "Xatolik yuz berdi!",
        });
        if (err?.status_code === 401) {
          console.log(err);
          navigate("/");
        }
      },
    }
  );

  async function OnSubmit(data) {
    const formData = new FormData();
    formData.append("company_name", data.company_name);
    formData.append("legal_address", data.legal_address);
    formData.append("region", data.region?.value);
    formData.append("district", data.district?.value);
    formData.append("debt", data.debt);
    formData.append("term", data.term);
    formData.append("phone_number", data.phone_number);
    formData.append("email", data.email);
    formData.append("debt_type", data.debt_type.value);
    formData.append("stir", data.stir);
    formData.append("process_step", 1);
    // formData.append('business_plan', businessPlan)
    formData.append("letter_id", letter_id);
    formData.append("company_charter_id", company_charter_id);
    if (hasUpdate) {
      formData.append("id", data?.id);
    }
    if (openFile) {
      formData.append("founders_charter_id", foundersCharterId);
    }
    localStorage.setItem("dep_type", data.debt_type.value);
    mutate(formData);
  }
  useQuery(
    ["get_stirInfo"],
    async () => await applyApi.getRequest("/app/retrieve-app/"),
    {
      onSuccess: (res) => {
        if (res) {
          setStirInfo((prev) => {
            return {
              ...prev,
              success: true,
              error: false,
              data: res,
            };
          });
        }
      },
      onError: (err) => {
        setStirInfo((prev) => {
          return {
            ...prev,
            success: false,
            error: true,
          };
        });
        if (err.status_code === 401) {
          navigate("/");
        }
      },
      enabled: !hasUpdate ? true : false,
    }
  );
  useQuery(
    ["get_main_apply_update"],
    async () => await applyApi.getRequest(`/app/related-data?process_step=1`),
    {
      onSuccess: (res) => {
        if (res) {
          reset({
            ...res,
            debt_type: optionsSel.find((item) => item.value === res.debt_type),
          });
          setHasUpdate(true);
          setCompany_charter_file(res?.company_charter);
          setCompany_charter_id(res?.company_charter?.id);
          setLetter_file(res?.letter);
          setLetter_id(res?.letter?.id);
          setFoundersCharter(res?.founders_charter);
          setFoundersCharterId(res?.founders_charter?.id);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
        if (err.status_code === 401) {
          navigate("/");
        }
      },
      // enabled:
      //   parseInt(getStep) === 1
      //     ? true
      //     : false,
    }
  );

  useQuery(
    ["get_region_list"],
    async () => await applyApi.getRequest("/tools/region/"),
    {
      onSuccess: (res) => {
        setRegion(res);
      },
      onError: (err) => {
        setRegion([]);
      },
    }
  );
  useQuery(
    ["get_district_list", regionId],
    async () =>
      await applyApi.getRequest(`/tools/district/${regionId?.value}/`),
    {
      onSuccess: (res) => {
        setDistrict(res);
      },
      onError: () => {
        setDistrict([]);
      },
      enabled: regionId ? true : false,
    }
  );
  async function deleteFoto({ setstate, id, setfileid }) {
    await technicsApi.deleteData(`/file/delete/${id}/`);

    setfileid(null);
    setstate(null);
  }
  useEffect(() => {
    if (stirInfo.success) {
      reset({
        ...stirInfo.data,
      });
    }
  }, [stirInfo.data, stirInfo.success]);
  useEffect(() => {
    if (watch("debt") >= 1000000000) {
      setOpenFile(true);
    } else setOpenFile(false);
  }, [watch("debt")]);
  return (
    <MainContainer>
      <MarginContainer>
        <H1>Kredit olish uchun online ariza berish</H1>
        <ContainerAct>
          <Row gutter={[16, 16]}>
            <Col sm={24} lg={24} xl={24}>
              <form onSubmit={handleSubmit(OnSubmit)}>
                <Cards>
                  <H3>Foydalanuvchi malumotlari</H3>
                  <Row gutter={16}>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"INN"}
                        placeholder="stir"
                        Controller={Controller}
                        control={control}
                        name={"stir"}
                        inputType={"number"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("stir") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("stir") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"Korxona nomi"}
                        placeholder="nomi"
                        Controller={Controller}
                        control={control}
                        name={"company_name"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("company_name") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("company_name") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        label={"Yuridik manzili"}
                        placeholder="manzil"
                        Controller={Controller}
                        control={control}
                        name={"legal_address"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("legal_address") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("legal_address") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      {/* <InputController
                        Controller={Controller}
                        control={control}
                        name={'region'}
                        label={'Viloyat'}
                        placeholder="viloyat"
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty('region') &&
                          'input-error'
                        }
                      /> */}
                      <SelectController
                        control={control}
                        Controller={Controller}
                        name={"region"}
                        label={"Viloyat"}
                        placeholder="Tanlang"
                        options={region}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("region") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("region") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <SelectController
                        control={control}
                        Controller={Controller}
                        name={"district"}
                        label={"Tuman"}
                        placeholder="Tanlang"
                        options={district}
                        required
                        disabled={district.length === 0 ? true : false}
                        className={
                          errors &&
                          errors?.hasOwnProperty("district") &&
                          "input-error"
                        }
                      />

                      {errors && errors?.hasOwnProperty("district") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"debt"}
                        label={"Qarz summasi, (so`m)"}
                        placeholder="summa"
                        inputType={"number"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("debt") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("debt") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"term"}
                        label={"Muddati, (max: 12oy)"}
                        placeholder="oy"
                        inputType={"number"}
                        required
                        max={12}
                        className={
                          errors &&
                          errors?.hasOwnProperty("term") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("term") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <SelectController
                        label={"Qarz turi"}
                        placeholder={"qarz turi"}
                        options={optionsSel}
                        Controller={Controller}
                        control={control}
                        name="debt_type"
                      />
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"phone_number"}
                        label={"Telefon raqam"}
                        placeholder="telefon"
                        inputType={"number"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("phone_number") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("phone_number") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col lg={8} md={12} sm={24} xs={24}>
                      <InputController
                        Controller={Controller}
                        control={control}
                        name={"email"}
                        label={"E-mail"}
                        placeholder="email"
                        inputType={"email"}
                        required
                        className={
                          errors &&
                          errors?.hasOwnProperty("email") &&
                          "input-error"
                        }
                      />
                      {errors && errors?.hasOwnProperty("email") && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                  </Row>
                  <FileContainer>
                    <H3>Xujjatlarni yuklang</H3>
                    <Row gutter={[16, 16]} className="mt-3">
                      <Col lg={8} md={12} span={24}>
                        <Wrapper>
                          {!letter_id ? (
                            <FileUploadForApply
                              setTechnicsFoto={setLetter_file}
                              label="Tuman xokimining tavsiya xati"
                              setFileId={setLetter_id}
                              fileId={letter_id}
                              nameId="latter_id"
                            />
                          ) : (
                            <>
                              <Label>Tuman xokimining tavsiya xati</Label>
                              <PhotoInfo
                                file={letter_file}
                                deleteFile={() =>
                                  deleteFoto({
                                    setstate: setLetter_file,
                                    id: letter_id,
                                    setfileid: setLetter_id,
                                  })
                                }
                              />
                            </>
                          )}
                        </Wrapper>
                      </Col>
                      <Col lg={8} md={12} span={24}>
                        <Wrapper>
                          {!company_charter_id ? (
                            <FileUploadForApply
                              setTechnicsFoto={setCompany_charter_file}
                              label="Korxona ustavi"
                              setFileId={setCompany_charter_id}
                              fileId={company_charter_id}
                              nameId="company_charter_id"
                            />
                          ) : (
                            <>
                              <Label>Korxona ustavi</Label>
                              <PhotoInfo
                                file={company_charter_file}
                                deleteFile={() =>
                                  deleteFoto({
                                    setstate: setCompany_charter_file,
                                    id: company_charter_id,
                                    setfileid: setCompany_charter_id,
                                  })
                                }
                              />
                            </>
                          )}
                        </Wrapper>
                      </Col>
                      {openFile && (
                        <Col lg={8} md={12} span={24}>
                          <Wrapper>
                            {!foundersCharterId ? (
                              <FileUploadForApply
                                setTechnicsFoto={setFoundersCharter}
                                label="Taʼsischilar bayonnomasi"
                                setFileId={setFoundersCharterId}
                                fileId={foundersCharterId}
                                nameId="founders_charter_id"
                              />
                            ) : (
                              <>
                                <Label>Taʼsischilar bayonnomasi</Label>
                                <PhotoInfo
                                  file={foundersCharter}
                                  deleteFile={() =>
                                    deleteFoto({
                                      setstate: setFoundersCharter,
                                      id: foundersCharterId,
                                      setfileid: setFoundersCharterId,
                                    })
                                  }
                                />
                              </>
                            )}
                          </Wrapper>
                        </Col>
                      )}
                    </Row>
                  </FileContainer>
                  <Buttons disabled={isLoading} />
                </Cards>
              </form>
            </Col>
            {/* <Col span={24} sm={24} lg={8} xl={6}>
              <CardsCalc>
                <H3>Hisob-kitob</H3>
                input tagini olib tashlash !!!
                <CalcComponent />
              </CardsCalc>
            </Col> */}
          </Row>
        </ContainerAct>
      </MarginContainer>
    </MainContainer>
  );
}

export default Apply;
