import React from "react";
import { ApplyContent, ListItem, List } from "./style";
function PersonalInfo({ data }) {
  return (
    <ApplyContent>
      <List>
        <ListItem>
          <span>Familiya</span>
          <span>{data?.first_name}</span>
        </ListItem>
        <ListItem>
          <span>Ismi</span>
          <span>{data?.last_name}</span>
        </ListItem>
        <ListItem>
          <span>Sharifi</span>
          <span>{data?.middle_name}</span>
        </ListItem>
        <ListItem>
          <span>Korxona nomi</span>
          <span>{data?.company_name}</span>
        </ListItem>
        <ListItem>
          <span>Viloyat</span>
          <span>{data?.region}</span>
        </ListItem>
        <ListItem>
          <span>Tuman</span>
          <span>{data?.district}</span>
        </ListItem>
        <ListItem>
          <span>Telefon</span>
          <span>{data?.phone_number ?? "-"}</span>
        </ListItem>
        <ListItem>
          <span>Email</span>
          <span>{data?.email ?? "-"}</span>
        </ListItem>
      </List>
    </ApplyContent>
  );
}

export default PersonalInfo;
