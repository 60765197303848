import create from "zustand";

const useAccordionStore = create((set) => ({
  accordionData: {
    status: false,
    step: 2,
    debt_type: null,
  },
  isCabinet: null,
  editStatus: (payload) =>
    set((state) => ({ accordionData: { ...state.accordionData, ...payload } })),
  editIsCabinet: (payload) => set(() => ({ isCabinet: payload })),
}));
export default useAccordionStore;
