import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { cancelApplyApi } from "shared/api/pagesApi";
import { notification } from "antd";
import { Modal } from "antd";

export default function useHooks() {
  const { confirm } = Modal;

  const navigate = useNavigate();
  const { mutate } = useMutation((data) => cancelApplyApi.cancelApply(data), {
    onSuccess: () => {
      notification["warning"]({
        message: "Warning",
        description: "Ariza Muvaffaqiyatli bekor qilindi",
      });
      navigate("/ms/ariza");
    },
    onError: (err) => {
      notification["error"]({
        message: "Error",
        description: "Xatolik yuz berdi!",
      });
      if (err?.status_code === 401) {
        navigate("/");
      }
    },
  });

  const onCancelButton = () => {
    const applyId = localStorage.getItem("application_id");
    confirm({
      title: "Arizani bekor qilmoqchimisiz?",
      okType: "primary",
      okButtonProps: {
        danger: true,
      },
      onOk() {
        mutate(applyId);
      },
      okText: "Ha",
      cancelText: "Bekor qilish",
    });
  };
  return {
    onCancelButton,
  };
}
