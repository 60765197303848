import React from 'react'
import PhotoInfo from './photoInfo'
import { FileContainer, Label } from './style'

function FileInfo({ label, data }) {
  // console.log(data, 'dadada')
  return (
    <FileContainer>
      <Label>{label}</Label>
      <PhotoInfo file={data} />
    </FileContainer>
  )
}

export default FileInfo
