import axios from "axios";
import { baseUrl } from "./baseUrl";
const GlobalUrl = baseUrl;
let getToken = JSON.parse(localStorage.getItem("userInfo"));
const accessToken = getToken?.accessToken;

export async function getAxios(url) {
  return await axios
    .get(GlobalUrl + url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
      if (err.status_code === 401) {
        window.location.href = "/";
      }
    });
}
export async function getAxios1(url, data) {
  return await axios
    .get(GlobalUrl + url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params: { ...data },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.status_code === 401) {
        window.location.href = "/";
      }
    });
}
export async function doGet(url) {
  return await axios.get(GlobalUrl + url).then((res) => {
    return res.data;
  });
}
export const doDelete = async (url, data) => {
  return await axios
    .delete(
      GlobalUrl + url,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.status_code === 401) {
        window.location.href = "/";
      }
    });
};
export const doPost = async (url, data) => {
  return await axios
    .post(
      GlobalUrl + url,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.status_code === 401) {
        window.location.href = "/";
      }
    });
};
export const doGetAuth = async (url, setState) => {
  setState((prev) => {
    return {
      ...prev,
      loading: true,
    };
  });
  return await axios
    .get(GlobalUrl + url)
    .then((res) => {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          success: true,
          error: false,
          data: res?.data,
        };
      });
    })
    .catch((e) => {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          success: false,
          error: true,
          data: e?.response,
        };
      });
      if (e.status_code) {
        window.location.href = "/";
      }
    });
};
export const doGetPayment = async (url, setState) => {
  setState((prev) => {
    return {
      ...prev,
      loading: true,
    };
  });
  return await axios
    .get(GlobalUrl + url, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          success: true,
          error: false,
          data: res?.data,
        };
      });
      console.log(res, "resss");
    })
    .catch((e) => {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          success: false,
          error: true,
          data: e?.response,
        };
      });
    });
};
