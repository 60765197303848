import { useMutation } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

export const useMutate = ({ getTotal, getTotalNew }) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(
    (data) => applyApi.sendApplyV2(data),
    {
      onSuccess: () => {
        notification["success"]({
          message: "Success",
          description: "5-qadam yakunlandi!!!",
        });
        navigate("/apply-success");
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Something went wrong!",
        });
      },
    }
  );

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append(
      "crop_area_1",
      JSON.stringify({
        crop_area_1: "ga",
        crop_area_1_amount: data.crop_area_1_amount,
        crop_area_1_value: null,
      })
    );
    formData.append(
      "productivity_2",
      JSON.stringify({
        productivity_2: "st/ga",
        productivity_2_amount: data.productivity_2_amount,
        productivity_2_value: null,
      })
    );
    formData.append(
      "gross_yield_3",
      JSON.stringify({
        gross_yield_3: "tonna",
        gross_yield_3_amount: data.gross_yield_3_amount,
        gross_yield_3_value: null,
      })
    );
    formData.append(
      "sales_revenue_4",
      JSON.stringify({
        sales_revenue_4: "ming so'm",
        sales_revenue_4_amount: null,
        sales_revenue_4_value: data.sales_revenue_4_value,
      })
    );
    formData.append(
      "total_cost_5",
      JSON.stringify({
        total_cost_5: "ming so'm",
        total_cost_5_amount: null,
        total_cost_5_value: getTotal()[0],
      })
    );

    formData.append(
      "seed_5_1",
      JSON.stringify({
        seed_5_1: "ming so'm",
        seed_5_1_amount: data.seed_5_1_amount,
        seed_5_1_value: data.seed_5_1_value,
      })
    );
    formData.append(
      "work_fee_5_2",
      JSON.stringify({
        work_fee_5_2: "ming so'm",
        work_fee_5_2_amount: null,
        work_fee_5_2_value: data.work_fee_5_2_value,
      })
    );
    formData.append(
      "mineral_fertilizers_5_3",
      JSON.stringify({
        mineral_fertilizers_5_3: "ming so'm",
        mineral_fertilizers_5_3_amount: null,
        mineral_fertilizers_5_3_value: getTotalNew()[0],
      })
    );
    formData.append(
      "ammophos_5_3_1",
      JSON.stringify({
        ammophos_5_3_1: "ming so'm",
        ammophos_5_3_1_amount: data.ammophos_5_3_1_amount,
        ammophos_5_3_1_value: data.ammophos_5_3_1_value,
      })
    );
    formData.append(
      "ammonium_nitrate_5_3_2",
      JSON.stringify({
        ammonium_nitrate_5_3_2: "ming so'm",
        ammonium_nitrate_5_3_2_amount: data.ammonium_nitrate_5_3_2_amount,
        ammonium_nitrate_5_3_2_value: data.ammonium_nitrate_5_3_2_value,
      })
    );
    formData.append(
      "urea_5_3_3",
      JSON.stringify({
        urea_5_3_3: "ming so'm",
        urea_5_3_3_amount: data.urea_5_3_3_amount,
        urea_5_3_3_value: data.urea_5_3_3_value,
      })
    );
    formData.append(
      "potassium_5_3_4",
      JSON.stringify({
        potassium_5_3_4: "ming so'm",
        potassium_5_3_4_amount: data.potassium_5_3_4_amount,
        potassium_5_3_4_value: data.potassium_5_3_4_value,
      })
    );
    formData.append(
      "others_5_3_5",
      JSON.stringify({
        others_5_3_5: "ming so'm",
        others_5_3_5_amount: data.others_5_3_5_amount,
        others_5_3_5_value: data.others_5_3_5_value,
      })
    );
    formData.append(
      "plant_protection_products_5_4",
      JSON.stringify({
        plant_protection_products_5_4: "ming so'm",
        plant_protection_products_5_4_amount:
          data.plant_protection_products_5_4_amount,
        plant_protection_products_5_4_value:
          data.plant_protection_products_5_4_value,
      })
    );
    formData.append(
      "chemical_protection_means_5_5",
      JSON.stringify({
        chemical_protection_means_5_5: "ming so'm",
        chemical_protection_means_5_5_amount:
          data.chemical_protection_means_5_5_amount,
        chemical_protection_means_5_5_value:
          data.chemical_protection_means_5_5_value,
      })
    );
    formData.append(
      "chemical_nutrients_5_6",
      JSON.stringify({
        chemical_nutrients_5_6: "ming so'm",
        chemical_nutrients_5_6_amount: data.chemical_nutrients_5_6_amount,
        chemical_nutrients_5_6_value: data.chemical_nutrients_5_6_value,
      })
    );
    formData.append(
      "fuel_lubricants_5_7",
      JSON.stringify({
        fuel_lubricants_5_7: "ming so'm",
        fuel_lubricants_5_7_amount: data.fuel_lubricants_5_7_amount,
        fuel_lubricants_5_7_value: data.fuel_lubricants_5_7_value,
      })
    );
    formData.append(
      "technical_materials_5_8",
      JSON.stringify({
        technical_materials_5_8: "ming so'm",
        technical_materials_5_8_amount: data.technical_materials_5_8_amount,
        technical_materials_5_8_value: data.technical_materials_5_8_value,
      })
    );
    formData.append(
      "tax_payments_5_9",
      JSON.stringify({
        tax_payments_5_9: "ming so'm",
        tax_payments_5_9_amount: data.tax_payments_5_9_amount,
        tax_payments_5_9_value: data.tax_payments_5_9_value,
      })
    );

    formData.append(
      "other_expenses_5_10",
      JSON.stringify({
        other_expenses_5_10: "ming so'm",
        other_expenses_5_10_amount: data.other_expenses_5_10_amount,
        other_expenses_5_10_value: data.other_expenses_5_10_value,
      })
    );
    formData.append(
      "net_profit_6",
      JSON.stringify({
        net_profit_6: "ming so'm",
        net_profit_6_amount: null,
        net_profit_6_value: data.net_profit_6_value,
      })
    );
    formData.append("fp_marketing", localStorage.getItem("app_id"));
    formData.append("process_step", 6);
    mutateAsync(formData);
  };
  return { onSubmit, isLoading };
};
