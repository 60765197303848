import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import React from "react";
import { ButtonContainer } from "./style";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Buttons({ disabled }) {
  const navigate = useNavigate();
  const location = useLocation();
  const OnClick = () => {
    if (location.pathname === "/agro-leasing/user-info") {
      localStorage.clear();
      navigate("/");
    } else navigate(-1);
  };
  return (
    <ButtonContainer>
      <Button
        Width={"140px"}
        Text="Bekor qilish"
        TextColor={allColors.shriftRed}
        BorderColor={allColors.shriftRed}
        backgroundColor={allColors.whiteColor}
        typeButton="button"
        disabled={disabled}
        OnClickButton={OnClick}
      />
      <Button
        Width={"140px"}
        Text="Davom etish"
        TextColor={allColors.whiteColor}
        backgroundColor={allColors.mainColor}
        disabled={disabled}
        typeButton="submit"
      />
    </ButtonContainer>
  );
}

export default Buttons;
