import styled from "styled-components";

export const ButtonWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;
