import { useForm, Controller, useWatch } from "react-hook-form";
import { applyApi, technicsApi } from "shared/api/pagesApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function useHooks() {
  const navigate = useNavigate();
  const form = useForm();
  const [applyTypeRadio, setApplyTypeRadio] = useState(1);
  const regionId = useWatch({
    control: form.control,
    name: "region_for_ofice",
  });
  const { mutate } = useMutation(
    (data) => technicsApi.sendData("/app-l/create/", data),
    {
      onSuccess: (res) => {
        localStorage.setItem("application_l", res?.application_l);
        if (applyTypeRadio === 1) {
          navigate("/agro-leasing/apply-v1");
        } else navigate("/agro-leasing/apply-v2");
      },
      onError: (res) => {
        if (res?.status === 498) {
          if (res?.process_step === 1) {
            notification["warning"]({
              message: "Warning",
              description:
                "Sizda ushbu tur bo'yicha tugatilmagan ariza mavjud, sizni davom etishga o'tkazamiz !",
            });
            localStorage.setItem("application_l", res?.application_l);
            if (applyTypeRadio === 1) {
              navigate("/agro-leasing/apply-v1");
            } else navigate("/agro-leasing/apply-v2");
          } else
            notification["warning"]({
              message: "Warning",
              description:
                "Sizda avval topshirilgan arizaga javob berilmadi,  shu sababli yangi ariza bersihga cheklov o'rnatilgan. Javob olganizdan so'ng yangi ariza topshirishingiz mumkin bo'ladi. Bizga murojjat qilganiz uchun rahmat !",
            });
        }
      },
    }
  );
  const handleNavigate = (data) => {
    const personalInfo = JSON.parse(localStorage.getItem("personal_info"));
    if (applyTypeRadio === 1) {
      localStorage.setItem("debtType", "GARDEN");
      mutate({
        ...personalInfo,
        debt_type: "GARDEN",
        region_proj_office: data?.region_for_ofice.value,
        district_proj_office: data?.district_for_ofice.value,
      });
    } else {
      localStorage.setItem("debtType", "LOGISTICS");
      mutate({
        ...personalInfo,
        debt_type: "LOGISTICS",
      });
    }
  };
  const { data: regions } = useQuery(
    ["get_region_list_leasing_ofice"],
    async () => await applyApi.getRequest("/tools/region/")
  );
  const { data: districts } = useQuery(
    ["get_district_list_leasing_ofice", regionId],
    async () =>
      await applyApi.getRequest(`/tools/district/${regionId?.value}/`),
    {
      enabled: regionId ? true : false,
    }
  );
  return {
    form,
    Controller,
    setApplyTypeRadio,
    applyTypeRadio,
    handleNavigate,
    regions,
    districts,
  };
}
