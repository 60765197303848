import React from "react";
import { ScrollContainer, Table, TableBody, TitleH4, Wrapper } from "./style";
import Input from "../../input/Input";
import { useForm } from "react-hook-form";

function ProjectPasport() {
  const { control } = useForm();
  return (
    <Wrapper>
      <TitleH4 style={{ textAlign: "center" }}>Loyiha pasporti</TitleH4>
      <ScrollContainer>
        <Table>
          <TableBody>
            <tr>
              <td>1</td>
              <td>Loyiha tashabbuskori:</td>
              <td>
                <Input
                  control={control}
                  placeholder={"ООО «АЛИНУР»"}
                  name={"user"}
                  type={"text"}
                />
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Loyihani amalga oshirish manzili:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Loyiha nomi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Umumiy yer maydoni:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Loyihaning umumiy qiymati:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Uskunalar va yuk mashinalarining narxi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Bino va inshootlarning narxi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Aylanma mablag'lar uchun o'z mablag'lari:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Kredit mablag'lari:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>O'tgan yilning moliyaviy yillik aylanmasi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                Joriy moliya yilining oxirgi hisobot sanasidagi haqiqiy
                aylanmasi:
              </td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>Joriy yilning rejalashtirilgan moliyaviy aylanmasi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>Loyihaning qoplanishi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>Mavjud ish joylari:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>15</td>
              <td>Loyiha doirasida yaratilgan yangi ish o‘rinlari:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
            <tr>
              <td>16</td>
              <td>Loyiha boshlanishi:</td>
              <td>
                <Input control={control} name={"user"} type={"text"} />
              </td>
            </tr>
          </TableBody>
        </Table>
      </ScrollContainer>
    </Wrapper>
  );
}

export default ProjectPasport;
