import React from 'react'
import { ButtonStyle } from './buttonStyle'

function Button({
  backgroundColor,
  TextColor,
  Width,
  BorderColor,
  Text,
  OnClickButton,
  disabled,
  typeButton,
  Icon,
  Opacity,
  form,
}) {
  return (
    <ButtonStyle
      backgroundColor={backgroundColor}
      TextColor={TextColor}
      Width={Width}
      BorderColor={BorderColor}
      onClick={OnClickButton}
      disabled={disabled}
      type={typeButton || 'button'}
      Opacity={Opacity}
      form={form}
    >
      {Icon}
      {Text}
    </ButtonStyle>
  )
}

export default Button
