import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: #313131;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    font-size: 22px;
    margin-bottom: 16px;
  }
`;

export const WrapBoxs = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const Box = styled.div`
  padding: 24px;
  cursor: pointer;
  background: #fbfbfb;
  border-radius: 16px;
  border: 1.5px solid #ededed;
  transition: all 0.3s ease-in-out;
  :hover {
    transform: scale(1.01);
  }
`;

export const BoxTitle = styled.h6`
  margin: 0;
  padding: 0;
  color: #08705f;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const BoxText = styled.p`
  margin: 0;
  padding: 0;
  color: #747b81;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const WrapContact = styled.div`
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    gap: 32px;
    margin-top: 16px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    gap: 22px;
    grid-template-columns: 1fr;
  }
`;

export const BoxContactTitle = styled.h6`
  padding: 0;
  color: #313131;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 12px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const UlContact = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    color: #747b81;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    @media (max-width: 991px) {
      font-size: 15px;
    }
  }
  li a {
    color: #747b81;
  }
`;
