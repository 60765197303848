import { MainContainer } from 'components/styles/styleComponetns';
import React from 'react';
import {
  Col,
  Paragraf,
  TitleCard,
  TitleH3,
  TitleSpan,
  Wrapper,
  WrapperCard,
} from '../container/style';
import { ReactComponent as Logo } from 'assets/images/footer/logo.svg';

function FooterInfo({ isLanding }) {
  return (
    <Wrapper isLanding={isLanding}>
      <MainContainer>
        <WrapperCard>
          <Col className='maxWidth1'>
              <Logo className='logo-vazirlik'/>
            <Paragraf>
              Qishloq xo‘jaligiga mo‘ljallangan yer uchastkalarini ijaraga
              berish bo‘yicha materiallarni to‘plash, ko‘rib chiqish va
              vakolatli organlar va tashkilotlar bilan kelishishni amalga
              oshirishga mo‘ljallangan avtomatlashtirilgan axborot tizimi.
            </Paragraf>
          </Col>
          <Col className='maxWidth2'>
            <TitleH3>Bog’dorchilik modullari</TitleH3>
            <TitleCard>
              <div className='flex-div-card'>
                <TitleSpan>Yer maydonlari</TitleSpan>
                <TitleSpan>Ekinlarni joylash</TitleSpan>
                <TitleSpan>Moliyalashtirish</TitleSpan>
              </div>
              <div className='flex-div-card'>
                <TitleSpan>Shartnomalar</TitleSpan>
                <TitleSpan>Agrotexnik tadbirlar</TitleSpan>
              </div>
            </TitleCard>
          </Col>
          <Col className='maxWidth3'>
            <TitleH3>Aloqa</TitleH3>
            <TitleCard>
              <div className='flex-div-card'>
                <TitleSpan>Tel: +998 55 500 80 08</TitleSpan>
                <TitleSpan>Email: agrokredit@agro.uz</TitleSpan>
              </div>
            </TitleCard>
          </Col>
        </WrapperCard>
      </MainContainer>
    </Wrapper>
  );
}

export default FooterInfo;
