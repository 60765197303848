import {
  Cards,
  H3,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import React from "react";
import { ButtonContainer } from "../components/button-components/style";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import { ApplyTypeContainer } from "./style";
import { useNavigate } from "react-router-dom";
import RadioComponent from "./radioBox";
import { Col, Row } from "antd";
import { SelectController } from "components/select/selectController";
import { useHooks } from "./hooks";
import { Hr } from "pages/agro-kredit/biznes-plan/components/collapseComponent/style";

function TypeForApply() {
  const navigate = useNavigate();
  const {
    form,
    Controller,
    applyTypeRadio,
    handleNavigate,
    setApplyTypeRadio,
    regions,
    districts
  } = useHooks();
  // const { region, district } = Hooks();

  return (
    <MainContainer>
      <MarginContainer>
        <Cards>
          <form onSubmit={form.handleSubmit(handleNavigate)}>
            <ApplyTypeContainer>
              <RadioComponent
                label={"Ariza turini tanlang"}
                value={applyTypeRadio}
                setRadioValue={setApplyTypeRadio}
                name={"apply_type"}
                data={[
                  {
                    title: "Intensiv bog' va tokzorlar;",
                    id: 1,
                  },
                  {
                    title: "Kichik agrologistika markazlarini tashkil etish",
                    id: 2,
                  },
                ]}
              />
              <Hr />
              <Row gutter={[16]} style={{ width: "100%" }}>
                <Col span={24} lg={24} md={24}>
                  <H3>Qaysi loyiha ofisiga jo'natasiz</H3>
                </Col>
                <Col span={24} lg={12} md={12}>
                  <SelectController
                    control={form.control}
                    Controller={Controller}
                    name={"region_for_ofice"}
                    label={"Viloyat"}
                    placeholder="Tanlang"
                    options={regions}
                    required
                    className={
                      form.formState.errors &&
                      form.formState.errors?.hasOwnProperty("region_for_ofice") &&
                      "input-error"
                    }
                  />
                  {form.formState.errors &&
                    form.formState.errors?.hasOwnProperty("region_for_ofice") && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} lg={12} md={12}>
                  <SelectController
                    control={form.control}
                    Controller={Controller}
                    name={"district_for_ofice"}
                    label={"Tuman"}
                    placeholder="Tanlang"
                    options={districts}
                    required
                    className={
                      form.formState.errors &&
                      form.formState.errors?.hasOwnProperty("district_for_ofice") &&
                      "input-error"
                    }
                  />
                  {form.formState.errors &&
                    form.formState.errors?.hasOwnProperty("district_for_ofice") && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
              </Row>
            </ApplyTypeContainer>
            <ButtonContainer>
              <Button
                Width={"140px"}
                Text="Bekor qilish"
                TextColor={allColors.shriftRed}
                BorderColor={allColors.shriftRed}
                backgroundColor={allColors.whiteColor}
                typeButton="button"
                OnClickButton={() => navigate(-1)}
              />
              <Button
                Width={"140px"}
                Text="Davom etish"
                TextColor={allColors.whiteColor}
                backgroundColor={allColors.mainColor}
                // OnClickButton={() =>
                //   navigate('/agro-leasing/form-an-application')
                // }

                typeButton="submit"
              />
            </ButtonContainer>
          </form>
        </Cards>
      </MarginContainer>
    </MainContainer>
  );
}

export default TypeForApply;
