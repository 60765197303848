import { Col, Row } from "antd";
import Button from "components/Button";
import { allColors } from "components/colors/AllColors";
import DatePickerComponent from "components/datePicker";
import InputController from "components/input/inputController";
import RadioComponent from "components/input/radioComponent";
import { SelectController } from "components/select/selectController";
import React, { Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { Cards, Hr, TitleH4 } from "../style";

function CAbroad({
  control,
  Controller,
  errors,
  options,
  setForeignSale,
  foreignSale,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "foreign_marketing",
  });
  function addAbroad() {
    append({
      fm_partner_name: null,
      fm_country: null,
      fm_contract_num: null,
      fm_product_name: null,
      fm_contract_date: null,
      fm_contract_amount: null,
    });
  }
  // console.log(fields, "foreignSale");
  return (
    <Cards className="m-0">
      <TitleH4 className="m-0">C. Chet elda</TitleH4>
      <RadioComponent
        label={"Sotuv bormi?"}
        name={"sales_within_foreign"}
        setRadioValue={setForeignSale}
        value={foreignSale}
      />
      {foreignSale ? (
        <Row gutter={[16]} className="w-100">
          {fields.map((item, index) => {
            return (
              <Fragment key={item.id}>
                {index > 0 ? (
                  <Col span={24} xl={24}>
                    <Hr />
                  </Col>
                ) : null}
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_partner_name`}
                    label={`${index + 1}. Xamkor nomi`}
                    required
                    defaultValue={item.fm_partner_name}
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_partner_name &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.foreign_marketing?.[index]?.fm_partner_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_country`}
                    label={"Davlat nomi"}
                    defaultValue={item.fm_country}
                    required
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_country &&
                      "input-error"
                    }
                  />
                  {errors && errors?.foreign_marketing?.[index]?.fm_country && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_contract_num`}
                    label={"Kontrakt raqami"}
                    required
                    inputType={"text"}
                    defaultValue={item.fm_contract_num}
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_contract_num &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.foreign_marketing?.[index]?.fm_contract_num && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <SelectController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_product_name`}
                    label={"Tovar nomi"}
                    options={options}
                    // defaultValue={item.fm_product_name}
                    placeholder="Tanlang"
                    required
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_product_name &&
                      "input-error"
                    }
                  />

                  {/* <InputController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_product_name`}
                    label={"Tovar nomi"}
                    defaultValue={item.fm_product_name}
                    required
                    inputType="text"
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_product_name &&
                      "input-error"
                    }
                  /> */}
                  {errors &&
                    errors?.foreign_marketing?.[index]?.fm_product_name && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <DatePickerComponent
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_contract_date`}
                    label={"Kontrakt sanasi"}
                    defaultValue={item?.fm_contract_date}
                  />
                </Col>
                <Col span={24} xl={8} lg={12} sm={12}>
                  <InputController
                    control={control}
                    Controller={Controller}
                    name={`foreign_marketing[${index}].fm_contract_amount`}
                    label={"Kontrakt summasi,($)"}
                    // label yoniga checkbox so'm yoki usd
                    defaultValue={item.fm_contract_amount}
                    inputType={"number"}
                    required
                    className={
                      errors &&
                      errors?.foreign_marketing?.[index]?.fm_contract_amount &&
                      "input-error"
                    }
                  />
                  {errors &&
                    errors?.foreign_marketing?.[index]?.fm_contract_amount && (
                      <span className="error-span">
                        Please enter information!
                      </span>
                    )}
                </Col>
                <Col
                  span={24}
                  xl={8}
                  lg={12}
                  sm={12}
                  style={{ alignSelf: "end", marginTop: "16px" }}
                >
                  {index > 0 ? (
                    <Button
                      Icon={
                        <AiOutlineDelete
                          style={{ fontSize: "20px" }}
                          color={allColors.shriftRed}
                        />
                      }
                      TextColor={allColors.shriftRed}
                      backgroundColor={"rgba(235, 87, 87, 0.1)"}
                      Text="O'chirish"
                      Width="120px"
                      OnClickButton={() => remove(index)}
                    />
                  ) : (
                    <Button
                      Icon={<BsPlus style={{ fontSize: "20px" }} />}
                      TextColor={allColors.mainColor}
                      backgroundColor={"#e6f1ef"}
                      Text="Qo’shish"
                      Width="120px"
                      OnClickButton={addAbroad}
                    />
                  )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
      ) : null}
    </Cards>
  );
}

export default CAbroad;
