import { allColors } from 'components/colors/AllColors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${allColors.whiteColor};
`;

export const Content = styled.main`
  flex-grow: 1;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  background-color: ${allColors.whiteColor};
`;
