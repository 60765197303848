import Api from '../index';

class Retrieve extends Api {
  gardenRetrieve(id) {
    return this.execute('get', `/app-l/garden-files-retrieve/${id}`);
  }
  logisRetrieve(id) {
    return this.execute('get', `/app-l/logistic-files-retrieve/${id}`);
  }
}

export default Retrieve;
