import Auth from "../api/modules/auth";
import Application from "./modules/application";
import Apply from "./modules/apply";
import DebtType from "./modules/debt-type";
import Retrieve from "./modules/retrieve";
import Technics from "./modules/technics";
import ApplyCancel from "./modules/applyCancel";

const authApi = new Auth();
const applyApi = new Apply();
const technicsApi = new Technics();
const applicationApi = new Application();
const gardenRetrieveApi = new Retrieve();
const debtTypeApi = new DebtType();
const cancelApplyApi = new ApplyCancel();

export {
  authApi,
  applyApi,
  technicsApi,
  applicationApi,
  gardenRetrieveApi,
  debtTypeApi,
  cancelApplyApi,
};
