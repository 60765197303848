import React from 'react';
import { Garden } from './components/garden';
import Logistics from './components/logistics';

export const ProjectOffice = ({ current, prev, next, message }) => {
  const debtType = localStorage.getItem('debtType');
  return (
    <>
      {debtType === 'GARDEN' ? (
        <Garden current={current} prev={prev} next={next} message={message} />
      ) : (
        <Logistics
          current={current}
          prev={prev}
          next={next}
          message={message}
        />
      )}
    </>
  );
};
