import React from "react";
import Input from "../financePlan/Input";
import { Table, TableBody, THead } from "../financePlan/style";

function Table3({ Controller, control, tableList }) {
  return (
    <Table>
      <THead>
        <tr>
          <th>№</th>
          <th>Nomi</th>
          <th>Miqdori</th>
          <th>Birligi</th>
        </tr>
      </THead>
      <TableBody>
        {tableList?.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.ps_name_3}</td>
            <td>
              <Input
                name={`sale_program[${index}].ps_amount_3`}
                Controller={Controller}
                control={control}
                required
              />
            </td>
            <td>{item.ps_unit_3}</td>
          </tr>
        ))}
      </TableBody>
    </Table>
  );
}
export default Table3;
