import React from "react";
import { MainContainer } from "components/styles/styleComponetns";
import jwt_decode from "jwt-decode";
import { H1, TabMenuContainer, TabMenu, TitleWrapper, HR } from "./style";

function MainTitle({ tabValue, setTabValue }) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  var decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.accessToken);
  }

  return (
    <TitleWrapper>
      <MainContainer>
        <H1>👋 Salom &nbsp;<span className="user-name">{decoded?.first_name}</span></H1>
        <TabMenuContainer>
          <TabMenu
            className={tabValue === 1 && "active"}
            onClick={() => setTabValue(1)}
          >
            Shaxsiy kabinet
            <span></span>
          </TabMenu>
          <TabMenu
            // disabled
            className={tabValue === 2 && "active"}
            onClick={() => setTabValue(2)}
          >
            Arizalar
            <span></span>
          </TabMenu>
          <TabMenu
            disabled
            className={tabValue === 3 && "active"}
            onClick={() => setTabValue(3)}
          >
            Shartnomalar
            <span></span>
          </TabMenu>
          <TabMenu
            disabled
            className={tabValue === 4 && "active"}
            onClick={() => setTabValue(4)}
          >
            Shikoyatlar
            <span></span>
          </TabMenu>
        </TabMenuContainer>
        <HR />
      </MainContainer>
    </TitleWrapper>
  );
}

export default MainTitle;
