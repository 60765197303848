import { Col, notification, Row } from "antd";
import InputController from "components/input/inputController";
import React, { useEffect, useState } from "react";
import { Form, TitleH4 } from "../style";
import { Controller, useForm } from "react-hook-form";
import TextAreaComponent from "components/input/textArea";
import ButtonComponent from "../buttonComponent";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { useNavigate } from "react-router-dom";

function ProjectCollapse() {
  let debtType = localStorage.getItem("dep_type");
  const navigate = useNavigate();
  const editStatus = useAccordionStore((state) => state.editStatus);
  const [hasUpdate, setHasUpdate] = useState(true);
  const accordionData = useAccordionStore((state) => state.accordionData);

  const getStep = localStorage.getItem("userStep");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const { mutate, isLoading } = useMutation(
    (data) =>
      debtType === "HARVEST"
        ? applyApi.sendApplyV2(data)
        : applyApi.sendApply(data),
    {
      onSuccess: (res) => {
        notification["success"]({
          message: "Success",
          description: "2-qadam yakunlandi!!!",
        });
        editStatus({
          step: 4,
        });
        localStorage.setItem("userStep", 4);
        if ("BUDGET" === localStorage.getItem("dep_type"))
          localStorage.setItem("app_id", res?.t_project);
        else localStorage.setItem("app_id", res?.ps_project);
      },
      onError: (error) => {
        notification["error"]({
          message: "Error",
          description:
            (error?.p_debt && error?.p_debt) || "Something went wrong!",
        });
      },
    }
  );
  function NextStep(data) {
    let p_activity = localStorage.getItem("app_id");
    let formData = new FormData();
    formData.append("process_step", 3);
    formData.append("p_own_funds", data.p_own_funds);
    formData.append("p_total_value", data.p_total_value);
    formData.append("p_project_objective", data.p_project_objective);
    formData.append("p_debt", data.p_debt);
    formData.append("p_activity", p_activity);
    if (hasUpdate) {
      formData.append("id", data.id);
      formData.append("p_activity", data.p_activity);
    } else formData.append("p_activity", p_activity);
    mutate(formData);
  }
  function goBack() {
    editStatus({
      step: 2,
    });
    localStorage.setItem("userStep", 2);
  }
  useQuery(
    ["get_project", accordionData.step],
    async () => await applyApi.getRequest("/app/retrieve-project/"),
    {
      onSuccess: (res) => {
        if (res) {
          reset({
            ...res,
          });
        }
      },
      onError: (err) => {
        if (err.status_code === 401) {
          navigate("/");
        }
      },
      enabled: parseInt(getStep) === 3 && !hasUpdate ? true : false,
    }
  );
  useQuery(
    ["get_project_update", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data?process_step=${getStep}`),
    {
      onSuccess: (res) => {
        if (res) {
          reset({
            ...res,
          });
          setHasUpdate(true);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled: parseInt(getStep) === 3 ? true : false,
    }
  );
  useEffect(() => {
    let result = Number(watch("p_debt")) + Number(watch("p_own_funds"));
    if (!isNaN(result)) {
      setValue("p_total_value", result);
    } else {
      setValue("p_total_value", parseInt(watch("p_debt")));
    }
  }, [watch("p_own_funds")]);

  return (
    <Form onSubmit={handleSubmit(NextStep)}>
      <Row gutter={16}>
        <Col span={24} xl={24}>
          <TitleH4>Loyiha qiymati:</TitleH4>
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            control={control}
            Controller={Controller}
            name="p_own_funds"
            label={"O’z mablag’lari"}
            required
            inputType={"number"}
            className={
              errors && errors?.hasOwnProperty("p_own_funds") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("p_own_funds") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            control={control}
            Controller={Controller}
            name="p_debt"
            label={"Qarz mablag’lari"}
            required
            inputType={"number"}
            disabled={true}
            className={
              errors && errors?.hasOwnProperty("p_debt") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("p_debt") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            control={control}
            Controller={Controller}
            name="p_total_value"
            label={"Umumiy qiymati"}
            required
            inputType={"number"}
            disabled={true}
            className={
              errors && errors?.hasOwnProperty("p_total_value") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("p_total_value") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={16} md={24}>
          <TextAreaComponent
            label={"Loyiha maqsadi"}
            Controller={Controller}
            control={control}
            name={"p_project_objective"}
            required
            className={
              errors &&
              errors?.hasOwnProperty("p_project_objective") &&
              "input-error"
            }
            // tekshir qizil chiqmadi!!!
          />
          {errors && errors?.hasOwnProperty("p_project_objective") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
      </Row>
      <ButtonComponent goBack={goBack} disabled={isLoading} />
    </Form>
  );
}

export default ProjectCollapse;
