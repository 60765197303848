import React from 'react';
import Input from './Input';
import { Table, TableBody, THead } from './style';

function Table1({ Controller, control }) {
  return (
    <Table>
      <THead>
        <tr>
          <th>№</th>
          <th>Ko’rsatkichlar nomi</th>
          <th>1 kunda</th>
          <th>1 oyda</th>
          <th>12 oyda</th>
        </tr>
      </THead>
      <TableBody>
        <tr>
          <td>1</td>
          <td>Maxsulot sotishdan tushum</td>
          <td>
            <Input
              required
              name={'m_1_day'}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={'m_1_month'}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
          <td>
            <Input
              name={'m_1_year'}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Maxsulot tannarxi</td>
          <td>
            <Input
              name={'m_2_day'}
              required
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              name={'m_2_month'}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
          <td>
            <Input
              name={'m_2_year'}
              Controller={Controller}
              control={control}
              disabled
              required
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>3</td>
          <td>Yalpi foyda</td>
          <td>
            <Input
              required
              name={'m_3_day'}
              disabled
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_3_month'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
          <td>
            <Input
              required
              name={'m_3_year'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Soliq to’lovlari</td>
          <td>
            <Input
              required
              name={'m_4_day'}
              disabled
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_4_month'}
              Controller={Controller}
              control={control}
              // disabled
            />
          </td>
          <td>
            <Input
              required
              name={'m_4_year'}
              Controller={Controller}
              control={control}
              // disabled
            />
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Kredit to’lovlari</td>
          <td>
            <Input
              required
              name={'m_5_day'}
              disabled
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_5_month'}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_5_year'}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Eksport bilan bog’liq xarajatlar</td>
          <td>
            <Input
              required
              name={'m_6_day'}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_6_month'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
          <td>
            <Input
              required
              name={'m_6_year'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>7</td>
          <td>Sof foyda</td>
          <td>
            <Input
              required
              name={'m_7_day'}
              disabled
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={'m_7_month'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
          <td>
            <Input
              required
              name={'m_7_year'}
              Controller={Controller}
              control={control}
              disabled
            />
          </td>
        </tr>
      </TableBody>
    </Table>
  );
}

export default Table1;
