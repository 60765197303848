import { Col, notification, Row } from "antd";
import InputController from "components/input/inputController";
import React, { useEffect, useState, Fragment } from "react";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import PassportComponent from "components/input/pasportSeriya";
import ButtonComponent from "../buttonComponent";
import { Cards, Form, Hr, TitleH4 } from "../style";
// import DatePickerComponent from "components/datePicker";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { useNavigate } from "react-router-dom";
import { EarthInfo } from "./earth-info";
import moment from "moment";

function Activity() {
  let debtType = localStorage.getItem("dep_type");
  const [pledge, setPledge] = useState(false);
  const editStatus = useAccordionStore((state) => state.editStatus);
  const accordionData = useAccordionStore((state) => state.accordionData);

  const navigate = useNavigate();
  const getStep = localStorage.getItem("userStep");
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      performer: [],
      lands: [
        {
          li_cad_num: 0,
          li_doc_type: "",
          li_doc_date: "",
          li_legal_area: "",
          li_gis_area: "",
          li_region: "",
          li_district: "",
          li_bonitet: "",
        },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "performer",
  });
  const expert_contracts = useWatch({
    control,
    name: "expert_contract",
  });

  const [userInfo, setUserInfo] = useState({
    error: false,
    success: false,
    data: null,
  });
  const [hasUpdate, setHasUpdate] = useState(true);
  // get earth info started
  useQuery(
    ["get_earth_info"],
    () => applyApi.getRequest("/app/retrieve-activity-v2/"),
    {
      onSuccess: (res) => {
        if (res) {
          setUserInfo((prev) => {
            return {
              ...prev,
              success: true,
              error: false,
              data: res,
            };
          });
          setPledge(res?.is_li);
        }
      },
      enabled:
        parseInt(localStorage.getItem("userStep")) === 2 &&
        !hasUpdate &&
        localStorage.getItem("dep_type") === "HARVEST"
          ? true
          : false,
    }
  );

  // get earth info finished
  const { mutate, isLoading } = useMutation(
    (data) =>
      debtType === "HARVEST"
        ? applyApi.sendApplyV2(data)
        : applyApi.sendApply(data),
    {
      onSuccess: (res) => {
        notification["success"]({
          message: "Success",
          description: res?.message || "1-qadam yakunlandi!!!",
        });
        localStorage.setItem("app_id", res?.p_activity);
        editStatus({
          step: 3,
        });
        localStorage.setItem("userStep", 3);
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Xatolik yuz berdi!",
        });
      },
    }
  );
  function NextStep(data) {
    console.log(data?.performer);
    let formData = new FormData();
    const appID = localStorage.getItem("app_id");
    formData.append("process_step", 2);
    formData.append("activity_type", data.activity_type);
    formData.append("charter_funds", data.charter_funds);
    formData.append("receivables", data.receivables * 1);
    formData.append("expert_contract", JSON.stringify(data.expert_contract));
    formData.append("director_passport_series", data.director_passport_series);
    formData.append("director_passport_number", data.director_passport_number);
    formData.append("performer", JSON.stringify(data.performer));
    formData.append("director_fullname", data.director_fullname);
    if (hasUpdate) {
      formData.append("id", data.id);
      formData.append("a_application", data.a_application);
    } else formData.append("a_application", appID);
    // app create-2 started
    if (debtType === "HARVEST") {
      let landList = data?.lands?.map((v) => {
        return {
          li_legal_area: Number(v?.li_legal_area),
          li_gis_area: Number(v?.li_gis_area),
          li_custom_area: Number(v?.li_custom_area),
          li_bonitet: Number(v?.li_bonitet),
          li_cad_num: v?.li_cad_num,
          li_doc_type: v?.li_doc_type,
          li_region: v?.li_region,
          li_district: v?.li_district,
          li_doc_date: v?.li_doc_date,
        };
      });
      formData.append("is_li", pledge);
      formData.append("lands", JSON.stringify(landList));
    }
    // app create-2 finished

    // formData.append('id', 28)
    mutate(formData);
  }
  useQuery(
    ["get_activity", accordionData.step],
    async () => await applyApi.getRequest("/app/retrieve-activity/"),
    {
      onSuccess: (res) => {
        if (res) {
          setUserInfo((prev) => {
            return {
              ...prev,
              success: true,
              error: false,
              data: res,
            };
          });
        }
      },
      onError: (err) => {
        setUserInfo((prev) => {
          return {
            ...prev,
            success: false,
            error: true,
          };
        });
        if (err.status_code === 401) {
          navigate("/");
        }
      },
      enabled:
        parseInt(getStep) === 2 &&
        !hasUpdate &&
        localStorage.getItem("dep_type") === "BUDGET"
          ? true
          : false,
    }
  );

  useQuery(
    ["get_activity_update-v2", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data-v2?process_step=${getStep}`),
    {
      onSuccess: (res) => {
        if (res) {
          setUserInfo((prev) => {
            return {
              ...prev,
              success: true,
              error: false,
              data: res,
            };
          });
          setPledge(res?.is_li);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled:
        parseInt(getStep) === 2 &&
        localStorage.getItem("dep_type") === "HARVEST"
          ? true
          : false,
    }
  );
  useQuery(
    ["get_activity_update", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data?process_step=${getStep}`),
    {
      onSuccess: (res) => {
        if (res) {
          setUserInfo((prev) => {
            return {
              ...prev,
              success: true,
              error: false,
              data: res,
            };
          });
          setHasUpdate(true);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled:
        parseInt(localStorage.getItem("userStep")) === 2 &&
        localStorage.getItem("dep_type") === "BUDGET"
          ? true
          : false,
    }
  );
  useEffect(() => {
    if (userInfo.success) {
      reset({
        ...userInfo.data,
        receivables: userInfo.data.receivables.toString(),
        lands: userInfo?.data?.lands?.map((v) => {
          return {
            ...v,
            li_doc_date: moment(v?.li_doc_date).format("YYYY-MM-DD"),
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.data, userInfo.success]);

  return (
    <Form onSubmit={handleSubmit(NextStep)}>
      <Row gutter={[16]}>
        <Col span={24} xl={8} lg={12} sm={24}>
          <InputController
            // disabled
            control={control}
            Controller={Controller}
            name="activity_type"
            label={"Faoliyat turi"}
            required
            className={
              errors && errors?.hasOwnProperty("activity_type") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("activity_type") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            disabled
            control={control}
            Controller={Controller}
            name="charter_funds"
            label={`Ustav fondi,(${watch("charter_funds_currency") || "so'm"})`}
            inputType={"number"}
            required
            className={
              errors && errors?.hasOwnProperty("charter_funds") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("charter_funds") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <InputController
            disabled
            control={control}
            Controller={Controller}
            name="receivables"
            label={"Dibitor qarzdorlik"}
            inputType={"text"}
            required
            className={
              errors && errors?.hasOwnProperty("receivables") && "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("receivables") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={24}>
          <TitleH4>Raxbari:</TitleH4>
        </Col>
        <Col span={24} xl={8} lg={12} sm={12}>
          <PassportComponent
            disabled
            control={control}
            Controller={Controller}
            nameA={"director_passport_series"}
            nameB={"director_passport_number"}
            required
            className={
              errors &&
              errors?.hasOwnProperty("director_passport_number") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("director_passport_number") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
        <Col span={24} xl={16} md={12} sm={12}>
          <InputController
            disabled
            control={control}
            Controller={Controller}
            name="director_fullname"
            label={"FIO"}
            required
            className={
              errors &&
              errors?.hasOwnProperty("director_fullname") &&
              "input-error"
            }
          />
          {errors && errors?.hasOwnProperty("director_fullname") && (
            <span className="error-span">Please enter information!</span>
          )}
        </Col>
      </Row>
      <Row gutter={[16]}>
        <Col span={24} xl={24}>
          <TitleH4>Ta'sischisi: {fields.length === 0 && "yo'q"}</TitleH4>
        </Col>
        {fields.map((item, index) => {
          return (
            <Fragment key={index}>
              <Col span={24} xl={8} lg={12} sm={12}>
                <PassportComponent
                  disabled
                  control={control}
                  Controller={Controller}
                  nameA={`performer[${index}].performer_passport_series`}
                  nameB={`performer[${index}].performer_passport_number`}
                  defaultValueA={item.performer_passport_series}
                  defaultValueB={item.performer_passport_number}
                  required={index < 1 ? true : false}
                  className={
                    errors &&
                    errors?.performer?.[index]?.performer_passport_series &&
                    "input-error"
                  }
                />
                {errors &&
                  errors?.performer?.[index]?.performer_passport_series && (
                    <span className="error-span">
                      Please enter information!
                    </span>
                  )}
              </Col>
              <Col span={24} xl={16} md={12} sm={12}>
                <InputController
                  disabled
                  control={control}
                  Controller={Controller}
                  name={`performer[${index}].performer_fullname`}
                  defaultValue={`${item.performer_fullname}`}
                  label={"FIO"}
                  required={index < 1 ? true : false}
                  className={
                    errors &&
                    errors?.performer?.[index]?.performer_fullname &&
                    "input-error"
                  }
                />
                {errors && errors?.performer?.[index]?.performer_fullname && (
                  <span className="error-span">Please enter information!</span>
                )}
              </Col>
            </Fragment>
          );
        })}
      </Row>
      {userInfo.success
        ? userInfo?.data?.expert_contract &&
          userInfo?.data?.expert_contract?.length !== 0 && (
            <Cards>
              <Row gutter={16}>
                <Col span={24} xl={24}>
                  <TitleH4 className="m-0">
                    Eksport shartnomalari ma’lumoti:
                  </TitleH4>
                </Col>
                {expert_contracts?.map((item, index) => (
                  <Fragment key={index}>
                    <Col span={24} xl={8} lg={12} sm={24}>
                      <InputController
                        disabled
                        control={control}
                        Controller={Controller}
                        name={`expert_contract[${index}].ec_farmer_name`}
                        label={index + 1 + ". Fermer xo’jaligi nomi"}
                        defaultValue={item.ec_farmer_name}
                        required
                        className={
                          errors &&
                          errors?.expert_contract?.[index]?.ec_farmer_name &&
                          "input-error"
                        }
                      />
                      {errors &&
                        errors?.expert_contract?.[index]?.ec_farmer_name && (
                          <span className="error-span">
                            Please enter information!
                          </span>
                        )}
                    </Col>
                    <Col span={24} xl={8} lg={12} sm={12}>
                      <InputController
                        disabled
                        control={control}
                        Controller={Controller}
                        name={`expert_contract[${index}].ec_number`}
                        label={"Raqami"}
                        defaultValue={item?.ec_number}
                        required
                        className={
                          errors &&
                          errors?.expert_contract?.[index]?.ec_number &&
                          "input-error"
                        }
                      />
                      {errors &&
                        errors?.expert_contract?.[index]?.ec_number && (
                          <span className="error-span">
                            Please enter information!
                          </span>
                        )}
                    </Col>
                    <Col span={24} xl={8} lg={12} sm={12}>
                      <InputController
                        disabled
                        control={control}
                        Controller={Controller}
                        name={`expert_contract[${index}].ec_date`}
                        label={"Sanasi"}
                        defaultValue={item?.ec_date}
                        required
                        className={
                          errors &&
                          errors?.expert_contract?.[index]?.ec_date &&
                          "input-error"
                        }
                      />
                      {errors && errors?.expert_contract?.[index]?.ec_date && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col span={24} xl={8} lg={12} sm={12}>
                      <InputController
                        disabled
                        control={control}
                        Controller={Controller}
                        name={`expert_contract[${index}].ec_price`}
                        label={"Summasi"}
                        defaultValue={item?.ec_price}
                        required
                        className={
                          errors &&
                          errors?.expert_contract?.[index]?.ec_price &&
                          "input-error"
                        }
                      />
                      {errors && errors?.expert_contract?.[index]?.ec_price && (
                        <span className="error-span">
                          Please enter information!
                        </span>
                      )}
                    </Col>
                    <Col span={24} xl={8} lg={12} sm={12}>
                      <InputController
                        disabled
                        control={control}
                        Controller={Controller}
                        name={`expert_contract[${index}].ec_product_name`}
                        label={"Tovar nomi"}
                        defaultValue={item?.ec_product_name}
                        required
                        className={
                          errors &&
                          errors?.expert_contract?.[index]?.ec_product_name &&
                          "input-error"
                        }
                      />
                      {errors &&
                        errors?.expert_contract?.[index]?.ec_product_name && (
                          <span className="error-span">
                            Please enter information!
                          </span>
                        )}
                    </Col>
                    {expert_contracts.length !== index + 1 && (
                      <Col span={24} xl={24}>
                        <Hr />
                      </Col>
                    )}
                  </Fragment>
                ))}
              </Row>
            </Cards>
          )
        : null}
      {debtType === "HARVEST" && (
        <EarthInfo
          control={control}
          Controller={Controller}
          errors={errors}
          pledge={pledge}
          setPledge={setPledge}
          landsFields={userInfo?.data?.lands}
          watch={watch}
        />
      )}
      <ButtonComponent
        goBack={() => navigate("/ms/ariza")}
        disabled={isLoading}
        isButton={false}
      />
    </Form>
  );
}

export default Activity;
