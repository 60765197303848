import React from "react";
import { WrapperClassification } from "./style";
import ProjectPasport from "../components/projectPasport";
import ProjectDrawing from "../components/projectDrawing";

function ProjectClassification() {
  return (
    <WrapperClassification>
      <ProjectPasport />
      <ProjectDrawing />
    </WrapperClassification>
  );
}

export default ProjectClassification;
