import { Row } from "antd";
import {
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";
import React from "react";
import ApplyTable from "./tables/applyTable";
import { useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { useNavigate } from "react-router-dom";

function ApplysComponent() {
    const navigate = useNavigate();
    const {data: personal_apply} = useQuery(
    ["get_personal_apply"],
    async () => await applyApi.getRequest("/personal-cabinet/apps-info/"),
    {
      onError: (err) => {
        if (err?.status_code === 401) {
            navigate("/");
          }
      },
    }
  );
  return (
    <MainContainer>
      <MarginContainer>
        <Row gutter={24}>
          <ApplyTable data={personal_apply}/>
        </Row>
      </MarginContainer>
    </MainContainer>
  );
}

export default ApplysComponent;
