/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, notification, Row } from "antd";
import { Form, MarketingWraps, TitleH4 } from "../style";
import { Controller, useForm, useWatch } from "react-hook-form";
import ButtonComponent from "../buttonComponent";
import useAccordionStore from "../../../../../../zustand/accordionData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import BRegion from "./bRegion";
import ARegion from "./aRegion";
import CAbroad from "./cAbroad";
import dayjs from "dayjs";
import TextAreaComponent from "components/input/textArea";
// import { useMemo } from 'react'

function MarketingCollapse({ debtType }) {
  const editStatus = useAccordionStore((state) => state.editStatus);
  const accordionData = useAccordionStore((state) => state.accordionData);
  const [hasUpdate, setHasUpdate] = useState(true);
  const [neighbourSale, setNeighbourSale] = useState(true);
  const [foreignSale, setForeignSale] = useState(true);
  const [regionSale, setRegionSale] = useState(true);
  const [options, setOptions] = useState([]);
  const getStep = localStorage.getItem("userStep");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      region_marketing: [
        {
          r_stir: null,
          r_company_name: null,
          r_product_name: null,
          r_production_volume: null,
        },
      ],
      neighbour_marketing: [
        {
          n_stir: null,
          n_company_name: null,
          n_product_name: null,
          n_production_volume: null,
        },
      ],
      foreign_marketing: [
        {
          fm_partner_name: null,
          fm_country: null,
          fm_contract_num: null,
          fm_product_name: null,
          fm_contract_date: null,
          fm_contract_amount: null,
        },
      ],
    },
  });
  // console.log(watch('sales_within_foreign'),'sales_within_foreign');
  const [region_marketing, neighbour_marketing] = useWatch({
    control,
    name: ["region_marketing", "neighbour_marketing", "foreign_marketing"],
  });
  const { mutate, isLoading } = useMutation(
    (data) =>
      debtType === "HARVEST"
        ? applyApi.sendApplyV2(data)
        : applyApi.sendApply(data),
    {
      onSuccess: (res) => {
        notification["success"]({
          message: "Success",
          description: "4-qadam yakunlandi!!!",
        });
        editStatus({
          step: 6,
        });
        localStorage.setItem("userStep", 6);
        localStorage.setItem("app_id", res?.fp_marketing);
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Something went wrong!",
        });
      },
    }
  );

  function NextStep(data) {
    let formData = new FormData();
    let m_technology = localStorage.getItem("app_id");
    formData.append("foreign_sale", foreignSale);
    formData.append("neighbour_sale", neighbourSale);
    formData.append("region_sale", regionSale);
    if (debtType === "HARVEST") {
      formData.append("m_description", data.m_description);
    }
    formData.append("process_step", 5);
    if (regionSale) {
      formData.append(
        "region_marketing",
        JSON.stringify(
          data.region_marketing?.map((item) => {
            return {
              ...item,
              r_product_name: item?.r_product_name?.value,
            };
          })
        )
      );
    } else formData.append("region_marketing", JSON.stringify([]));
    if (neighbourSale) {
      formData.append(
        "neighbour_marketing",
        JSON.stringify(
          data.neighbour_marketing?.map((item) => {
            return {
              ...item,
              n_product_name: item?.n_product_name?.value,
            };
          })
        )
      );
    } else formData.append("neighbour_marketing", JSON.stringify([]));
    if (foreignSale) {
      formData.append(
        "foreign_marketing",
        JSON.stringify(
          data.foreign_marketing?.map((item) => {
            return {
              ...item,
              fm_product_name: item?.fm_product_name?.value,
            };
          })
        )
      );
    } else formData.append("foreign_marketing", JSON.stringify([]));

    if (hasUpdate) {
      formData.append("id", data.id);
      if (debtType === "HARVEST") {
        formData.append("m_prod_sales", data.m_prod_sales);
      } else {
        formData.append("m_technology", data.m_technology);
      }
    } else {
      if (debtType === "HARVEST") {
        formData.append("m_prod_sales", m_technology);
      } else {
        formData.append("m_technology", m_technology);
      }
    }
    let count = 0;
    for (let i = 0; i < 1; i++) {
      if (foreignSale) {
        count++;
      }
      if (neighbourSale) {
        count++;
      }
      if (regionSale) {
        count++;
      }
    }
    if (count >= 1) {
      mutate(formData);
      // console.log(data, 'datatttataad');
    } else {
      notification["error"]({
        message: "Error",
        description: "Hech bo'lmaganda bittasini to'ldirishingiz kerak!",
      });
    }
  }

  useQuery(
    ["get_marketing_update", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data?process_step=${getStep}`),
    {
      select: (res) => {
        return {
          ...res,
          foreign_marketing: res?.foreign_marketing?.map((v) => {
            let contractDate = dayjs(v?.fm_contract_date).format("MM.DD.YYYY");
            return {
              ...v,
              fm_contract_date: dayjs(contractDate, "MM.DD.YYYY"),
            };
          }),
        };
      },
      onSuccess: (res) => {
        if (res) {
          setForeignSale(res?.foreign_sale);
          setRegionSale(res?.region_sale);
          setNeighbourSale(res?.neighbour_sale);
          setHasUpdate(true);
          reset({
            ...res,
            region_marketing:
              res?.region_marketing.length > 0
                ? res?.region_marketing
                : [
                    {
                      r_stir: null,
                      r_company_name: null,
                      r_product_name: null,
                      r_production_volume: null,
                    },
                  ],
            neighbour_marketing:
              res?.neighbour_marketing.length > 0
                ? res?.neighbour_marketing
                : [
                    {
                      n_stir: null,
                      n_company_name: null,
                      n_product_name: null,
                      n_production_volume: null,
                    },
                  ],
            foreign_marketing:
              res?.foreign_marketing.length > 0
                ? res?.foreign_marketing
                : [
                    {
                      fm_partner_name: null,
                      fm_country: null,
                      fm_contract_num: null,
                      fm_product_name: null,
                      fm_contract_date: null,
                      fm_contract_amount: null,
                    },
                  ],
          });
          localStorage.setItem("app_id", res.m_technology);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled:
        parseInt(getStep) === 5 && localStorage.getItem("dep_type") === "BUDGET"
          ? true
          : false,
    }
  );
  useQuery(
    ["get_marketing_update-v2", accordionData.step],
    async () =>
      await applyApi.getRequest(`/app/related-data-v2?process_step=${getStep}`),
    {
      select: (res) => {
        return {
          ...res,
          foreign_marketing: res?.foreign_marketing?.map((v) => {
            let contractDate = dayjs(v?.fm_contract_date).format("MM.DD.YYYY");
            return {
              ...v,
              fm_contract_date: dayjs(contractDate, "MM.DD.YYYY"),
            };
          }),
        };
      },
      onSuccess: (res) => {
        if (res) {
          setForeignSale(res?.foreign_sale);
          setRegionSale(res?.region_sale);
          setNeighbourSale(res?.neighbour_sale);
          setHasUpdate(true);
          reset({
            ...res,
            region_marketing:
              res?.region_marketing.length > 0
                ? res?.region_marketing
                : [
                    {
                      r_stir: null,
                      r_company_name: null,
                      r_product_name: null,
                      r_production_volume: null,
                    },
                  ],
            neighbour_marketing:
              res?.neighbour_marketing.length > 0
                ? res?.neighbour_marketing
                : [
                    {
                      n_stir: null,
                      n_company_name: null,
                      n_product_name: null,
                      n_production_volume: null,
                    },
                  ],
            foreign_marketing:
              res?.foreign_marketing.length > 0
                ? res?.foreign_marketing
                : [
                    {
                      fm_partner_name: null,
                      fm_country: null,
                      fm_contract_num: null,
                      fm_product_name: null,
                      fm_contract_date: null,
                      fm_contract_amount: null,
                    },
                  ],
          });
          if (localStorage.getItem("dep_type") === "HARVEST") {
            localStorage.setItem("app_id", res.m_prod_sales);
          } else localStorage.setItem("app_id", res.m_technology);
        }
      },
      onError: (err) => {
        if (err.status_code === 404) {
          setHasUpdate(false);
        }
      },
      enabled:
        parseInt(getStep) === 5 &&
        localStorage.getItem("dep_type") === "HARVEST"
          ? true
          : false,
    }
  );
  useQuery(
    ["get_product", accordionData.step],
    async () =>
      debtType === "HARVEST"
        ? await applyApi.getRequest(
            `/app/products-prod-sales/${localStorage.getItem("app_id")}`
          )
        : await applyApi.getRequest(
            `/app/products-tech/${localStorage.getItem("app_id")}/`
          ),
    {
      onSuccess: (res) => {
        if (res) {
          setOptions(res);
        }
      },
      onError: (err) => {
        // notification['error',{

        // }]
        console.log(err);
      },
      enabled: parseInt(getStep) === 5 ? true : false,
    }
  );

  const getStirA = async (stir, data, number) => {
    // const res = await getAxios1(`/app/company-name/${stir}`, data)
    const res = await applyApi.getRequest(
      `/app/company-name/${stir}?type=${data?.type}`
    );
    // console.log(res, 'stir');
    if (res) {
      setValue(
        `region_marketing[${number}].r_company_name`,
        res?.r_company_name
      );
    }
  };
  const getStirB = async (stir, data, number) => {
    // const res = await getAxios1(`/app/company-name/${stir}`, data);
    const res = await applyApi.getRequest(
      `/app/company-name/${stir}?type=${data?.type}`
    );
    // console.log(res, 'stir');
    if (res) {
      setValue(
        `neighbour_marketing[${number}].n_company_name`,
        res?.n_company_name
      );
    }
  };
  useEffect(() => {
    if (neighbour_marketing[0]?.n_stir?.length === 9) {
      getStirB(
        neighbour_marketing[0].n_stir,
        {
          type: "B",
        },
        0
      );
    }
  }, [neighbour_marketing[0]?.n_stir]);
  useEffect(() => {
    if (
      neighbour_marketing[1] &&
      neighbour_marketing[1]?.n_stir?.length === 9
    ) {
      getStirB(
        neighbour_marketing[1]?.n_stir,
        {
          type: "B",
        },
        1
      );
    }
  }, [neighbour_marketing[1]?.n_stir]);
  useEffect(() => {
    if (
      neighbour_marketing[2] &&
      neighbour_marketing[2]?.n_stir?.length === 9
    ) {
      getStirB(
        neighbour_marketing[2]?.n_stir,
        {
          type: "B",
        },
        2
      );
    }
  }, [neighbour_marketing[2]?.n_stir]);
  useEffect(() => {
    if (
      neighbour_marketing[3] &&
      neighbour_marketing[3]?.n_stir?.length === 9
    ) {
      getStirB(
        neighbour_marketing[3]?.n_stir,
        {
          type: "B",
        },
        3
      );
    }
  }, [neighbour_marketing[3]?.n_stir]);
  useEffect(() => {
    if (
      neighbour_marketing[4] &&
      neighbour_marketing[4]?.n_stir?.length === 9
    ) {
      getStirB(
        neighbour_marketing[4]?.n_stir,
        {
          type: "B",
        },
        4
      );
    }
  }, [neighbour_marketing[4]?.n_stir]);

  useEffect(() => {
    if (region_marketing[0]?.r_stir?.length === 9) {
      getStirA(
        region_marketing[0]?.r_stir,
        {
          type: "A",
        },
        0
      );
    }
  }, [region_marketing[0]?.r_stir]);
  useEffect(() => {
    if (region_marketing[1] && region_marketing[1]?.r_stir?.length === 9) {
      getStirA(
        region_marketing[1]?.r_stir,
        {
          type: "A",
        },
        1
      );
    }
  }, [region_marketing[1]?.r_stir]);
  useEffect(() => {
    if (region_marketing[2] && region_marketing[2]?.r_stir?.length === 9) {
      getStirA(
        region_marketing[2]?.r_stir,
        {
          type: "A",
        },
        2
      );
    }
  }, [region_marketing[2]?.r_stir]);
  useEffect(() => {
    if (region_marketing[3] && region_marketing[3]?.r_stir?.length === 9) {
      getStirA(
        region_marketing[3]?.r_stir,
        {
          type: "A",
        },
        3
      );
    }
  }, [region_marketing[3]?.r_stir]);
  useEffect(() => {
    if (region_marketing[4] && region_marketing[4]?.r_stir?.length === 9) {
      getStirA(
        region_marketing[4]?.r_stir,
        {
          type: "A",
        },
        4
      );
    }
  }, [region_marketing[4]?.r_stir]);
  // useEffect(() => {
  //   if (foreignSale) {
  //     setValue("foreign_marketing", [{
  //       fm_partner_name: null,
  //       fm_country: null,
  //       fm_contract_num: null,
  //       fm_product_name: null,
  //       fm_contract_date: null,
  //       fm_contract_amount: null,
  //     }]);
  //   }
  // }, [foreignSale]);
  function goBack() {
    editStatus({
      step: 4,
    });
    localStorage.setItem("userStep", 4);
  }

  // console.log(watch("sales_within_foreign"), "sales_within_foreign");
  return (
    <Form onSubmit={handleSubmit(NextStep)}>
      <MarketingWraps>
        <TitleH4>Hamkorlar (kontoragentlar) sigmentini aniqlash</TitleH4>
        <ARegion
          control={control}
          Controller={Controller}
          errors={errors}
          options={options}
          setRegionSale={setRegionSale}
          regionSale={regionSale}
        />
        <BRegion
          control={control}
          Controller={Controller}
          errors={errors}
          options={options}
          setNeighbourSale={setNeighbourSale}
          neighbourSale={neighbourSale}
        />
        <CAbroad
          control={control}
          Controller={Controller}
          errors={errors}
          options={options}
          setForeignSale={setForeignSale}
          foreignSale={foreignSale}
        />
      </MarketingWraps>
      {localStorage.getItem("dep_type") === "BUDGET" ? null : (
        <Row>
          <Col span={24} md={16}>
            <TextAreaComponent
              label={"Izoh qoldirish"}
              Controller={Controller}
              control={control}
              name={"m_description"}
              required
              className={
                errors &&
                errors?.hasOwnProperty("m_description") &&
                "input-error"
              }
              // tekshir qizil chiqmadi!!!
            />
            {errors && errors?.hasOwnProperty("m_description") && (
              <span className="error-span">Please enter information!</span>
            )}
          </Col>
        </Row>
      )}

      <ButtonComponent goBack={goBack} disabled={isLoading} />
    </Form>
  );
}

export default MarketingCollapse;
