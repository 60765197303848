import styled from "styled-components";
import { allColors } from "../colors/AllColors";
import { Link } from "react-router-dom";

export const Marquee = styled("marquee")`
  width: 100%;
  background-color: #ffc107;
  /* padding: 0.25rem; */
  font-family: "Raleway";
  font-size: 14px;
`;

export const Main = styled("div")`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  padding: 24px 0;
  background-color: ${allColors.mainColor};
  box-sizing: border-box;
  @media (max-width: 1366px) {
    padding: 22px 0;
  }
  @media (max-width: 920px) {
    padding: 18px 0;
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const LogoMain = styled.div`
  display: inline-flex;
  gap: 40px;
  align-items: center;
  .vaz-logo {
    width:  109px;
    height: 47px;
  }
  .plus-logo {
    width: 224px;
    height: 56px;
  }
  .bog-logo {
    /* width: 232px; */
    /* height: 44px; */
  }
  @media (max-width: 1366px) {
    gap: 20px;
    .vaz-logo {
      /* width: 212px; */
      height: 42px;
    }
    .plus-logo {
      width: 190px;
      height: 56px;
    }
    .bog-logo {
      /* width: 160px; */
      /* height: 42px; */
    }
  }
  @media (max-width: 920px) {
    gap: 32px;
    /* .vaz-logo {
      width: 200px;
      height: 53px;
    } */

    .plus-logo {
      display: none;
    }
  }
  @media (max-width: 540px) {
    .plus-logo {
      display: none;
    }
  }
  @media (max-width: 525px) {
    .vaz-logo {
      display: none;
    }
    .plus-logo {
      display: none;
    }
  }
`;

export const AccountCard = styled.div`
  padding: 10px 20px 10px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 85px;

  .main-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .exit-card {
    cursor: pointer;
    display: none;
    width: auto;
    height: auto;
    background-color: ${allColors.whiteColor};
    border: 1px solid ${allColors.mainColor};
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 500;
    /* bottom: -10px; */
    svg {
      color: black;
      width: 24px;
    }
    &:hover {
      color: ${allColors.mainColor};
      svg {
        color: ${allColors.mainColor};
      }
    }
    @media (max-width: 520px) {
      left: -100%;
      top: 100%;
    }
  }
  &:hover {
    .exit-card {
      display: flex;
      align-items: center;
    }
  }
  span {
    color: ${allColors.whiteColor};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @media (max-width: 650px) {
      display: none !important;
    }
  }
  svg {
    color: ${allColors.whiteColor};
    font-size: 18px;
    width: 26px !important;
    height: auto;
  }
  @media (max-width: 650px) {
    padding: 10px !important;
    border-radius: 50%;
    svg {
      /* font-size: 20px; */
      height: 25px;
    }
  }
`;
export const DropItem = styled(Link)`
  display: flex;
  gap: 2px;
  align-items: center;
  font-weight: 500;
  svg {
    color: black;
    width: 24px;
  }
  &:hover {
    color: ${allColors.mainColor} !important;
    svg {
      color: ${allColors.mainColor} !important;
    }
  }
`;
export const DropExitItem = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  font-weight: 500;
  svg {
    color: black;
    width: 24px;
  }
  &:hover {
    color: ${allColors.mainColor} !important;
    svg {
      color: ${allColors.mainColor} !important;
    }
  }
`;

export const EnterLink = styled(Link)`
  gap: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  padding: 11px 16px;
  border-radius: 85px;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  color: ${allColors.whiteColor};
  border: 1px solid rgba(255, 255, 255, 0.3);
  :hover {
    color: ${allColors.whiteColor};
  }
  @media (max-width: 920px) {
    padding: 5px 16px;
  }
  @media (max-width: 525px) {
    font-size: 14px;
    line-height: normal;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
