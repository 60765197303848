import styled from 'styled-components';

export const Wrapper = styled('div')`
  padding: 41px 0 78px 0;
  .ant-steps-item-icon {
    width: 63px;
    height: 63px;
    background-color: #6ba99f !important;
    padding: 8px;
  }
  .ant-steps-item-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #60676d;
    text-align: center;
  }
  .ant-steps-item-tail {
    left: 17%;
    width: 80%;
  }
  .ant-steps-item-tail::after {
    margin-top: 12px;
    background-color: #9cc6bf !important;
  }
  .ant-steps-item-content {
    width: 152px !important;
  }
`;
