import { allColors } from 'components/colors/AllColors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${allColors.mainColor};
  height: 100vh;
`
export const Cards = styled.div`
  box-sizing: border-box;
  width: 450px;
  background-color: ${allColors.whiteColor};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled.h2`
  margin: 0;
  width: 100%;
  font-family: 'Raleway';
  font-size: 30px;
  color: ${allColors.shriftColor};
  font-weight: 700;
  text-align: center;
`

export const TitleCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  button {
    border: 1px solid rgb(109 81 206);
    background-color: ${allColors.whiteColor};
    border-radius: 4px;
    padding: 6px 10px;
  }
`

export const ImageCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 65px;
    box-shadow: 2px 2px 30px lightgrey;
  }
`
