import Api from '../index';

class Apply extends Api {
  sendApply(data) {
    return this.execute('post', '/app/create/', data);
  }
  sendApplyV2(data) {
    return this.execute('post', '/app/create-v2/', data);
  }
  getRequest(url) {
    return this.execute('get', url);
  }
}

export default Apply;
