import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 12px;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  .ant-input {
    background-color: ${allColors.selectBgColor};
  }
`;
export const Label = styled.label`
  /* margin-bottom: 8px; */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* letter-spacing: 0.1px; */
  font-feature-settings: "pnum" on, "lnum" on;
  color: ${allColors.shriftColorOpasity3};
`;

export const FotoInfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 2px;
  background: ${allColors.selectBgColor};
  border-radius: 8px;
  height: 57px;

  .close-icon {
    font-size: 24px;
    cursor: pointer;
  }
`;
export const FileIconCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  .img-style-file {
    font-size: 26px;
    color: #3aa291;
  }
`;
export const FileNameCard = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${allColors.shriftColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-decoration: none;
    /* width: 250px;
    @media (min-width: 679px) and (max-width: 860px) {
      width: 200px;
    }
    @media (min-width: 576px) and (max-width: 678px) {
      width: 150px;
    }
    @media (max-width: 435px) {
      width: 130px;
    } */
  }
  span {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #a9abbd;
  }
`;
export const Line = styled.div`
  width: 100%;
  margin: 16px 0;
  height: 6px;
  border-top: 2px solid rgba(8, 112, 95, 0.6);
  border-bottom: 2px solid rgba(8, 112, 95, 0.6);
`;
export const TotalCost = styled.h4`
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${allColors.mainColor};
`;
