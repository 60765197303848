import { useEffect } from 'react';

export const useTotal = ({ planProps }) => {
  const getTotal = () => {
    const totalList = Object.values(planProps).reduce(
      (prev, next) => {
        return prev.map((v, i) => {
          const n = next[i] === undefined ? 0 : next[i];
          return Number(v) + Number(n);
        });
      },
      [0, 0, 0]
    );
    return totalList;
  };
  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planProps]);

  return {
    getTotal,
  };
};
