import { allColors } from 'components/colors/AllColors';
import styled from 'styled-components';
import bannerBackground from 'assets/images/landing/backgroundBaner1.jpg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${allColors.mainColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${bannerBackground});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const LogoCards = styled.div`
  width: 250px;
  height: 120px;
  border: 1px solid ${allColors.whiteColor};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: ${allColors.whiteColor};
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.5);
    color: ${allColors.whiteColor};
    transition: transform 0.3s ease;
    width: 260px;
    height: 130px;
  }
  @media (max-width: 920px) {
    width: 200px;
    height: 100px;
    font-weight: 600;
    font-size: 16px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.5);
      color: ${allColors.whiteColor};
      transition: transform 0.3s ease;
      width: 210px;
      height: 110px;
    }
  }
`;
