import { useQuery } from "@tanstack/react-query";
import { applyApi } from "shared/api/pagesApi";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useState } from "react";
// import { notification } from "antd";

export function Hooks() {
  const navigate = useNavigate();
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const regionId = useWatch({
    control,
    name: "region",
  });

  useQuery(
    ["get_leasing_user_info"],
    async () => await applyApi.getRequest(`/app-l/retrieve-app/`),
    {
      onSuccess: (res) => {
        reset(res);
      },
      onError: (err) => {
        if (err.status_code === 401) {
          navigate("/");
        }
      },
    }
  );
  useQuery(
    ["get_region_list_leasing"],
    async () => await applyApi.getRequest("/tools/region/"),
    {
      onSuccess: (res) => {
        setRegion(res);
      },
      onError: (err) => {
        setRegion([]);
      },
    }
  );
  useQuery(
    ["get_district_list_leasing", regionId],
    async () =>
      await applyApi.getRequest(`/tools/district/${regionId?.value}/`),
    {
      onSuccess: (res) => {
        setDistrict(res);
      },
      onError: () => {
        setDistrict([]);
      },
      enabled: regionId ? true : false,
    }
  );
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("company_name", data?.company_name);
    formData.append("legal_address", data?.legal_address);
    formData.append("stir", data?.stir);
    formData.append("phone_number", data?.phone_number);
    formData.append("email", data?.email);
    formData.append("region", data?.region.value);
    formData.append("district", data?.district.value);
    // mutate(formData);
    let payload = {
      company_name: data?.company_name,
      legal_address: data?.legal_address,
      stir: data?.stir,
      phone_number: data?.phone_number,
      email: data?.email,
      region: data?.region?.value,
      district: data?.district?.value,
    };
    localStorage.setItem("personal_info", JSON.stringify(payload));
    navigate("/agro-leasing/apply-type-choose");
  };
  return {
    handleSubmit,
    control,
    errors,
    region,
    district,
    onSubmit,
  };
}
