import styled from 'styled-components'

export const ButtonStyle = styled.button`
  width: ${(props) => props.Width};
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    props.BorderColor ? '1px solid ' + props.BorderColor : 'none'} !important;
  height: 44px;
  color: ${(props) => props.TextColor};
  padding: 12px;
  font-family: 'Raleway' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.Opacity ?? 1};
  gap: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`
