import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;
