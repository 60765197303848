import React from "react";
import Input from "../../financePlan/Input";
import { Table, TableBody, THead } from "./style";

export const TableComponent = ({ Controller, control, total, totalNew }) => {
  
  return (
    <Table>
      <THead>
        <tr>
          <th>№</th>
          <th>Xarajat turlari</th>
          <th>O’lchov birligi</th>
          <th>Miqdori</th>
          <th>Qiymati </th>
        </tr>
      </THead>
      <TableBody>
        <tr>
          <td>1</td>
          <td>Ekin maydoni</td>
          <td>ga</td>
          <td>
            <Input
              name={"crop_area_1_amount"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td className="disabled"></td>
        </tr>
        <tr>
          <td>2</td>
          <td>Xosildorlik</td>
          <td>st/ga</td>
          <td>
            <Input
              name={"productivity_2_amount"}
              Controller={Controller}
              control={control}
              required
            />
          </td>
          <td className="disabled"></td>
        </tr>
        <tr>
          <td>3</td>
          <td>Yalpi hosil (tn)</td>
          <td>tonna</td>
          <td>
            <Input
              required
              name={"gross_yield_3_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td className="disabled"></td>
        </tr>
        <tr>
          <td>4</td>
          <td>Sotishdan tushadigan tushum</td>
          <td>ming so'm</td>
          <td className="disabled"></td>
          <td>
            <Input
              required
              name={"sales_revenue_4_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>5</td>
          <td>Jami xarajatlar</td>
          <td>ming so'm</td>
          <td></td>
          <td>{total[0]} </td>
        </tr>
        <tr>
          <td>5.1</td>
          <td>Urug'lik</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"seed_5_1_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"seed_5_1_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.2</td>
          <td>Ish haqi</td>
          <td>ming so'm</td>
          <td className="disabled"></td>
          <td>
            <Input
              required
              name={"work_fee_5_2_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>5.3</td>
          <td>Mineral o’g’itlar: </td>
          <td>ming so'm</td>
          <td></td>
          <td>{totalNew[0]}</td>
        </tr>
        <tr>
          <td>5.3.1</td>
          <td>-ammafos</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"ammophos_5_3_1_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"ammophos_5_3_1_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.3.2</td>
          <td>-ammiachnaya selitra</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"ammonium_nitrate_5_3_2_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"ammonium_nitrate_5_3_2_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.3.3</td>
          <td>-karbamid</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"urea_5_3_3_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"urea_5_3_3_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.3.4</td>
          <td>-kaliy</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"potassium_5_3_4_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"potassium_5_3_4_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.3.5</td>
          <td>-boshqalar</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"others_5_3_5_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"others_5_3_5_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.4</td>
          <td>O‘simliklarni himoya qilish vositalari</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"plant_protection_products_5_4_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"plant_protection_products_5_4_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.5</td>
          <td>Kimyoviy himoya vositalari </td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"chemical_protection_means_5_5_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"chemical_protection_means_5_5_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.6</td>
          <td>Kimyoviy oziqlantirish vositalari </td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"chemical_nutrients_5_6_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"chemical_nutrients_5_6_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.7</td>
          <td>Yoqilg’i moylash materiallari</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"fuel_lubricants_5_7_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"fuel_lubricants_5_7_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.8</td>
          <td>Texnika xizmatlari</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"technical_materials_5_8_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"technical_materials_5_8_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>

        <tr>
          <td>5.9</td>
          <td>Solik to‘lovlari (so’m)</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"tax_payments_5_9_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"tax_payments_5_9_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr>
          <td>5.10</td>
          <td>Boshqa xarajatlar</td>
          <td>ming so'm</td>
          <td>
            <Input
              required
              name={"other_expenses_5_10_amount"}
              Controller={Controller}
              control={control}
            />
          </td>
          <td>
            <Input
              required
              name={"other_expenses_5_10_value"}
              Controller={Controller}
              control={control}
            />
          </td>
        </tr>
        <tr className="tr-green">
          <td>6</td>
          <td>Sof foyda</td>
          <td>ming so'm</td>
          <td></td>
          <td>
            <Input
              required
              name={"net_profit_6_value"}
              Controller={Controller}
              control={control}
              disabled={true}
            />
          </td>
        </tr>
      </TableBody>
    </Table>
  );
};
