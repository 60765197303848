import { allColors } from "components/colors/AllColors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ScrollContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
`;
export const TitleH4 = styled.h3`
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
`;
export const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  tr {
    /* height: 50px; */
    border-bottom: 1px solid #e7e7e7;
  }
`;
export const TableBody = styled.tbody`
  tr {
    padding: 0;
    width: 100%;
    border-radius: 12px;
    &.tr-green {
      background-color: rgba(8, 112, 95, 0.05);
      td {
        color: ${allColors.mainColor} !important;
        font-weight: 600;
      }
      input {
        background: #f2f8f7;
      }
    }
  }
  & td {
    position: relative;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${allColors.shriftColor};
    padding: 8px 10px;
  }
  & td:first-child {
    min-width: auto !important;
    text-align: center;
    width: 50px;
  }
  & td:nth-child(2) {
    min-width: 160px !important;
    max-width: 160px !important;
  }
  & td:last-child {
    min-width: 260px;
  }
  tr td:not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.4em;
    background: ${allColors.seriyColor};
    border: 1px solid ${allColors.seriyColor};
    transform: rotate(90deg);
    transform: translateY(-50%);
    content: "";
  }
  tr td:last-child:not([colspan]):before {
    content: none;
  }
`;
