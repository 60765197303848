import React from "react";
import { Cards, Container, ImageCard, Title, TitleCard } from "./style";
import OneId from "assets/images/auth/oneId.svg";
import OneIdImg from "assets/images/auth/one-id.png";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doGetAuth } from "services/services";
import ReactLoader from "components/react-loader/ReactLoader";
import { doGet } from "services/services";
import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { applyApi } from "shared/api/pagesApi";
import useAccordionStore from "../../../../zustand/accordionData";
import axios from "axios";

function AuthV1() {
  const navigate = useNavigate();
  const location = useLocation();
  const editStatus = useAccordionStore((state) => state.editStatus);
  const [avalableBol, setAvalableBol] = useState(false);
  let object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const [urlId, setUrlId] = useState({
    error: false,
    loading: false,
    success: false,
    data: "",
  });
  useQuery(
    ["get_token"],
    () => doGet(`/auth/one-id-callback/?code=${params?.code}`),
    {
      onSuccess: (data) => {
        if (data) {
          let userInfo = JSON.stringify({
            accessToken: data?.access,
            refreshToken: data?.refresh,
          });
          localStorage.setItem("userInfo", userInfo);
          if (localStorage.getItem("isCabinetGarden") === "false") {
            // navigate("/credit-type");
            setAvalableBol(true);
          } else {
            navigate("/personal-cabinet");
            localStorage.setItem("isCabinetGarden", false);
          }
        }
      },
      onError: (error) => {
        if (error?.response?.status === 400) {
          navigate("/");
          notification["error"]({
            message: "Error",
            description: error?.response?.data.detail,
          });
        }
      },
      enabled: params.code ? true : false,
    }
  );
  useQuery(
    ["get_stepInfo"],
    async () => await applyApi.getRequest("/app/available/"),
    {
      onSuccess: (res) => {
        if (res) {
          if (res.process_step > 1) {
            editStatus({
              step: res.process_step,
              debt_type: res.debt_type,
            });
            localStorage.setItem("app_id", res?.id);
            navigate("/ms/business-plan");
          } else {
            editStatus({
              step: 2,
            });
            navigate("/ms/ariza");
          }
          let userStep = JSON.stringify(res?.process_step);
          localStorage.setItem("userStep", userStep);
          localStorage.setItem("dep_type", res?.debt_type);
          localStorage.setItem(
            "application_id",
            JSON.stringify(res?.a_application)
          );
        }
      },
      onError: (err) => {
        if (err?.status_code === 401) {
          navigate("/");
        }
      },
      enabled: avalableBol ? true : false,
    }
  );
  const GetAuth = async () => {
    setUrlId((prev) => {
      return {
        ...prev,
        loading: true,
      };
    })
   const url= await axios.get("https://gardenapi.agro.uz/auth/one-id-ms/").then((res) => {
      return res.data;
    });
    setUrlId((prev) => {
      return {
        ...prev,
        loading: false,
        success: true,
        error: false,
        data: url,
      };
    });
  };

  useEffect(() => {
    if (!params?.code) {
      GetAuth();
    }
  }, []);
  return (
    <Container>
      {params.code ? (
        <ReactLoader propWidth={100} colors={"#fff"} alignItem="center" />
      ) : (
        <>
          {urlId.loading && (
            <ReactLoader propWidth={100} colors={"#fff"} alignItem="center" />
          )}
          {urlId.error && (
            <h1 style={{ color: "#fff" }}>Xatolik yuz berdi!!!</h1>
          )}
          {urlId.success && (
            <Cards>
              <Title>Kirish</Title>
              <TitleCard>
                <button>
                  <img src={OneId} alt="one-id-icon" />
                </button>
              </TitleCard>
              <ImageCard>
                <a
                  target={"_self"}
                  href={urlId.data?.url}
                  // onClick={changeIsCabinet}
                >
                  <img src={OneIdImg} alt="one-id-icon" />
                </a>
              </ImageCard>
            </Cards>
          )}
        </>
      )}
    </Container>
  );
}

export default AuthV1;
