import React, { useState } from "react";
import {
  Input,
  InputContainer,
  Label,
  LabelMain,
  UploadConatiner,
} from "./style";
import { FiPaperclip } from "react-icons/fi";
import { technicsApi } from "shared/api/pagesApi";
import { notification } from "antd";

function FileUploadForApply({
  label,
  setTechnicsFoto,
  setFileId,
  fileId,
  nameId,
  disabled,
}) {
  const [changeFile, setChangeFile] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  // const [fileId, setFileId] = useState([])
  async function onChange(e) {
    const formData = new FormData();
    const files = e.target.files[0];
    formData.append("file", files);
    setIsDisabled(true);
    try {
      const res = await technicsApi.sendData("/file/create/", formData);     
      if (res && res?.files) {
        setFileId( res?.files.id);
        setIsDisabled(false);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(false);
      if (err?.status_code===400) {
        notification["error"]({
          message: "Error",
          description: err?.detail || "Xatolik yuz berdi!",
        });
      }
    }

    setTechnicsFoto(files);
    setChangeFile("");
  }
  return (
    <InputContainer>
      <LabelMain>{label}</LabelMain>
      <UploadConatiner>
        <Label htmlFor={nameId}>
          <FiPaperclip /> File yuklash
        </Label>
        <Input
          id={nameId}
          placeholder="File yuklash"
          type={"file"}
          accept=".jpg, .pdf"
          onChange={onChange}
          value={changeFile}
          required={fileId ? false: true}
          disabled={isDisabled || disabled}
        />
      </UploadConatiner>

      {/* {changeFile ? (
        <span className="error-span">Please enter information!</span>
      ) : null} */}
    </InputContainer>
  );
}

export default FileUploadForApply;
