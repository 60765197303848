import { Col, Row } from "antd";
import InputController from "components/input/inputController";
import React, { Fragment } from "react";
import { Title, Wrapper } from "./style";

export const EarthInfo = ({
  control,
  Controller,
  errors,
  pledge,
  landsFields,
}) => {
  return (
    <Wrapper>
      <Title>Yer ma’lumoti</Title>
      <Title>Yer ijara shartnomasi mavjudmi? : {pledge ? "Ha" : "yo'q"}</Title>

      <Row gutter={[16, 16]}>
        {landsFields?.map((item, index) => (
          <Fragment key={index}>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_cad_num`}
                label={"Yer kadastr raqami"}
                placeholder="23423423432 (API)"
                required
                disabled
                className={
                  errors &&
                  errors?.hasOwnProperty("cadastr_number") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("cadastr_number") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_doc_type`}
                label={"Xujjat turi"}
                placeholder="------- (API)"
                required
                disabled={true}
                className={
                  errors &&
                  errors?.hasOwnProperty("li_doc_type") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_doc_type") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_doc_date`}
                label={"Xujjat berilgan vaqt"}
                placeholder="10.12.2022 (API)"
                disabled={true}
                required
                className={
                  errors &&
                  errors?.hasOwnProperty("li_doc_date") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_doc_date") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_legal_area`}
                label={"Xujjatdagi yer maydoni"}
                placeholder="123  (API)"
                disabled={true}
                required
                className={
                  errors &&
                  errors?.hasOwnProperty("li_legal_area") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_legal_area") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_gis_area`}
                label={"Haqiqiy maydon"}
                placeholder="4234 (API)"
                required
                disabled={true}
                className={
                  errors &&
                  errors?.hasOwnProperty("li_gis_area") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_gis_area") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_custom_area`}
                label={"Shundan ekin yer maydoni"}
                // placeholder="564 (API)"
                inputType={'number'}
                required
                className={
                  "bg-primary" + errors &&
                  errors?.lands?.[index]?.li_custom_area &&
                  "input-error"
                }
              />
              {errors && errors?.lands?.[index]?.li_custom_area && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_region`}
                label={"Yer joylashgan viloyat"}
                placeholder="Toshkent (API)"
                disabled={true}
                required
                className={
                  errors && errors?.hasOwnProperty("li_region") && "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_region") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_district`}
                label={"Yer joylashgan tuman"}
                placeholder="Zangiota (API)"
                disabled={true}
                required
                className={
                  errors &&
                  errors?.hasOwnProperty("li_district") &&
                  "input-error"
                }
              />
              {errors && errors?.hasOwnProperty("li_district") && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
            <Col span={24} md={8}>
              <InputController
                control={control}
                Controller={Controller}
                name={`lands[${index}].li_bonitet`}
                label={"Yer uchastkasining ball boniteti"}
                placeholder="API"
                required
                inputType={"text"}
                className={
                  errors && errors?.lands?.[index]?.li_bonitet && "input-error"
                }
              />
              {errors && errors?.lands?.[index]?.li_bonitet && (
                <span className="error-span">Please enter information!</span>
              )}
            </Col>
          </Fragment>
        ))}
      </Row>
    </Wrapper>
  );
};
