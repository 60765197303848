import styled from 'styled-components';

export const Header = styled('div')`
  .apply-status-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 600;
      span {
        color: #a5a5a5;
      }
    }
  }
`;
