import React from "react";
import { InputContainer, Label } from "../inputStyle";
import { Input } from "antd";
const { TextArea } = Input;

function TextAreaComponent({
  label,
  placeholder,
  name,
  Controller,
  control,
  required = false,
  minLength = 3,
  showCount,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required, minLength: minLength }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <InputContainer>
          <Label htmlFor={name}>{label}</Label>
          <TextArea
            id={name}
            placeholder={placeholder || "Shuyerga kiriting"}
            value={value || ""}
            showCount={showCount ? false : true}
            maxLength={5000}
            style={{
              height: 132,
              width: "100%",
              resize: "none",
            }}
            bordered={false}
            onChange={onChange}
            ref={ref}
            // allowClear={true}
          />
        </InputContainer>
      )}
    />
  );
}

export default TextAreaComponent;
