import React from "react";

import {
  Cards,
  MainContainer,
  MarginContainer,
} from "components/styles/styleComponetns";

function ProjectOfficeChoosing() {
  return (
    <MainContainer>
      <MarginContainer>
        <Cards></Cards>
      </MarginContainer>
    </MainContainer>
  );
}

export default ProjectOfficeChoosing;
