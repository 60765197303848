import React from "react";
import {
  MainContainer,
  MarginContainer,
  Cards,
  H3,
} from "components/styles/styleComponetns";
import { Col, Row } from "antd";
import { Controller } from "react-hook-form";
import InputController from "components/input/inputController";
import { useHooks } from "./hooks";
import TextAreaComponent from "components/input/textArea";
import FileInfo from "../filesComp";
export default function ApplyRejection() {
  const { control, data } = useHooks();
  return (
    <MainContainer>
      <MarginContainer>
        <Cards>
          <H3 style={{ textAlign: "center" }}>Ariza bo'yicha xulosa</H3>
          <Row gutter={[16, 0]} className="mt-4">
            <Col span={24} lg={8} md={12}>
              <InputController
                Controller={Controller}
                control={control}
                label={"Ariza statusi"}
                placeholder={"Ariza statusi"}
                disabled={true}
                name={"applyStatus"}
              />
            </Col>
            <Col span={24} lg={8} md={12}>
              <InputController
                Controller={Controller}
                control={control}
                label={"Sanasi"}
                placeholder={"Sanasi"}
                disabled={true}
                name={"reject_date"}
              />
            </Col>
            <Col span={24} lg={8} md={12}>
              <InputController
                Controller={Controller}
                control={control}
                label={"Bo'lim"}
                placeholder={"Bo'lim"}
                disabled={true}
                name={"rejected_by"}
              />
            </Col>
            <Col span={24} lg={8} md={12}>
              <div style={{ marginTop: "16px" }}>
                <FileInfo
                  label={"Rad etish xati"}
                  data={data?.rejection_letter}
                />
              </div>
            </Col>
            <Col span={24} lg={12} md={12}>
              <TextAreaComponent
                control={control}
                Controller={Controller}
                label="Rad etish xulosasi"
                name="conclusion"
                showCount={true}
              />
            </Col>
          </Row>
        </Cards>
      </MarginContainer>
    </MainContainer>
  );
}
